'use strict'

export default [
  // 数据看板
  {
    path: '1024/data-board',
    name: 'C1024DataBoard',
    meta: { Title: '数据看板',Auth:'{C1024DataBoard}' },
    // meta: { Title: '数据看板', Auth: '{Routine}' },
    component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/custom/1024/data-board'),
  },
  // 策略中心
  {
    path: '1024/tack-manage',
    name: 'C1024TackManage',
    meta: {Title: '策略中心', Auth: '{Tack}'},
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'tack',
        meta: { Title: '策略管理' },
        redirect: 'tack/list',
        component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1024TackManage.BaseManage.List',
            meta: { Title: '策略列表', Auth: '{C1024Tack}[Base](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/tack/list.vue'),
          }, {
            path: 'info/:id/:type?/:category?/:groupId?',
            name: 'C1024TackManage.BaseManage.Info',
            meta: { Title: '策略详情', Auth: '{C1024Tack}[Base](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/tack/info.vue'),
          },
          {
            path: 'add/:id?/:type?/:category?/:groupId?',
            name: 'C1024TackManage.BaseManage.Add',
            meta: { Title: '策略编辑', Auth: '{C1024Tack}[Base](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/tack/add.vue'),
          }
        ]
      },
      {
        path: 'policy-group',
        meta: { Title: '策略组管理', Auth: '{C1024Tack}[Group]' },
        redirect: 'policy-group/list',
        component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1024TackManage.GroupManage.List',
            meta: { Title: '策略组列表', Auth: '{C1024Tack}[Group](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/policy-group/list.vue'),
          },
           {
            path: 'info/:id',
            name: 'C1024TackManage.GroupManage.Info',
            meta: { Title: '策略组详情', Auth: '{C1024Tack}[Group](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/policy-group/info.vue'),
          },
           {
            path: 'add/:id?',
            name: 'C1024TackManage.GroupManage.Add',
            meta: { Title: '策略组编辑', Auth: '{C1024Tack}[Group](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/policy-group/add.vue'),
          }
        ]
      },
      {
        path: 'position',
        meta: { Title: '触点管理' },
        redirect: 'position/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1024TackManage.PositionManage.List',
            meta: { Title: '触点列表', Auth: '{C1024Tack}[Position](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/position/list.vue'),
          },
          {
            path: 'info/:id',
            name: 'C1024TackManage.PositionManage.Info',
            meta: { Title: '触点详情', Auth: '{C1024Tack}[Position](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/position/info.vue'),
          }
        ]
      },
      {
        path: 'contact-resources',
        name: 'C1024TackManage.MaterialGroupManage',
        meta: { Title: '触点资源', Auth: '{C1024Tack}[MaterialGroup](List)' },
        redirect: 'contact-resources/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1024TackManage.MaterialGroupManage.List',
            meta: { Title: '触点资源列表', Auth: '{C1024Tack}[MaterialGroup](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/contact-resources/list.vue'),
          },
          {
            path: 'info/:id?/:type',
            name: 'C1024TackManage.MaterialGroupManage.Info',
            meta: { Title: '触点资源详情', Auth: '{C1024Tack}[MaterialGroup](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/contact-resources/info.vue'),
          },
          {
            path: 'add/:id?/:type',
            name: 'C1024TackManage.MaterialGroupManage.Add',
            meta: { Title: '触点资源添加', Auth: '{C1024Tack}[MaterialGroup](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/contact-resources/add.vue'),
          },
        ]
      },
      {
        path: 'strategy-material',
        name: 'C1024TackManage.StrategyMaterial',
        meta: { Title: '策略素材', Auth: '{C1024Tack}[Material](List)' },
        redirect: 'strategy-material/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1024TackManage.MaterialManage.List',
            meta: { Title: '策略素材列表', Auth: '{C1024Tack}[Material](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/strategy-material/list.vue'),
          },
          {
            path: 'add',
            name: 'C1024TackManage.MaterialManage.Add',
            meta: { Title: '素材编辑',Auth:'{C1024Tack}[Material](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/strategy-material/add.vue'),
          },
        ]
      },
      {
        path: 'data-statistics',
        meta: { Title: '策略分析',Auth:'{C1024Tack}[Data](List)'},
        redirect: 'data-statistics/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
            {
              path: 'list',
              name: 'C1024TackManage.DataManage.List',
              meta: { Title: '',Auth:'{C1024Tack}[Data](List)'},
              component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/data-statistics/list.vue'),
            },
            {
                path: 'info/:id',
                name: 'C1024TackManage.DataManage.Info',
                meta: { Title: '策略数据详情',Auth:'{C1024Tack}[Data](Info)'},
                component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/data-statistics/info.vue'),
            },
            {
              path: 'index',
              name: 'C1024TackManage.DataManage.IndexInfo',
              meta: { Title: '关键指标详情',Auth:'{C1024Tack}[Data](IndexInfo)' },
              component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/data-statistics/index-info.vue'),
            },
        ]
      },
      {
        path: 'sms-push',
        name: 'C1024TackManage.SMSPushManage',
        meta: { Title: '短信群发',
          Auth:'{C1024Tack}[SMSPush](List)'
        },
        redirect: 'sms-push/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
            {
              path: 'list',
              name: 'C1024TackManage.SMSPushManage.List',
              meta: { Title: '群发列表',
                Auth:'{C1024Tack}[SMSPush](List)'
              },
              component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/sms-push/list.vue'),
            },
            {
              path: 'add/:id?',
              name: 'C1024TackManage.SMSPushManage.Add',
              meta: { Title: '短信群发新建/编辑',
                Auth:'{C1024Tack}[SMSPush](Add)'
              },
              component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/sms-push/add.vue'),
            },
            {
              path: 'info/:id',
              name: 'C1024TackManage.SMSPushManage.Info',
              meta: { Title: '短信群发详情',Auth:'{C1024Tack}[SMSPush](Info)'},
              component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1024/tack-manage/sms-push/info.vue'),
            }
        ]
      }
    ]
  },
  // 通知中心
  {
    path: '1024/notification-manage',
    name: 'C1024NotificationManage',
    meta: {Title: '通知中心', Auth: '{C1024Notification}'},
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'message',
        meta: { Title: '消息管理' },
        redirect: 'message/list',
        component: () => import(/* webpackChunkName: "index.lazy.notification-manage" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1024NotificationManage.MessageManage.List',
            meta: { Title: '消息列表', Auth: '{C1024Notification}[Message](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.notification-manage" */ '@/page/custom/1024/notification-manage/message/list.vue'),
          }, {
            path: 'info/:id',
            name: 'C1024NotificationManage.MessageManage.Info',
            meta: { Title: '消息详情', Auth: '{C1024Notification}[Message](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.notification-manage" */ '@/page/custom/1024/notification-manage/message/info.vue'),
          },
          {
            path: 'add/:type/:id?',
            name: 'C1024NotificationManage.MessageManage.Add',
            meta: { Title: '消息编辑', Auth: '{C1024Notification}[Message](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.notification-manage" */ '@/page/custom/1024/notification-manage/message/add.vue'),
          }
        ]
      }
    ]
  },
  // 用户运营
  {
    path: '1024/customer-manage',
    name: 'C1024CustomerManage',
    meta: { Title: '用户运营', Auth: '{C1024Customer}'},
    component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'user',
        redirect: 'user/list',
        meta: { Title: '用户管理'},
        component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1024CustomerManage.List',
            meta: {
              Title: '用户列表',
              Auth: '{C1024Customer}[List]'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/custom/1024/customer-manage/user/list.vue')
          },
          {
            path: 'info/:id',
            name: 'C1024CustomerManage.Info',
            meta: {
              Title: '用户详情',
              Auth: '{C1024Customer}[Info]'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/custom/1024/customer-manage/user/info.vue')
          },
        ]
      }, {
        path: 'tag',
        redirect: 'tag/list',
        name: 'C1024CustomerManage.TagManage',
        meta: {
          Title: '标签管理',
          Auth: '{C1024Customer}[Tag](List)'
        },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1024CustomerManage.TagManage.List',
            meta: {
              Title: '标签列表',
              Auth: '{C1024Customer}[Tag](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1024-customer-manage" */ '@/page/custom/1024/customer-manage/tag/list.vue')
          }, {
            path: 'add/:id?',
            name: 'C1024CustomerManage.TagManage.Add',
            meta: {
              Title: '标签编辑',
              Auth: '{C1024Customer}[Tag](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1024-customer-manage" */ '@/page/custom/1024/customer-manage/tag/add.vue')
          }, {
            path: 'info/:id',
            name: 'C1024CustomerManage.TagManage.Info',
            meta: {
              Title: '标签详情',
              Auth: '{C1024Customer}[Tag](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1024-customer-manage" */ '@/page/custom/1024/customer-manage/tag/info.vue')
          }
        ]
      }, {
        path: 'user-analysis',
        name: 'C1024CustomerManage.Analysis',
        meta: {
          Title: '用户分析',
          Auth: '{C1024Customer}[Analysis]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.c1024-customer-manage" */ '@/page/custom/1024/customer-manage/user-analysis.vue')
      }]
  },
  // 微信体系管理
  // {
  //   path: '1024/wechat-manage',
  //   name: 'C1024WeChatManage',
  //   meta: {
  //     Title: '微信体系管理'
  //   },
  //   component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
  //   children: [
  //     {
  //       path: 'wechat-mp',
  //       name: 'C1024WeChatManage.WeChatMP',
  //       redirect: 'wechatMP/tmplmsg/list',
  //       meta: {Title: '公众号', Auth: '{Platform}[Wechat]'},
  //       component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
  //       children: [
  //         {
  //           path: 'tmpl-msg',
  //           redirect: 'tmpl-msg/list',
  //           name: 'C1024WeChatManage.WeChatMP.TmplMsg',
  //           meta: {
  //             Title: '模板消息',
  //             // Auth: '{Platform}[Wechat](MenuList)'
  //           },
  //           // component: () => import(/* webpackChunkName: "index.lazy.c1024-wechat-manage" */ '@/page/custom/1024/wechat-manage/mp/tmpl-msg/list.vue'),
  //           component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
  //           children: [
  //             {
  //               path: 'list',
  //               name: 'C1024WeChatManage.WeChatMP.TmplMsg.List',
  //               meta: {
  //                 Title: '订阅消息模板列表',
  //                 // Auth: '{Platform}[Wechat](MenuList)'
  //               },
  //               component: () => import(/* webpackChunkName: "index.lazy.c1024-wechat-manage" */ '@/page/custom/1024/wechat-manage/mp/tmpl-msg/list.vue')
  //             },
  //             {
  //               path: 'info/:id',
  //               name: 'C1024WeChatManage.WeChatMP.TmplMsg.Info',
  //               meta: {
  //                 Title: '订阅消息模板详情',
  //                 // Auth: '{Platform}[Wechat](MenuList)'
  //               },
  //               component: () => import(/* webpackChunkName: "index.lazy.c1024-wechat-manage" */ '@/page/custom/1024/wechat-manage/mp/tmpl-msg/info.vue')
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // }
]
