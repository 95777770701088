import VueCookie from 'vue-cookie'
import KOCString from 'koc-common-string'
import ConfigSite from '../../config/site'

const configDomain = process.env.VUE_APP_DOMAIN
let browserGroup = null
let vueInstance = null

const UtilsCommon = {
  Instance: (vue) => {
    vueInstance = vue
  },
  BrowserGroup: () => {
    if (browserGroup) return browserGroup
    /* eslint-disable eqeqeq */
    const userAgent = navigator.userAgent || ''
    browserGroup = 'PC'
    if (userAgent.match(/AppleWebKit.*Mobile.*/)) {
      if (userAgent.match(/MicroMessenger/i) === 'MicroMessenger') {
        browserGroup = 'Wechat'
      } else if (userAgent.match(/WeiBo/i) === 'WeiBo') {
        browserGroup = 'Weibo'
      } else if (userAgent.match(/QQ/i) === 'QQ') {
        browserGroup = 'QQ'
      } else {
        browserGroup = 'Mobile'
      }
    }
    if (userAgent.match(/MicroMessenger/i) === 'MicroMessenger') browserGroup = 'Wechat'
    return browserGroup
  },
  LocationReload: (force) => {
    if (UtilsCommon.BrowserGroup() !== 'Wechat') {
      window.location.reload(force)
      return
    }
    if (!location.search) {
      window.location.reload(force)
      window.location.href = location.origin + location.pathname + '?_rt=' + (new Date()).getTime()
      return
    }
    const pattern = /([?|&]_rt=).*?(?=(&|$))/g
    // alert('Locatfopm;coml' + pattern)
    const params = location.search.match(pattern) ? location.search.replace(pattern, (_, $1) => $1 + (new Date()).getTime()) : location.search + '&_rt=' + (new Date()).getTime()
    window.location.href = location.origin + location.pathname + params
    // location.href = '/login'
  },
  Cookie: {
    Domain: () => {
      return configDomain === '*' ? window.location.hostname : configDomain
    },
    Set: (name, val, opts) => {
      console.log(name,
        val,
        Object.assign({
          domain: UtilsCommon.Cookie.Domain()
        }, opts),'注册注册注册注册注册自注册');
      VueCookie.set(
        name,
        val,
        Object.assign({
          domain: UtilsCommon.Cookie.Domain()
        }, opts)
      )
    },
    Get: (name) => {
      return VueCookie.get(name)
    },
    Del: (name) => {
      VueCookie.delete(name, {
        domain: UtilsCommon.Cookie.Domain()
      })
    },
    JsonWebToken: {
      Set: (val) => {
        if (val) UtilsCommon.Cookie.Set('JsonWebToken', KOCString.ToString(val),10)
      },
      Get: (dev) => {
        // dev: false 不读取测试状态
        // alert('9999999' + configDomain.JsonWebToken)
        return (dev !== false ? configDomain.JsonWebToken : null) || KOCString.ToString(UtilsCommon.Cookie.Get('JsonWebToken'))
        // return (dev !== false && vueInstance ? configDomain['JsonWebToken.' + vueInstance.$options.site] || configDomain.JsonWebToken : null) || KOCString.ToString(UtilsCommon.Cookie.Get('JsonWebToken'))
      },
      Del: () => {
        console.log('token-del-before:', UtilsCommon.Cookie.JsonWebToken.Get())
        UtilsCommon.Cookie.Del('JsonWebToken')
        console.log('token-del-after:', UtilsCommon.Cookie.JsonWebToken.Get())
      }
    }
  },
  LocalStorage: {
    Get: (key) => {
      return localStorage.getItem(key) ? localStorage.getItem(key) : null
    },
    GetJson: (key) => {
      try {
        return JSON.parse(UtilsCommon.LocalStorage.Get(key))
      } catch (e) {
        return []
      }
    },
    Set: (key, data) => {
      localStorage.setItem(key, data)
    },
    SetJson: (key, data) => {
      UtilsCommon.LocalStorage.Set(key, JSON.stringify(data))
    },
    Del: (key) => {
      localStorage.removeItem(key)
    }
  },
  SiteURL: {
    // site:站点名
    // host:是否只取得host(不带http) 默认:false
    Get: (site, host) => {
      const siteConfig = ConfigSite[site]
      if (!siteConfig) return ''
      let retURL = ''
      if (configDomain === '*') {
        retURL = window.location.hostname + ':' + siteConfig.port
      } else if (KOCString.Regular.Verify(configDomain, 'IPv4')) {
        retURL = configDomain + ':' + siteConfig.port
      } else {
        retURL = siteConfig.subdomain + '.' + configDomain
      }
      return host ? retURL : 'https://' + retURL + '/'
    },
    MUser: (host) => {
      return UtilsCommon.SiteURL.Get('m-user', host)
    }
  },
  ImageURL: {
    User: (data) => {
      data = Object.assign({
        User_ID_Company: null,
        User_ID: null,
        Company_ID: null
      }, data)
      return `${process.env.VUE_APP_API_URL}common/image/user/${KOCString.ToIntPositive(data.User_ID_Company)}/${KOCString.ToIntPositive(data.User_ID)}/${KOCString.ToIntPositive(data.Company_ID)}`
    }
  },
  MDialog: {
    Loading: {
      Open: (opts) => {
        if (!vueInstance || !vueInstance.$dialog) return
        vueInstance.$dialog.loading.open(opts)
      },
      Close: () => {
        if (!vueInstance || !vueInstance.$dialog) return
        vueInstance.$dialog.loading.close()
      }
    },
    Confirm: (opts, loadover) => {
      if (!vueInstance || !vueInstance.$dialog) return
      if (loadover !== false) UtilsCommon.MDialog.Loading.Close()
      if (typeof opts.opts === 'function') {
        vueInstance.$dialog.confirm(opts)
        return
      }
      if (!opts.opts) {
        opts.opts = [
          {
            name: false,
            txt: '取消',
            color: false
          },
          {
            name: true,
            txt: '确定',
            color: true
          }
        ]
      }
      if (!(opts.opts instanceof Array)) return
      return new Promise((resolve) => {
        for (const thisValue of opts.opts) {
          if (thisValue.callback && typeof thisValue.callback === 'function') continue
          thisValue.callback = () => {
            return resolve(thisValue.name)
          }
        }
        vueInstance.$dialog.confirm(opts)
      })
    },
    Alert: (opts, loadover) => {
      if (!vueInstance || !vueInstance.$dialog) return
      if (loadover !== false) UtilsCommon.MDialog.Loading.Close()
      vueInstance.$dialog.alert(opts)
    },
    Toast: (opts, loadover) => {
      if (!vueInstance || !vueInstance.$dialog) return
      if (loadover !== false) UtilsCommon.MDialog.Loading.Close()
      return new Promise((resolve) => {
        opts.callback = () => {
          return resolve()
        }
        vueInstance.$dialog.toast(Object.assign({
          timeout: 1000
        }, opts))
      })
    },
    Notify: (opts, loadover) => {
      if (!vueInstance || !vueInstance.$dialog) return
      if (loadover !== false) UtilsCommon.MDialog.Loading.Close()
      return new Promise((resolve) => {
        opts.callback = () => {
          return resolve()
        }
        vueInstance.$dialog.notify(Object.assign({
          timeout: 1000
        }, opts))
      })
    }
  },
  /**
   * @description 根据name/params/query判断两个路由对象是否相等
   * @param {*} route1 ,route2 路由对象
   */
  IsEqualRoute: (route1, route2) => {
    const params1 = route1.Params || {}
    const params2 = route2.Params || {}
    const query1 = route1.Query || {}
    const query2 = route2.Query || {}
    return (route1.Name === route2.Name) && UtilsCommon.IsEqualObject(params1, params2) && UtilsCommon.IsEqualObject(query1, query2)
  },
  /**
   * @param {*} obj1 对象,{*} obj2 对象
   * @description 判断两个对象是否相等
   */
  IsEqualObject: (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1)
    const keysArr2 = Object.keys(obj2)
    if (keysArr1.length !== keysArr2.length) {
      return false
    } else if (keysArr1.length === 0 && keysArr2.length === 0) {
      return true
    } else {
      return !keysArr1.some(key => obj1[key] !== obj2[key])
    }
  }
}

export default UtilsCommon
