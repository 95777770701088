'use strict'

export default [
  {
    path: 'wechat-manage',
    name: 'WechatManage',
    meta: {Title: '微信体系管理', Auth: '{Wechat}'},
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'app',
        name: 'WechatManage.APPManage',
        redirect: 'app/list',
        meta: {Title: '基础授权', Auth: '{Wechat}[APP]'},
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'WechatManage.APPManage.List',
            meta: {Title: '授权列表', Auth: '{Wechat}[APP](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/app/list.vue')
          },
        ]
      },
      {
        path: 'mp',
        name: 'Wechat.MPManage',
        redirect: 'template/list',
        meta: {Title: '公众号', Auth: '{Wechat}[MP]'},
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'template',
            redirect: 'template/list',
            name: 'WechatManage.MPManage.Template',
            meta: {Title: '模板消息', Auth: '{Wechat}[MP](TemplateList)'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.MPManage.TemplateList',
                meta: {Title: '模板消息列表', Auth: '{Wechat}[MP](TemplateList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/template/list.vue')
              },
              {
                path: 'info/:id/:APPID',
                name: 'WechatManage.MPManage.TemplateInfo',
                meta: {Title: '模板消息详情', Auth: '{Wechat}[MP](TemplateInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/template/info.vue')
              }
            ]
          },
          {
            path: 'template-push',
            redirect: 'template-push/list',
            name: 'WechatManage.MPManage.TemplatePush',
            meta: {Title: '消息群发', Auth: '{Wechat}[MP](TemplatePushList)'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.MPManage.TemplatePushList',
                meta: {Title: '消息群发列表', Auth: '{Wechat}[MP](TemplatePushList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/template-push/list.vue')
              },
              {
                path: 'info/:id?',
                name: 'WechatManage.MPManage.TemplatePushInfo',
                meta: {Title: '消息群发详情', Auth: '{Wechat}[MP](TemplatePushInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/template-push/info.vue')
              },
              {
                path: 'add/:id?',
                name: 'WechatManage.MPManage.TemplatePushAdd',
                meta: {Title: '消息群发新建/编辑', Auth: '{Wechat}[MP](TemplatePushAdd)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/template-push/add.vue')
              }
            ]
          },
          {
            path: 'subscription',
            redirect: 'subscription/list',
            name: 'WechatManage.MPManage.Subscription',
            meta: {Title: '订阅通知', Auth: '{Wechat}[MP](SubscriptionList)'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.MPManage.SubscriptionList',
                meta: {Title: '订阅通知列表', Auth: '{Wechat}[MP](SubscriptionList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/subscription/list.vue')
              }
            ]
          },
          {
            path: 'subscription-push',
            redirect: 'subscription-push/list',
            name: 'WechatManage.MPManage.SubscriptionPush',
            meta: {Title: '订阅通知群发', Auth: '{Wechat}[MP](SubscriptionPushList)'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.MPManage.SubscriptionPushList',
                meta: {Title: '订阅通知群发列表', Auth: '{Wechat}[MP](SubscriptionPushList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/subscription-push/list.vue')
              },
              {
                path: 'info/:id?',
                name: 'WechatManage.MPManage.SubscriptionPushInfo',
                meta: {Title: '订阅通知群发详情', Auth: '{Wechat}[MP](SubscriptionPushInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/subscription-push/info.vue')
              },
              {
                path: 'add/:id?',
                name: 'WechatManage.MPManage.SubscriptionPushAdd',
                meta: {Title: '订阅通知群发新建/编辑', Auth: '{Wechat}[MP](SubscriptionPushAdd)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/mp/subscription-push/add.vue')
              }
            ]
          },
          {
            path: 'menu',
            redirect: 'menu/list',
            name: 'WechatManage.Menu',
            meta: {Title: '自定义菜单', Auth: '{Platform}[Wechat](MenuList)'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.MenuList',
                meta: {Title: '自定义菜单列表', Auth: '{Platform}[Wechat](MenuList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/menu/list.vue')
              },
              {
                path: 'info/:id',
                name: 'WechatManage.MenuInfo',
                meta: {Title: '自定义菜单详情', Auth: '{Platform}[Wechat](MenuInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/menu/info.vue')
              },
              {
                path: 'add/:id',
                name: 'WechatManage.MenuAdd',
                meta: {Title: '自定义菜单编辑', Auth: '{Platform}[Wechat](MenuAdd)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/menu/add.vue')
              },
            ]
          },
          {
            path: 'autoreply',
            redirect: 'autoreply/list',
            name: 'WechatManage.Autoreply',
            meta: {Title: '自动回复', Auth: '{Platform}[Wechat](SubscribeMessageList)'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.AutoreplyList',
                meta: {Title: '自动回复列表', Auth: '{Platform}[Wechat](SubscribeMessageList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/beadded/list.vue')
              },
              {
                path: 'info/:id/:type',
                name: 'WechatManage.BeaddedInfo',
                meta: {Title: '回复详情', Auth: '{Platform}[Wechat](SubscribeMessageInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/beadded/info.vue')
              },
              {
                path: 'add/:id/:type',
                name: 'WechatManage.BeaddedAdd',
                meta: {Title: '回复编辑', Auth: '{Platform}[Wechat](SubscribeMessageAdd)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/beadded/add.vue')
              },
              // {
              //     path: 'keyword-list',
              //     name: 'WechatManage.KeywordList',
              //     meta: {Title: '自动回复', Auth: '{Platform}[Wechat](KeyMessageList)'},
              //     component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/keyword/list.vue')
              // },
              // {
              //     path: 'keyword-info/:id',
              //     name: 'WechatManage.KeywordInfo',
              //     meta: {Title: '关键词回复详情', Auth: '{Platform}[Wechat](KeyMessageInfo)'},
              //     component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/keyword/info.vue')
              // },
              // {
              //     path: 'keyword-add/:id',
              //     name: 'WechatManage.KeywordAdd',
              //     meta: {Title: '关键词回复编辑', Auth: '{Platform}[Wechat](KeyMessageAdd)'},
              //     component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/keyword/add.vue')
              // },
            ]
          }
        ]
      },
      {
        path: 'work',
        name: 'WechatManage.WorkManage',
        redirect: 'work/employee/list',
        meta: {Title: '企业微信',
          // Auth: '{WechatWork}'
        },
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'employee',
            redirect: 'employee/list',
            // name: 'WechatManage.WorkManage.Employee',
            meta: {
              Title: '员工管理',
              // Auth: '{WechatWork}[Employee](List)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.WorkManage.EmployeeList',
                meta: {Title: '员工列表', Auth: '{Wechat}[Work](EmployeeList)'},
                component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/employee/list.vue')
              },
              {
                path: 'info/:id',
                name: 'WechatManage.WorkManage.EmployeeInfo',
                meta: {Title: '员工信息详情', Auth: '{Wechat}[Work](EmployeeInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/employee/info.vue')
              },
              {
                path: 'info-customer/:id',
                name: 'WechatManage.WorkManage.CustomerInfo',
                meta: {Title: '员工客户详情', Auth: '{Wechat}[Work](CustomerInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/customer/info.vue')
              }
            ]
          },
          // {
          //   path: 'customer',
          //   redirect: 'customer/list',
          //   // name: 'WechatManage.WorkManage.Employee',
          //   meta: {
          //     Title: '员工客户管理',
          //     // Auth: '{Wechat}[Work](CustomerList)'
          //   },
          //   component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
          //   children: [
          //     // {
          //     //   path: 'list',
          //     //   name: 'WechatManage.WorkManage.CustomerList',
          //     //   meta: {Title: '员工客户列表', Auth: '{Wechat}[Work](CustomerList)'},
          //     //   component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/customer/list.vue')
          //     // },
          //     {
          //       path: 'info/:id',
          //       name: 'WechatManage.WorkManage.CustomerInfo',
          //       meta: {Title: '员工客户详情', Auth: '{Wechat}[Work](CustomerInfo)'},
          //       component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/customer/info.vue')
          //     }
          //   ]
          // },
          {
            path: 'tag/list',
            name: 'WechatManage.WorkManage.TagList',
            meta: {Title: '标签分组', Auth: '{Wechat}[Work](TagList)'},
            component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/tag/list.vue')
          },
          {
            path: 'group',
            redirect: 'group/list',
            name: 'WechatManage.WorkManage.Group',
            meta: {Title: '客户群', Auth: '{WechatWork}[Group]'},
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'WechatManage.WorkManage.GroupList',
                meta: {Title: '客户群列表', Auth: '{Wechat}[Work](GroupList)'},
                component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/group/list.vue')
              },
              {
                path: 'info/:ChatID',
                name: 'WechatManage.WorkManage.GroupInfo',
                meta: {Title: '客户群详情', Auth: '{Wechat}[Work](GroupInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/group/info.vue')
              },
            ]
          },
          {
            path: 'base-setting',
            name: 'WechatManage.WorkManage.SettingAdd',
            meta: {Title: '基础配置', Auth: '{Wechat}[Work](SettingAdd)'},
            component: () => import(/* webpackChunkName: "index.lazy.work-manage" */ '@/page/wechat-manage/work/base-setting.vue')
          }
        ]
      },
      {
        path: 'platform',
        name: 'PlatformManage.WechatManage',
        redirect: 'platform/qrcode/list',
        meta: {Title: '渠道码管理', Auth: '{Platform}[Wechat]'
        },
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'qrcode',
            redirect: 'qrcode/list',
            // name: 'WechatManage.QRCode',
            meta: {Title: '二维码管理',
              // Auth: '{Platform}[Wechat](QRCodeList)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
            children: [
              {
                path: 'list',
                name: 'PlatformManage.WechatManage.QRCodeList',
                meta: {Title: '二维码列表', Auth: '{Platform}[Wechat](QRCodeList)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/qrcode/list.vue')
              },
              {
                path: 'info/:id',
                name: 'PlatformManage.WechatManage.QRCodeInfo',
                meta: {Title: '二维码详情', Auth: '{Platform}[Wechat](QRCodeInfo)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/qrcode/info.vue')
              },
              {
                path: 'add/:id',
                name: 'PlatformManage.WechatManage.QRCodeAdd',
                meta: {Title: '二维码编辑', Auth: '{Platform}[Wechat](QRCodeAdd)'},
                component: () => import(/* webpackChunkName: "index.lazy.wechat-manage" */ '@/page/wechat-manage/qrcode/add.vue')
              }
            ]
          }
        ]
      }
    ]
  },
// 公众号管理

]
