// 'use strict'

import StoreConst from '../const'
import APICommon from '../../api/common'
import APIUser from '../../api/user'
import UtilsCommon from '../../utils/common'

const state = {
  Admin: null,
  Company: null,
  User: null
}

const getters = {
  [StoreConst.CStateData.Getter.Admin]: state => {
    return state.Admin
  },
  [StoreConst.CStateData.Getter.Company]: state => {
    return state.Company
  },
  [StoreConst.CStateData.Getter.User]: state => {
    return state.User
  }
}

const actions = {
  async [StoreConst.CStateData.Action.Set] ({commit}) {
    let retValue
    // token处理
    if (!UtilsCommon.Cookie.JsonWebToken.Get()) {
      retValue = await APICommon.State.Token()
      if (retValue.hasError) return retValue
      UtilsCommon.Cookie.JsonWebToken.Set(retValue.returnObject)
    }
    // 读取用户状态
    retValue = await APICommon.State.Data()
    if (retValue.returnObject.Company) {
      retValue.returnObject.Company.companyFlag = true// 企业微信管理授权判断
      retValue.returnObject.Company.accountflag = true// 微信平台管理授权判断
    }
    commit(StoreConst.CStateData.Mutation.Set, retValue.hasError ? null : retValue.returnObject)
    return retValue
  },
  async [StoreConst.CStateData.Action.Clear_User] (context) {
    const retValue = await APIUser.Logout()
    !retValue.hasError && (context.commit(StoreConst.CStateData.Mutation.Set_User, null))
    return retValue
  }
}

const mutations = {
  [StoreConst.CStateData.Mutation.Set] (state, data) {
    state.Admin = data ? data.Admin : null
    state.Company = data ? data.Company : null
    state.User = data ? data.User : null
    // console.log(state);
  },
  [StoreConst.CStateData.Mutation.Set_User] (state, data) {
    state.User = data || null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
