'use strict'

export default [
  // 渠道投放
  {
    path: 'channel-delivery',
    name: 'ChannelDelivery',
    meta: { Title: '渠道投放', Auth: '{Content}' },
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      // 内容管理
      {
        path: 'channel-delivery-manage',
        redirect: 'channel-delivery-manage/list',
        name: 'ChannelDeliveryManage',
        meta: { Title: '投放管理', Auth: '{Content}[Project]'},
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'ChannelDeliveryManage.List',
            meta: { Title: '投放列表', Auth: '{ChannelDelivery}[List]' },
            component: () => import(/* webpackChunkName: "index.lazy.channel-delivery" */ '@/page/channel-delivery/channel-delivery-manage/list.vue')
          },
          {
            path: 'info/:id',
            name: 'ChannelDeliveryManage.Info',
            meta: { Title: '查看投放', Auth: '{ChannelDelivery}[Info]' },
            component: () => import(/* webpackChunkName: "index.lazy.channel-delivery" */ '@/page/channel-delivery/channel-delivery-manage/info.vue')
          },
          {
            path: 'add/:id?',
            name: 'ChannelDeliveryManage.Add',
            meta: { Title: '新建/编辑投放', Auth: '{ChannelDelivery}[Add]' },
            component: () => import(/* webpackChunkName: "index.lazy.channel-delivery" */ '@/page/channel-delivery/channel-delivery-manage/add.vue')
          }
        ]
      },
      {
        path: 'channel-delivery-data',
        redirect: 'channel-delivery-data/list',
        name: 'ChannelDeliveryData',
        meta: { Title: '投放数据' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          // 内容标签
          {
            path: 'list',
            name: 'ChannelDeliveryManage.DataList',
            meta: { Title: '投放数据列表', Auth: '{ChannelDelivery}[DataList]' },
            component: () => import(/* webpackChunkName: "index.lazy.channel-delivery" */ '@/page/channel-delivery/channel-delivery-data/list')
          },
          {
            path: 'info/:id',
            name: 'ChannelDeliveryManage.DataInfo',
            meta: { Title: '投放数据详情', Auth: '{ChannelDelivery}[DataList]' },
            component: () => import(/* webpackChunkName: "index.lazy.channel-delivery" */ '@/page/channel-delivery/channel-delivery-data/info')
          },
        ]
      }
    ]
  }
]
