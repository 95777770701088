'use strict'
/* eslint-disable */
import APIService from './service'

export default {
  Login: (data) => {
    data = Object.assign({
      Company_ID: null,
      Phone: null,
      Login_Password: null
    }, data)
    return APIService.RequestPost('/biz/login', data)
  },
  Logout: () => APIService.RequestPost('/biz/logout'),
  Change_Password: (data) => {
    data = Object.assign({
      Old_Password: null,
      New_Password: null,
      Check_Password: null
    }, data)
    return APIService.RequestPost('/biz/change-password', data)
  },
  ComponentAuthURL: (data) => {
    data = Object.assign({
      Company_ID: null,
      APP_Type: null,
      FromURL: null
    }, data)
    return `${process.env.VUE_APP_API_URL}biz/component-authurl?Company_ID=${data.Company_ID}&APP_Type=${data.APP_Type}&FromURL=${data.FromURL}`
  },
  Config: () => APIService.RequestGet('/biz/config'),
  LogManage: {
    Employee: {
      List: (data) => {
        data = Object.assign({
          Limit: null,
          Type: null,
          Relate_ID: null,
          Date_Begin: null,
          Date_End: null
        }, data)
        return APIService.RequestPost('/biz/log-manage/employee/list', data)
      }
    }
  },
  Analysis: (flag) => APIService.RequestGet(`/biz/Analysis?${flag}`),
  getWeChatofficialaccountList: (flag) => APIService.RequestGet(`/biz/getWeChatofficialac/countList?${flag}`),
  getaccountofficialaccountList: (flag) => APIService.RequestGet(`/biz/getaccountofficialac/countList?${flag}`),
  getSingleWeChatofficialac: (flag) => APIService.RequestGet(`/biz/SingleWeChat/countList?${flag}`),
  BaseManage: {
    CompanyManage: {
      App: {
        List: (data) => {
          data = Object.assign({
            APP_Type: null
          }, data)
          return APIService.RequestPost('/biz/user/base-manage/company-manage/app-list', data)
        }
      },
      Company: {
        Info: () => APIService.RequestGet('/biz/user/base-manage/company-manage/info'),
        Update_Info: (data) => {
          data = Object.assign({
            Company_Name: null,
            Contact_Name: null,
            Contact_Phone: null,
            Telephone: null,
            Address: null,
            SecretKey: null,
            PushURL: null,
            IPList: null
          }, data)
          return APIService.RequestPost('/biz/user/base-manage/company-manage/info-update', data)
        }
      },
      Employee_Info: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            F_DateBegin: null,
            F_DateEnd: null,
            F_FuzzyQuery: null,
            F_Status: 0
          }, data)
          return APIService.RequestPost('/biz/user/base-manage/company-manage/employee-page', data)
        },
        Info: (id) => APIService.RequestGet(`/biz/user/base-manage/company-manage/employee-info/${id}`),
        Update: (data) => {
          data = Object.assign({
            Employee_ID: null,
            Phone: null,
            RealName: null,
            Status: null,
            RightCode: null
          }, data)
          return APIService.RequestPost('/biz/user/base-manage/company-manage/employee-add', data)
        }
      }
    },
    LogManage: {
      Operation: {
        List_Filter: () => {
          return APIService.RequestGet(`/biz/user/base-manage/log-manage/operation-filter-list`)
        },
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            F_DateBegin: null,
            F_DateEnd: null,
            F_SType: null,
            F_SValue: null,
            F_Phone: null,
            F_Type: null,
            F_CreateIP: null,
            Sort_Dir: null,
            Sort_Col: null
          }, data)
          return APIService.RequestPost('/biz/user/base-manage/log-manage/operation-list', data)
        }
      }
    }
  },
  MemberManage: {
    UserManage: {
      Info: {
        AppList: () => APIService.RequestGet('/biz/user/member-manage/user-manage/app-list'),
        UserList: (data) => {
          data = Object.assign({
            FuzzyQuery: null,
            APP_Type: null
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/user-manage/listj-app', data)
        },
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            F_DateBegin: null,
            F_DateEnd: null,
            F_SType: null,
            F_SValue: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Subscribe: null,
            F_Status: -1,
            F_APP: null,
            F_DateType: 'Create'
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/user-manage/list', data)
        },
        Info: (id) => APIService.RequestGet(`/biz/user/member-manage/user-manage/info/${id}`),
        Relation: (id) => APIService.RequestGet(`/biz/relation-list/${id}`)
      },
      Log: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            F_DateBegin: null,
            F_DateEnd: null,
            F_SType: null,
            F_SValue: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Phone: null,
            F_Type: null,
            F_CreateIP: null,
            F_APPID: null,
            F_RelateID: null
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/user-manage/log-list', data)
        }
      }
    },
    TagManage: {
      Tag: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Date_Begin: null,
            F_Date_End: null,
            F_SType: null,
            F_SValue: null
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/tag-manage/list', data)
        },
        Info: (id) => {
          return APIService.RequestPost(`/biz/user/member-manage/tag-manage/info/${id}`)
        },
        Add: (data) => {
          // 信息添加
          data = Object.assign({
            Tag_ID: null,
            Name: null
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/tag-manage/add', data)
        }
      },
      UserTag: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Date_Begin: null,
            F_Date_End: null,
            F_SType: null,
            F_SValue: null
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/tag-manage/list-tag', data)
        },
        Info: (data) => {
          data = Object.assign({
            User_ID_Company: null
          }, data)
          return APIService.RequestPost(`/biz/user/member-manage/tag-manage/info-tag`, data)
        },
        Add: (data) => {
          // 信息添加
          data = Object.assign({
            Tag_ID: null,
            User_ID_Company: null
          }, data)
          return APIService.RequestPost('/biz/user/member-manage/tag-manage/add-tag', data)
        }
      }
    }
  },
  PlatformManage: {
    Wechat_Manage: {
      Menu: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Status: null,
            F_Date_Begin: null,
            F_Date_End: null,
            F_SType: null,
            F_SValue: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/menu-list', data)
        },
        Info: (id) => {
          return APIService.RequestPost(`/biz/platform-manage/wechat-manage/menu-info/${id}`)
        },
        Add: (data) => {
          // 信息添加
          data = Object.assign({
            Menu_ID: null,
            Menu_Name: null,
            APP_ID: null,
            Content: null,
            Status: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/menu-add', data)
        }
      },
      SubscribeMessage: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Status: null,
            F_Date_Begin: null,
            F_Date_End: null,
            F_SType: null,
            F_SValue: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/subscribemessage-list', data)
        },
        Info: (id) => {
          return APIService.RequestPost(`/biz/platform-manage/wechat-manage/subscribemessage-info/${id}`)
        },
        Add: (data) => {
          // 信息添加
          data = Object.assign({
            Message_ID: null,
            Message_Name: null,
            APP_ID: null,
            Content: null,
            Status: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/subscribemessage-add', data)
        }
      },
      KeyMessage: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_Status: null,
            F_Date_Begin: null,
            F_Date_End: null,
            F_SType: null,
            F_SValue: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/keymessage-list', data)
        },
        Info: (id) => {
          return APIService.RequestPost(`/biz/platform-manage/wechat-manage/keymessage-info/${id}`)
        },
        Add: (data) => {
          // 信息添加
          data = Object.assign({
            Key_ID: null,
            Key_Name: null,
            APP_ID: null,
            Content: null,
            Status: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/keymessage-add', data)
        }
      },
      QRCode: {
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_FuzzyQuery: null,
            F_Type: null,
            F_Date_Begin: null,
            F_Date_End: null,
            F_Status: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/qrcode-list', data)
        },
        Info: (id) => {
          return APIService.RequestGet(`/biz/platform-manage/wechat-manage/qrcode-info/${id}`)
        },
        Add: (data) => {
          data = Object.assign({
            QRCode_ID: null,
            Name: null,
            APP_ID: null,
            Type: null,
            Param_Source: null,
            Param_Tags: null,
            Param_Other: null,
            Param_Inviter: null,
            Status: null
          }, data)
          return APIService.RequestPost('/biz/platform-manage/wechat-manage/qrcode-add', data)
        }
      }
    }
  },
  WechatWorkManage: {
    SettingManage: {
      Add: async (data) => {
        data = Object.assign({
          Corp_ID: null,
          Contact_Secret: null,
          Contact_Token: null,
          Contact_EncodingAESKey: null,
          ExternalContact_Secret: null,
          ExternalContact_Token: null,
          ExternalContact_EncodingAESKey: null,
          ExternalContact_APP_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechatwork-manage/setting-manage/add', data)
      }
    },
    CompanyManage: {
      Employee: {
        Employee_Sync: (data) => {
          data = Object.assign({
            Type: null,
            UserID: null
          }, data)
          return APIService.RequestPost('/biz/wechatwork-manage/company-manage/employee-sync', data)
        },
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_SType: null,
            F_SValue: null,
            Department_ID: null
          }, data)
          return APIService.RequestPost('/biz/wechatwork-manage/company-manage/employee-list', data)
        },
        Info: (id) => {
          return APIService.RequestPost(`/biz/wechatwork-manage/company-manage/employee-info/${id}`)
        },
        Add: (data) => {
          data = Object.assign({
            Tag_ID: null,
            Name: null
          }, data)
          return APIService.RequestPost('/biz/wechatwork-manage/company-manage/employee-add', data)
        },
        Delete: (id) => {
          return APIService.RequestPost(`/biz/wechatwork-manage/company-manage/employee-delete/${id}`)
        }
      },
      Department: {
        Department_Sync: () => {
          return APIService.RequestGet('/biz/wechatwork-manage/company-manage/department-sync')
        },
        List: () => {
          return APIService.RequestGet('/biz/wechatwork-manage/company-manage/department-list')
        }
      }
    },
    CustomerManage: {
      Customer: {
        Customer_Sync: (data) => {
          data = Object.assign({
            UserID: null
          }, data)
          return APIService.RequestPost('/biz/wechatwork-manage/customer-manage/customer-sync', data)
        },
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_SType: null,
            F_SValue: null,
            F_Type: null,
            F_Gender: null,
            F_UserID: null
          }, data)
          return APIService.RequestPost('/biz/wechatwork-manage/customer-manage/list', data)
        },
        Info: (data) => {
          return APIService.RequestPost(`/biz/wechatwork-manage/customer-manage/info/${data}`)
        }
      },
      Group: {
        Group_Sync: () => {
          return APIService.RequestGet('/biz/wechatwork-manage/customer-manage/group-sync')
        },
        List: (data) => {
          data = Object.assign({
            P_Start: 0,
            P_Length: 20,
            P_Get: true,
            P_MaxCode: null,
            Sort_Dir: null,
            Sort_Col: null,
            F_SType: null,
            F_SValue: null,
            F_Name: null,
            F_Owner: null,
            F_Status: null
          }, data)
          return APIService.RequestPost('/biz/wechatwork-manage/customer-manage/group-list', data)
        },
        Info: (data) => {
          return APIService.RequestPost(`/biz/wechatwork-manage/customer-manage/group-info/${data.ChatID}`)
        }
      }
    },
    ContactManage: {
      Department_List: (data) => {
        data = Object.assign({
          Parent_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechatwork-manage/company-manage/department-list', data)
      },
      Employee_List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_SType: null,
          F_SValue: null,
          Department_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechatwork-manage/company-manage/employee-list', data)
      }
    },
    TagManage: {
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_SType: null,
          F_SValue: null
        }, data)
        return APIService.RequestPost('/biz/wechatwork-manage/tag-manage/list', data)
      },
      Tag_Sync: () => {
        return APIService.RequestGet('/biz/wechatwork-manage/tag-manage/tag-sync')
      },
      EmployeeTag_Sync: (data) => {
        data = Object.assign({
          Tag_ID: null
        }, data)
        return APIService.RequestGet('/biz/wechatwork-manage/tag-manage/employeetag-sync', data)
      },
      List_Filter: (data) => {
        data = Object.assign({
          FuzzyQuery: null,
          Limit: null
        }, data)
        return APIService.RequestPost('/biz/wechatwork-manage/tag-manage/list-filter', data)
      }
    }
  },
  // region 用户管理
  CustomerManage: {
    // 用户列表
    List: (data) => {
      data = Object.assign({
        P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
        P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
        P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
        P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
        Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
        Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
        F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
        F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
        F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
        F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
        F_CustomerID: null,                   // 用户ID [Number] [必传:否]
        F_Gender: null,                       // 性别 [Number] [必传:否] 0：未知 1：男性 2：女性
        F_IsWechat: null,                     // 是否微信用户 [Bool] [必传:否]
        F_IsWechatWork: null,                 // 是否企业微信用户 [Bool] [必传:否]
        F_IsPhone: null,                      // 是否手机用户 [Bool] [必传:否]
        F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
      }, data)
      return APIService.RequestPost('/biz/customer-manage/list', data)
    },
    // 用户详情
    Info: (id) => APIService.RequestPost('/biz/customer-manage/info/' + id)
  },
  // endregion
  // region 微信平台管理
  WechatManage: {
    // region 基础授权管理
    APPManage: {
      // 授权列表
      List: (data) => {
        data = Object.assign({
          APP_Type: null // APP类型 [Array] [必传:否] [1 公众号 2 小程序 3 QQ 101 安卓APP 102 IOS 999 其他]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/app-manage/list', data)
      },
      // 授权详情
      Info: (id) => {
        return APIService.RequestGet(`/biz/wechat-manage/app-manage/info/${id}`)
      }
    },
    // endregion
    // region 公众号管理
    MPManage: {
      // 模板消息列表
      Template_Message_List: (data) => {
        data = Object.assign({
          P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
          P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
          P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
          P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
          Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
          Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
          F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
          F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
          F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
          F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
          F_APPID: null,                        // 公众号ID [Number] [必传:否]
          F_TemplateID: null,                   // 模板id [String] [必传:否]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/template-message-list', data)
      },
      // 模板消息详情
      Template_Message_Info: (data) => {
        data = Object.assign({
          APP_ID: null,
          Template_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/template-message-info', data)
      },
      // 模板消息同步
      Template_Message_Sync: (data) => {
        data = Object.assign({
          APP_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/template-message-sync', data)
      },
      // 模板消息推送列表
      Template_Message_Push_List: (data) => {
        data = Object.assign({
          P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
          P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
          P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
          P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
          Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
          Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
          F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
          F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
          F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
          F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
          F_APPID: null,                        // 公众号ID [Number] [必传:否]
          F_TemplateID: null,                   // 模板ID [String] [必传:否]
          F_StatusPush: null,                   // 推送状态 [Number] [必传:否] [0 完成 1 待发送 2 发送中]
          F_Status: null                        // 状态 [Number] [必传:否] [0 正常 1 锁定]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/template-message-push-list', data)
      },
      // 模板消息推送详情
      Template_Message_Push_Info: (id) => APIService.RequestGet(`/biz/wechat-manage/mp-manage/template-message-push-info/${id}`),
      // 模板消息推送添加
      Template_Message_Push_Add: (data) => {
        data = Object.assign({
          Push_ID: null,                      // ID [Number] [必传:修改必传]
          Push_Name: false,                   // 名称 [String] [必传:是]
          Tag_ID: null,                       // 标签ID [Number] [必传:否] [默认:全部]
          Push_Date: null,                    // 推送时间 [Date] [必传:否] [默认:立即推送][定时推送传递定时推送时间]
          APP_ID: null,                       // 公众号ID [Number] [必传:是]
          Template_ID: null,                  // 模板ID [String] [必传:是]
          Template_Data: null,                // 模板变量 [JSON] [必传:是] [{"key1":{"value":"变量值"},"key2":{"value":"变量值"}}]
          Target_Type: null,                  // 跳转类型 [Number] [必传:否] [默认:1] [1:无 2:H5 3:小程序]
          Target_Setting: null,               // 跳转类型 [JSON] [必传:否] [默认:null] [Target_Type = 2 {URL:''} Target_Type = 3 {APP_ID:1000,APP_No:'wxaadfdsafdafdas',PagePath:'/pages/index'}]
          Status: null,                       // 状态 [Number] [必传:否] [默认:0] [0:正常 1:锁定]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/template-message-push-add', data)
      },
      // 订阅通知列表
      Subscription_Message_List: (data) => {
        data = Object.assign({
          P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
          P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
          P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
          P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
          Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
          Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
          F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
          F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
          F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
          F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
          F_APPID: null,                        // 公众号ID [Number] [必传:否]
          F_TemplateID: null,                   // 模板id [String] [必传:否]
          F_Type: null,                         // 模板类型 [Number] [必传:否] [2 为一次性订阅，3 为长期订阅]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/subscription-message-list', data)
      },
      // 订阅通知详情
      Subscription_Message_Info: (data) => {
        data = Object.assign({
          APP_ID: null,
          Template_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/subscription-message-info', data)
      },
      // 订阅通知同步
      Subscription_Message_Sync: (data) => {
        data = Object.assign({
          APP_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/subscription-message-sync', data)
      },
      // 订阅通知推送列表
      Subscription_Message_Push_List: (data) => {
        data = Object.assign({
          P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
          P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
          P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
          P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
          Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
          Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
          F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
          F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
          F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
          F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
          F_APPID: null,                        // 公众号ID [Number] [必传:否]
          F_TemplateID: null,                   // 模板ID [String] [必传:否]
          F_StatusPush: null,                   // 推送状态 [Number] [必传:否] [0 完成 1 待发送 2 发送中]
          F_Status: null                        // 状态 [Number] [必传:否] [0 正常 1 锁定]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/subscription-message-push-list', data)
      },
      // 订阅通知推送详情
      Subscription_Message_Push_Info: (id) => APIService.RequestGet(`/biz/wechat-manage/mp-manage/subscription-message-push-info/${id}`),
      // 订阅通知推送添加
      Subscription_Message_Push_Add: (data) => {
        data = Object.assign({
          Push_ID: null,                      // ID [Number] [必传:修改必传]
          Push_Name: false,                   // 名称 [String] [必传:是]
          Push_Date: null,                    // 推送时间 [Date] [必传:否] [默认:立即推送][定时推送传递定时推送时间]
          APP_ID: null,                       // 公众号ID [Number] [必传:是]
          Template_ID: null,                  // 模板ID [String] [必传:是]
          Template_Data: null,                // 模板变量 [JSON] [必传:是] [{"key1":{"value":"变量值"},"key2":{"value":"变量值"}}]
          Target_Type: null,                  // 跳转类型 [Number] [必传:否] [默认:1] [1:无 2:H5 3:小程序]
          Target_Setting: null,               // 跳转类型 [JSON] [必传:否] [默认:null] [Target_Type = 2 {URL:''} Target_Type = 3 {APP_ID:1000,APP_No:'wxaadfdsafdafdas',PagePath:'/pages/index'}]
          Status: null,                       // 状态 [Number] [必传:否] [默认:0] [0:正常 1:锁定]
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/mp-manage/subscription-message-push-add', data)
      },
    },
    // endregion
    // region 企业微信管理
    WorkManage: {
      /**
       * 设置
       * @param {Object} data
       * @param {string} [data.Corp_ID] 企业ID
       * @param {string} [data.Contact_Secret] 通讯录 Secret
       * @param {string} [data.Contact_Token] 通讯录接收事件服务器 Token
       * @param {string} [data.Contact_EncodingAESKey] 通讯录接收事件服务器 EncodingAESKey
       * @param {string} [data.ExternalContact_Secret] 客户联系 Secret
       * @param {string} [data.ExternalContact_Token] 客户联系接收事件服务器 Token
       * @param {string} [data.ExternalContact_EncodingAESKey] 客户联系接收事件服务器 EncodingAESKey
       * @param {string} [data.ExternalContact_APP_ID] 客户联系绑定的企业APP_ID
       * @returns {*}
       */
      Setting_Add: (data) => {
        data = Object.assign({
          Corp_ID: null,
          Contact_Secret: null,
          Contact_Token: null,
          Contact_Event: null,
          Contact_EncodingAESKey: null,
          ExternalContact_Secret: null,
          ExternalContact_Token: null,
          ExternalContact_Event: null,
          ExternalContact_EncodingAESKey: null,
          ExternalContact_APP_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/setting-add', data)
      },
      /**
       * 部门同步
       * @returns {*}
       */
      Department_Sync: () => APIService.RequestGet('/biz/wechat-manage/work-manage/department-sync'),
      /**
       * 部门列表
       * @param {Object} data
       * @param {number} [data.Parent_ID] 父部门id。根部门为1
       * @returns {*}
       */
      Department_List: (data) => {
        data = Object.assign({
          Parent_ID: null,
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/department-list', data)
      },
      /**
       * 员工同步
       * @param {Object} data
       * @param {number} [data.Department_ID=1] 部门ID
       * @param {string} [data.UserID] 员工ID
       * @returns {*}
       */
      Employee_Sync: (data) => {
        data = Object.assign({
          Department_ID: null,
          UserID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/employee-sync', data)
      },
      /**
       * 员工列表
       * @param {Object} data
       * @param {boolean} [data.Cache=false] 是否启用缓存
       * @param {number} data.P_Start 分页起始 0
       * @param {number} data.P_Length 分页长度
       * @param {boolean} [data.P_Get=false] 是否获取分页数据
       * @param {string} [data.P_MaxCode=''] 数据最大值
       * @param {string} [data.Sort_Dir='DESC'] 排序
       * @param {string} [data.Sort_Col] 排序字段
       * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
       * @param {string} [data.F_SValue] 过滤具体内容
       * @param {number} [data.F_Department] 部门ID
       * @returns {*}
       */
      Employee_List: (data) => {
        data = Object.assign({
          Cache: null,
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_SType: null,
          F_SValue: null,
          F_Department: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/employee-list', data)
      },
      /**
       * 员工信息
       * @param {number} id 员工ID
       * @returns {*}
       */
      Employee_Info: (id) => APIService.RequestGet(`/biz/wechat-manage/work-manage/employee-info/${id}`),
      /**
       * 标签同步
       * @returns {*}
       */
      Tag_Sync: () => APIService.RequestPost('/biz/wechat-manage/work-manage/tag-sync'),
      /**
       * 标签员工同步
       * @param {number} id 标签ID
       * @returns {*}
       */
      Tag_Employee_Sync: (id) => APIService.RequestPost('/biz/wechat-manage/work-manage/tag-employee-sync', { Tag_ID: id }),
      /**
       * 标签列表
       * @param {Object} data
       * @param {boolean} [data.Cache=false] 是否启用缓存
       * @param {number} data.P_Start 分页起始 0
       * @param {number} data.P_Length 分页长度
       * @param {boolean} [data.P_Get=false] 是否获取分页数据
       * @param {string} [data.P_MaxCode=''] 数据最大值
       * @param {string} [data.Sort_Dir='DESC'] 排序
       * @param {string} [data.Sort_Col] 排序字段
       * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
       * @param {string} [data.F_SValue] 过滤具体内容
       * @returns {*}
       */
      Tag_List: (data) => {
        data = Object.assign({
          Cache: null,
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_SType: null,
          F_SValue: null,
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/tag-list', data)
      },
      Tag_User_List: (data) => {
        data = Object.assign({
          Tag: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/tag-user-list', data)
      },
      /**
       * 客户同步
       * @param {number} id 员工ID
       * @returns {*}
       */
      Customer_Sync: (id) => APIService.RequestPost('/biz/wechat-manage/work-manage/customer-sync', { UserID: id }),
      /**
       * 客户列表
       * @param {Object} data
       * @param {boolean} [data.Cache=false] 是否启用缓存
       * @param {number} data.P_Start 分页起始 0
       * @param {number} data.P_Length 分页长度
       * @param {boolean} [data.P_Get=false] 是否获取分页数据
       * @param {string} [data.P_MaxCode=''] 数据最大值
       * @param {string} [data.Sort_Dir='DESC'] 排序
       * @param {string} [data.Sort_Col] 排序字段
       * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
       * @param {string} [data.F_SValue] 过滤具体内容
       * @param {Array} [data.F_UserID] 员工ID
       * @param {Array} [data.F_Type] 外部联系人的类型，1表示该外部联系人是微信用户，2表示该外部联系人是企业微信用户
       * @param {number} [data.F_Gender] 性别 0表示未定义，1表示男性，2表示女性
       * @returns {*}
       */
      Customer_List: (data) => {
        data = Object.assign({
          Cache: null,
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_SType: null,
          F_SValue: null,
          F_UserID: null,
          F_Type: null,
          F_Gender: null,
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/customer-list', data)
      },
      /**
       * 客户详情
       * @param {number} id 客户ID
       * @returns {*}
       */
      Customer_Info: (id) => APIService.RequestGet(`/biz/wechat-manage/work-manage/customer-info/${id}`),
      /**
       * 客户群同步
       * @param {Object} data
       * @param {string} [data.UserID] 员工ID
       * @param {string} [data.Chat_ID] 客户群ID
       * @returns {*}
       */
      Group_Sync: (data) => {
        data = Object.assign({
          UserID: null,
          Chat_ID: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/group-sync', data)
      },
      /**
       * 客户群列表
       * @param {Object} data
       * @param {boolean} [data.Cache=false] 是否启用缓存
       * @param {number} data.P_Start 分页起始 0
       * @param {number} data.P_Length 分页长度
       * @param {boolean} [data.P_Get=false] 是否获取分页数据
       * @param {string} [data.P_MaxCode=''] 数据最大值
       * @param {string} [data.Sort_Dir='DESC'] 排序
       * @param {string} [data.Sort_Col] 排序字段
       * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
       * @param {string} [data.F_SValue] 过滤具体内容
       * @param {Array} [data.F_ChatID] 客户群ID
       * @param {number} [data.F_Owner] 群主ID
       * @returns {*}
       */
      Group_List: (data) => {
        data = Object.assign({
          Cache: null,
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_SType: null,
          F_SValue: null,
          F_ChatID: null,
          F_Owner: null
        }, data)
        return APIService.RequestPost('/biz/wechat-manage/work-manage/group-list', data)
      },
      /**
       * 客户群详情
       * @param {number} id 客户群ID
       * @returns {*}
       */
      Group_Info: (id) => APIService.RequestGet(`/biz/wechat-manage/work-manage/group-info/${id}`),
    },
    // endregion
  },
  // endregion
  // region 通知中心
  NotificationManage: {
    // 小程序订阅消息模板列表
    WeAPPSubscribeTmp_List: () => APIService.RequestGet(`/biz/liubike/notification-manage/weappsubscribetmp-list`),
    // 消息列表
    Message_List: (data) => {
      data = Object.assign({
        P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
        P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
        P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
        P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
        Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
        Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
        F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
        F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
        F_MessageType: null,                  // 类型过滤 [String] [必传:否]
        F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
        F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
        F_Status: null                        // 状态 [Number] [必传:否] [0 正常 1 锁定]
      }, data)
      return APIService.RequestPost('/biz/liubike/notification-manage/message-list', data)
    },
    // 消息详情
    Message_Info: (id) => APIService.RequestGet(`/biz/liubike/notification-manage/message-info/${id}`),
    /**
     * 消息添加
     * @param {Object} data
     * @param {number} [data.Message_ID] 消息ID
     * @param {string} data.Message_Name 消息名称
     * @param {string} data.Message_Type 消息类型 ['SMS':短信]['APPPush':APP推送]['WeAPPSubscribeMessage':小程序订阅消息]
     * @param {string} [data.Content] SMS必传。短信内容
     * @param {boolean} [data.ClickAction] APPPush必传。可否点击
     * @param {string} [data.TemplateCode] APPPush必传。模板编号
     * @param {string} [data.TitleParam] APPPush推送标题 无
     * @param {string} [data.TextParam] APPPush推送内容
     * @param {string} [data.Template_ID] WeAPPSubscribeMessage必传。订阅模板ID
     * @param {JSON} [data.Template_Data] WeAPPSubscribeMessage必传。订阅模板内容
     * @param {number} [data.Target_Type] 跳转类型 [1:不跳转][2:跳转小程序]
     * @param {string} [data.Page] 跳转小程序路径
     * @param {string} [data.Status] 状态 [0:正常][1:锁定]
     * @returns {*}
     */
    Message_Add: (data) => {
      data = Object.assign({
        Message_ID: null,
        Message_Name: null,
        Message_Type: null,
        Content: null,
        ClickAction: null,
        TemplateCode: null,
        TextParam: null,
        Template_ID: null,
        Template_Data: null,
        Target_Type: null,
        Page: null,
        Status: null
      }, data)
      return APIService.RequestPost('/biz/liubike/notification-manage/message-add', data)
    },
  },
  // endregion
  ToolsManage: {
    WechatManage: {
      QRCodeList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_FuzzyQuery: null,
          F_Type: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_Status: null
        }, data)
        return APIService.RequestPost('/biz/tools-manage/wechat-manage/qrcode-list', data)
      },
      QRCodeInfo: (id) => {
        return APIService.RequestGet(`/biz/tools-manage/wechat-manage/qrcode-info/${id}`)
      },
      QRCodeAdd: (data) => {
        data = Object.assign({
          QRCode_ID: null,
          Name: null,
          APP_ID: null,
          Type: null,
          Param_Source: null,
          Param_Tags: null,
          Param_Other: null,
          Param_Inviter: null,
          Status: null
        }, data)
        return APIService.RequestPost('/biz/tools-manage/wechat-manage/qrcode-add', data)
      }
    }
  },
  QuestionSurveyManage: {
    Activity_Manage: {
      List_Filter: (data) => {
        data = Object.assign({
          FuzzyQuery: null
        }, data)
        return APIService.RequestPost('/biz/questionsurvey-manage/activity-manage/list-filter', data)
      },
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_FuzzyQuery: null,
          F_Type: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_Status: null
        }, data)
        return APIService.RequestPost('/biz/questionsurvey-manage/activity-manage/list-page', data)
      },
      Info: (id) => {
        return APIService.RequestGet(`/biz/questionsurvey-manage/activity-manage/info/${id}`)
      },
      Add: (data) => {
        data = Object.assign({
          Activity_ID: null,
          Activity_Name: null,
          Activity_Intro: null,
          Date_Begin: null,
          Date_End: null,
          Status: null
        }, data)
        return APIService.RequestPost('/biz/questionsurvey-manage/activity-manage/add', data)
      }
    },
    Question_Manage: {
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_FuzzyQuery: null,
          F_Type: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_Status: null,
          F_Activity_ID: null
        }, data)
        return APIService.RequestPost('/biz/questionsurvey-manage/question-manage/list-page', data)
      },
      Info: (id) => {
        return APIService.RequestGet(`/biz/questionsurvey-manage/question-manage/info/${id}`)
      },
      Add: (data) => {
        data = Object.assign({
          Activity_ID: null,
          Question_ID: null,
          Question_Name: null,
          Options: null,
          Placeholder: null,
          Type: null,
          List_Order: null,
          Status: null
        }, data)
        return APIService.RequestPost('/biz/questionsurvey-manage/question-manage/add', data)
      }
    },
    Result_Manage: {
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_FuzzyQuery: null,
          F_Type: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_Status: null,
          F_Activity_ID: null
        }, data)
        return APIService.RequestPost('/biz/questionsurvey-manage/result-manage/list-page', data)
      },
      Info: (id) => {
        return APIService.RequestGet(`/biz/questionsurvey-manage/result-manage/info/${id}`)
      }
    }
  },
  ContentManage: {
    AnalysisManage: {
      Info: (data) => {
        data = Object.assign({
          Date_Begin: null,
          Date_End: null,
          Project_ID: null,
          Total_Data_End: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/analysis-manage/info', data)
      }
    },
    ProjectManage: {
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_Status: null,
          F_DateBegin: null,
          F_DateEnd: null,
          F_FuzzyQuery: null,
          F_SType: null,
          F_SValue: null,
          F_Type: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/project-manage/list', data)
      },
      Info: (id) => {
        return APIService.RequestGet(`/biz/content-manage/project-manage/info/${id}`)
      },
      Add: (data) => {
        // 活动信息添加
        data = Object.assign({
          Status: null,
          Project_ID: null,
          Project_Name: null,
          Project_Type: null,
          Date_Begin: null,
          Date_End: null,
          Content_Tags: null,
          Project_Intro: null,
          Target_Type: null,
          Target_Setting: null,
          Target_Remark: null,
          Share_Content: null,
          Share_Text: null,
          Share_Desc: null,
          CanvasSetting: null,
          IsDaily: null,
          Publish_Status: null,
          Publish_Date: null,
          Employee: null,
          Level: null,
          Main_Pic: null,
          Share_Pic: null,
          Video_ID: null,
          Video_Main_Pic: null,
          Video_Url: null,
          Article_ID: null,
          Article_Content: null,
          List_Order: null,
          Setting: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/project-manage/add-new', data)
      },
      Poster_Del: (data) => {
        data = Object.assign({
          ID: null,
          Project_ID: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/project-manage/poster-del', data)
      },
      Approval: (data) => {
        data = Object.assign({
          Project_ID: null,
          Approval_Status: null,
          Approval_Remark: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/project-manage/approval', data)
      },
    },
    MaterialManage: {
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_Status: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_FuzzyQuery: null,
          F_SType: null,
          F_SValue: null,
          F_Type: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/material-manage/list', data)
      },
      Info: (id) => {
        return APIService.RequestGet(`/biz/content-manage/material-manage/info/${id}`)
      },
      Add: (data) => {
        data = Object.assign({
          Status: 0,
          Material_ID: null,
          Material_Name: null,
          Type: null,
          Main_Name: null,
          Video_Temp: null,
          Font_Temp: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/material-manage/add-new', data)
      }
    },
    TagManage: {
      List_Filter: (data) => {
        data = Object.assign({
          FuzzyQuery: null,
          Limit: 100
        }, data)
        return APIService.RequestPost('/biz/content-manage/tag-manage/list-filter', data)
      },
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_Status: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_FuzzyQuery: null,
          F_SType: null,
          F_SValue: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/tag-manage/list', data)
      },
      Info: (data) => {
        data = Object.assign({
          Tag_ID: null,
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null
        }, data)
        return APIService.RequestPost(`/biz/content-manage/tag-manage/info`, data)
      },
      Add: (data) => {
        data = Object.assign({
          Tag_ID: null,
          Tag_Name: null,
          Status: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/tag-manage/add', data)
      }
    },
    BannerManage: {
      List: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_Status: null,
          F_Date_Begin: null,
          F_Date_End: null,
          F_FuzzyQuery: null,
          F_SType: null,
          F_SValue: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/banner-manage/list', data)
      },
      Info: (id) => {
        return APIService.RequestGet(`/biz/content-manage/banner-manage/info/${id}`)
      },
      Add: (data) => {
        data = Object.assign({
          Banner_ID: null,
          Banner_Name: null,
          Banner_Type: null,
          Setting: null,
          Banner_Pic: null,
          BGColor: null,
          Date_Begin: null,
          Date_End: null,
          List_Order: null,
          Status: null
        }, data)
        return APIService.RequestPost('/biz/content-manage/banner-manage/add', data)
      }
    }
  },
  // region 渠道投放管理
  ChannelDeliveryManage: {
    /**
     * 投放列表
     * @param {Object} data
     * @param {boolean} [data.Cache=false] 是否启用缓存
     * @param {number} data.P_Start 分页起始 0
     * @param {number} data.P_Length 分页长度
     * @param {boolean} [data.P_Get=false] 是否获取分页数据
     * @param {string} [data.P_MaxCode=''] 数据最大值
     * @param {string} [data.Sort_Dir='DESC'] 排序
     * @param {string} [data.Sort_Col] 排序字段
     * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
     * @param {string} [data.F_SValue] 过滤具体内容
     * @param {string} [data.F_DateBegin] 开始时间
     * @param {string} [data.F_DateEnd] 结束时间
     * @param {string} [data.F_DeliveryType] 投放渠道 1:网页链接 2:小程序 3:公众号 4:企业微信名片
     * @returns {*}
     */
    List: (data) => {
      data = Object.assign({
        Cache: false,
        P_Start: 0,
        P_Length: 20,
        P_Get: true,
        P_MaxCode: null,
        Sort_Dir: null,
        Sort_Col: null,
        F_Status: null,
        F_SType: null,
        F_SValue: null,
        F_DateBegin: null,
        F_DateEnd: null,
        F_DeliveryType: null
      }, data)
      return APIService.RequestPost('/biz/channeldelivery-manage/list', data)
    },
    /**
     * 投放详情
     * @param {string} id 投放ID
     * @returns {*}
     */
    Info: (id) => APIService.RequestGet(`/biz/channeldelivery-manage/info/${id}`),
    /**
     * 投放编辑
     * @param {Object} data
     * @param {number} data.Delivery_ID 投放ID
     * @param {string} data.Delivery_Name 投放名称
     * @param {number} data.Delivery_Type 投放渠道 1:网页链接 2:小程序 3:公众号 4:企业微信名片
     * @param {Object} data.Setting 投放配置
     *     '网页链接': {
     *       Type: '', 形式 Link 链接 QRCode 二维码
     *       URL: '' 链接地址
     *     },
     *     '小程序': {
     *       Type: '', 形式 Link 链接 QRCode 二维码
     *       APP_ID: 0, APP_ID
     *       QRCode_Type: 0, 二维码类型 1 临时 2 永久 为二维码形式时必填
     *       Page: ''  小程序路径 为链接时必填
     *     },
     *     '公众号': {
     *       APP_ID: 0, APP_ID
     *       QRCode_Type: 0 二维码类型 1 临时 2 永久 为二维码形式时必填
     *     },
     *     '企业微信名片': {
     *      按部门
     *       Department: [
     *         {
     *           Department_ID: 0, 部门ID
     *           Department_Name: '' 部门名称
     *         },
     *       ],
     *       按员工
     *       Employee: [
     *         {
     *           Department: '', 部门
     *           User_ID: 0, 员工用户ID
     *           Name: '' 员工名称
     *         }
     *       ],
     *       按员工标签
     *       Tag: [
     *         {
     *           Tag_ID: 0, 标签ID
     *           Tag_Name: '' 标签名称
     *         }
     *       ]
     *     }
     * @param {string} data.Remark 备注
     * @param {number} data.Status 状态 0:正常 1:锁定
     * @returns {*}
     */
    Add: (data) => {
      data = Object.assign({
        Delivery_ID: null,
        Delivery_Name: null,
        Delivery_Type: null,
        Setting: null,
        Remark: null,
        Status: null
      }, data)
      return APIService.RequestPost('/biz/channeldelivery-manage/add', data)
    },
    /**
     * 投放数据列表
     * @param {Object} data
     * @param {boolean} [data.Cache=false] 是否启用缓存
     * @param {number} data.P_Start 分页起始 0
     * @param {number} data.P_Length 分页长度
     * @param {boolean} [data.P_Get=false] 是否获取分页数据
     * @param {string} [data.P_MaxCode=''] 数据最大值
     * @param {string} [data.Sort_Dir='DESC'] 排序
     * @param {string} [data.Sort_Col] 排序字段
     * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
     * @param {string} [data.F_SValue] 过滤具体内容
     * @param {string} [data.F_DateBegin] 开始时间
     * @param {string} [data.F_DateEnd] 结束时间
     * @param {string} [data.F_DeliveryType] 投放渠道 1:网页链接 2:小程序 3:公众号 4:企业微信名片
     * @returns {*}
     */
    Data_List: (data) => {
      data = Object.assign({
        Cache: false,
        P_Start: 0,
        P_Length: 20,
        P_Get: true,
        P_MaxCode: null,
        Sort_Dir: null,
        Sort_Col: null,
        F_Status: null,
        F_SType: null,
        F_SValue: null,
        F_DateBegin: null,
        F_DateEnd: null,
        F_DeliveryType: null
      }, data)
      return APIService.RequestPost('/biz/channeldelivery-manage/data-list', data)
    },
    /**
     * 投放数据详情列表
     * @param {Object} data
     * @param {boolean} [data.Cache=false] 是否启用缓存
     * @param {number} data.P_Start 分页起始 0
     * @param {number} data.P_Length 分页长度
     * @param {boolean} [data.P_Get=false] 是否获取分页数据
     * @param {string} [data.P_MaxCode=''] 数据最大值
     * @param {string} [data.Sort_Dir='DESC'] 排序
     * @param {string} [data.Sort_Col] 排序字段
     * @param {string} [data.F_SType] 过滤条件 空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识
     * @param {string} [data.F_SValue] 过滤具体内容
     * @param {string} [data.F_DateBegin] 开始时间
     * @param {string} [data.F_DateEnd] 结束时间
     * @param {number} [data.F_Delivery] 投放ID
     * @returns {*}
     */
    Data_Info_List: (data) => {
      data = Object.assign({
        Cache: false,
        P_Start: 0,
        P_Length: 20,
        P_Get: true,
        P_MaxCode: null,
        Sort_Dir: null,
        Sort_Col: null,
        F_Status: null,
        F_SType: null,
        F_SValue: null,
        F_DateBegin: null,
        F_DateEnd: null,
        F_Delivery: null
      }, data)
      return APIService.RequestPost('/biz/channeldelivery-manage/data-info-list', data)
    },
    /**
     * 投放数据详情
     * @param {number} id 投放ID
     * @returns {*}
     */
    Data_Info: (id) => APIService.RequestGet(`/biz/channeldelivery-manage/data-info/${id}`),
  },
  // endregion
  Report: {
    Dashbord: () => {
      return APIService.RequestGet(`/biz/dashbord`)
    }
  },
  VideoData: {
    FilmvideoManage: {
      ArtList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/filmvideo-manage/art-list', data)
      },
      MovieList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/filmvideo-manage/movie-list', data)
      },
      TvList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/filmvideo-manage/tv-list', data)
      },
    },
    BillboardManage: {
      HotList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/billboard-manage/hot-list', data)
      },
      MusicList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/billboard-manage/music-list', data)
      },
      TopicList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/billboard-manage/topic-list', data)
      },
      StarhotList: (data) => {
        data = Object.assign({
          P_Start: 0,
          P_Length: 20,
          P_Get: true,
          P_MaxCode: null,
          Sort_Dir: null,
          Sort_Col: null,
          F_DateBegin: null,
          F_DateEnd: null,
          F_Hot_List_Type: 1
        }, data)
        return APIService.RequestPost('/biz/douyindata-manage/billboard-manage/starhot-list', data)
      },
    }
  },
  Custom: {
    C1001: {
      Region_List: () => APIService.RequestGet(`/biz/qwom/region-list`),
      Building_List: (data) => {
        data = Object.assign({
          City_Code: null   // 围栏编码 [String] [必传:否]
        }, data)
        return APIService.RequestPost(`/biz/qwom/building-list`, data)
      },
      // region 通知中心
      NotificationManage: {
        // 小程序订阅消息模板列表
        WeAPPSubscribeTmp_List: () => APIService.RequestGet(`/biz/qwom/notification-manage/weappsubscribetmp-list`),
        // 消息列表
        Message_List: (data) => {
          data = Object.assign({
            P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
            P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
            P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
            P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
            Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
            Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
            F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
            F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
            F_MessageType: null,                  // 类型过滤 [String] [必传:否]
            F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
            F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
            F_Status: null                        // 状态 [Number] [必传:否] [0 正常 1 锁定]
          }, data)
          return APIService.RequestPost('/biz/qwom/notification-manage/message-list', data)
        },
        // 消息详情
        Message_Info: (id) => APIService.RequestGet(`/biz/qwom/notification-manage/message-info/${id}`),
        /**
         * 消息添加
         * @param {Object} data
         * @param {number} [data.Message_ID] 消息ID
         * @param {string} data.Message_Name 消息名称
         * @param {string} data.Message_Type 消息类型 ['SMS':短信]['APPPush':APP推送]['WeAPPSubscribeMessage':小程序订阅消息]
         * @param {string} [data.Content] SMS必传。短信内容
         * @param {boolean} [data.ClickAction] APPPush必传。可否点击
         * @param {string} [data.TemplateCode] APPPush必传。模板编号
         * @param {string} [data.TitleParam] APPPush推送标题 无
         * @param {string} [data.TextParam] APPPush推送内容
         * @param {string} [data.Template_ID] WeAPPSubscribeMessage必传。订阅模板ID
         * @param {JSON} [data.Template_Data] WeAPPSubscribeMessage必传。订阅模板内容
         * @param {number} [data.Target_Type] 跳转类型 [1:不跳转][2:跳转小程序]
         * @param {string} [data.Page] 跳转小程序路径
         * @param {string} [data.Status] 状态 [0:正常][1:锁定]
         * @returns {*}
         */
        Message_Add: (data) => {
          data = Object.assign({
            Message_ID: null,
            Message_Name: null,
            Message_Type: null,
            Content: null,
            ClickAction: null,
            TemplateCode: null,
            TextParam: null,
            Template_ID: null,
            Template_Data: null,
            Target_Type: null,
            Page: null,
            Status: null
          }, data)
          return APIService.RequestPost('/biz/qwom/notification-manage/message-add', data)
        },
      },
      // endregion
      CustomerManage: {
        Tag: {
          List: (data) => {
            data = Object.assign({
              P_Start: 0,
              P_Length: 20,
              P_Get: true,
              P_MaxCode: null,
              Sort_Dir: null,
              Sort_Col: null,
              F_Date_Begin: null,
              F_Date_End: null,
              F_SType: null,
              F_Status: null,
              F_FuzzyQuery: null
            }, data)
            return APIService.RequestPost('/biz/qwom/tag-manage/list', data)
          },
          Info: (id) => {
            return APIService.RequestPost(`/biz/qwom/tag-manage/info/${id}`)
          },
          Add: (data) => {
            // 信息添加
            data = Object.assign({
              Tag_ID: null,
              Tag_Name: null,
              ES_Condition: null,
              Description: null,
              Status: null
            }, data)
            return APIService.RequestPost('/biz/qwom/tag-manage/add', data)
          }
        },
        User: {
          List: () => {
            return APIService.RequestGet('/biz/Custom/1001/CustomerManage/User/List')
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1001/CustomerManage/User/Info/${id}`)
          }
        },
        getMap: (data) => APIService.RequestPost(`/biz/C1001WeChatofficialac/countList`, data)
      },
      Position: {
        List: (data) => {
          return APIService.RequestPost(`/biz/Custom/1001/Position/List`, data)
        },
        TreeList: () => {
          return APIService.RequestGet(`/biz/Custom/1001/Position/TreeList`)
        },
        Info: (id) => {
          return APIService.RequestGet(`/biz/Custom/1001/Position/Info/${id}`)
        },
      },
      MarketingTools: {
        ActivityManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_Category: null,                     // 类型 [String] [必传:是] [SecKill:秒杀 Register:新人注册]
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_IsWeApp: null,                      // 展示渠道
              F_StatusDate: null,                   // 活动状态 [Number] [必传:否] [3:已结束 1:未开始 2:进行中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
              F_ShareMethod: null                   // 领券配置
            }, data)
            return APIService.RequestPost('/biz/qwom/marketing-manage/activity-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/qwom/marketing-manage/activity-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Activity_ID: null,                 // 活动ID [Number] [必传:修改必传 其他否]
              Activity_Name: null,               // 活动名称 [String] [必传:是]
              Category: null,                    // 类型 [String] [必传:是] [SecKill:秒杀 Register:新人注册 CouponDaily：日常发券]
              Limit_Num_Order: null,             // 限制总量 0不限制
              Limit_Num_Buy_Max: null,           // 单人单店最大购买量，默认1，0不限制
              Rules: null,                       // 活动规则 [String] [必传:是]
              Intro: null,                       // 活动详情 [String] [必传:否]
              Price: null,                       // 活动价格 [Number] [必传:否]
              Price_Market: null,                // 时长价格 [Number] [必传:否]
              Date_Begin: null,                  // 开始时间 [Date] [必传:是]
              Date_End: null,                    // 结束时间 [Date] [必传:是]
              IsWeApp: null,                     // 展示渠道 [Number] [必传:否] [0:端外售卖 1:端内展示]
              Background_Pic: null,              // H5背景图片 [String] [必传:是]
              Share_Desc: null,                  // 分享描述 [String] [必传:否]
              Share_Text: null,                  // 分享内容 [String] [必传:否]
              Share_WeAPP_Path: null,            // 分享小程序路径 [String] [必传:否]
              List_Order: null,                  // 排序 [Number] [必传:否]
              Status: null,                      // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Share_Method: null,                // 领券配置 [Number] [必传:否] [0:直接领券 1:分享朋友圈领券]
              Area: null,                        // 活动区域 [Array] [必传:否] [{Flag:Delete(修改的时候删除),City_Code:城市Code,City:城市，Building_No:综合体编号,Building:综合体}]
              Item: null,                        // 活动商品 [Array] [必传:秒杀、新人注册是 其他否] [{Flag:Delete(修改的时候删除),Item_ID:商品ID,Item_Name:商品名称,Item_Type:类型(0-现金券，1-折扣券),Num:商品数量,Price:价格}]
              SecKill: null,                     // 秒杀阶段 [Array] [必传:秒杀是 其他否] [{Stage_ID:阶段ID,Num:数量,Price:秒杀价格,Status:状态 0正常 1锁定}]
              Num_Group: null,                   // 团购人数
              Num_Android: null,                 // 团购机器人数
              Num_Order_History: null,           // 配置数量
              Expire: null,                      // 团购过期时间（分钟）
              Main_Pic: null,                    // 主图临时文件名 [String]
              Banner_Pic: null,                  // banner临时文件名 [Array] [临时文件名数组]
              Share_Pic: null,                   // 分享图临时文件名 [String]
            }, data)
            return APIService.RequestPost('/biz/qwom/marketing-manage/activity-manage/add', data)
          }
        },
        OrderManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_OrderNo: null,                      // 订单号ID [Number] [必传:否]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_Category: null,                     // 类型 [String] [必传:是] [SecKill:秒杀 Group：拼团，Register:新人注册]
              F_Phone: null,                        // 手机号
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/qwom/marketing-manage/order-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/qwom/marketing-manage/order-manage/info/${id}`)
          },
          RefundCouponMoney: (data) => {
            data = Object.assign({
              Order_No: null,       // 订单号
              Refund_Type: false    // 1:门店撤场退款，2：普通退款
            }, data)
            return APIService.RequestPost(`/biz/qwom/marketing-manage/order-manage/refundcouponmoney`, data)
          },
          Refund: (data) => {
            data = Object.assign({
              Order_No: null,       // 订单号
              Refund_Type: false,   // 1:门店撤场退款，2：普通退款
              Refund_Amt: null      // 退款金额
            }, data)
            return APIService.RequestPost(`/biz/qwom/marketing-manage/order-manage/refund`, data)
          }
        },
        Coupon: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_CouponID: null,                     // 优惠券ID [Number] [必传:否]
              F_CouponType: null,                   // 优惠券类型 [String] [必传:是] [0:现金券 1:折扣券]
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusDate: null,                   // 优惠券状态 [Number] [必传:否] [3:已过期 1:待投放 2:投放中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/qwom/marketing-manage/coupon-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/qwom/marketing-manage/coupon-manage/info/${id}`)
          },
          /**
           * 优惠券添加编辑
           * @param {Object} data
           * @param {number} [data.Coupon_ID] 优惠券ID
           * @param {string} data.Coupon_Name 优惠券名称
           * @param {number} data.Coupon_Type 优惠券类型 [0:现金券|1:折扣券]
           * @param {number} data.Coupon_Cash 现金券金额/折扣券折扣
           * @param {number} data.Confine_Type 门槛限制 [0:无门槛|1:最低限额(满多少可使用)]
           * @param {number} [data.Price_Lower] 最低限额
           * @param {number} [data.Price_Upper] 最高优惠金额
           * @param {number} [data.Expire_Day] 过期天数
           * @param {string} [data.Rules] 优惠券描述
           * @param {number} [data.Status] 状态 [0:正常|1:锁定]
           * @returns {*}
           */
          Add: (data) => {
            data = Object.assign({
              Coupon_ID: null,
              Coupon_Name: null,
              Coupon_Type: null,
              Coupon_Cash: null,
              Confine_Type: null,
              Price_Lower: null,
              Price_Upper: null,
              Expire_Day: null,
              Status: null,
              Rules: null
            }, data)
            return APIService.RequestPost('/biz/qwom/marketing-manage/coupon-manage/add', data)
          }
        },
        CouponDaily: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Coupon/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Coupon/Info/${id}`)
          }
        },
        Register: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Register/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Register/Info/${id}`)
          }
        },
        Group: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Group/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Group/Info/${id}`)
          }
        },
        GroupOrder: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/GroupOrder/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/GroupOrder/Info/${id}`)
          }
        },
        Fission: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_Category: null,                     // 类型 [String] [必传:否] [dicing:掷骰子 turntable:大转盘 tiger:老虎机 grasp:抓娃娃]
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusDate: null,                   // 优惠券状态 [Number] [必传:否] [3:已过期 1:待投放 2:投放中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/qwom/marketing-manage/fission-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/qwom/marketing-manage/fission-manage/info/${id}`)
          },
          Add: (data) => {
            data = Object.assign({
              Activity_ID: null,                 // 活动ID [Number] [必传:修改必传 其他否]
              Activity_Name: null,               // 活动名称 [String] [必传:是]
              Category: null,                    // 类型 [String] [必传:是] [dicing:掷骰子 turntable:大转盘 tiger:老虎机 grasp:抓娃娃]
              Limit_Num_Order: null,             // 限制总量 0不限制
              Limit_Num_Fission: null,           // 裂变抽奖人数限制
              Expire_Day: null,                  // 订单分享有效天数
              Date_Begin: null,                  // 开始时间 [Date] [必传:是]
              Date_End: null,                    // 结束时间 [Date] [必传:是]
              Share_Text: null,                  // 分享内容 [String] [必传:否]
              Share_WeAPP_Path: null,            // 分享小程序路径 [String] [必传:否]
              Status: null,                      // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Item: null,                        // 活动商品 [Array] [必传:是] [{Flag:Delete(修改的时候删除),Item_ID:商品ID,Item_Name:商品名称,Item_Type:类型(0-现金券，1-折扣券),Num:商品数量,Price:价格,IsNewUser:新老用户,Rate:概率}]
              Share_Pic: null,                   // 分享图临时文件名 [String]
            }, data)
            return APIService.RequestPost('/biz/qwom/marketing-manage/fission-manage/add', data)
          }
        },
        Invite: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusDate: null,                   // 优惠券状态 [Number] [必传:否] [3:已过期 1:待投放 2:投放中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/qwom/marketing-manage/invite-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/qwom/marketing-manage/invite-manage/info/${id}`)
          },
          Add: (data) => {
            data = Object.assign({
              Activity_ID: null,                 // 活动ID [Number] [必传:修改必传 其他否]
              Activity_Name: null,               // 活动名称 [String] [必传:是]
              Date_Begin: null,                  // 开始时间 [Date] [必传:是]
              Date_End: null,                    // 结束时间 [Date] [必传:是]
              Share_Text: null,                  // 分享内容 [String] [必传:否]
              Share_WeAPP_Path: null,            // 分享小程序路径 [String] [必传:否]
              Status: null,                      // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Area: null,                        // 活动区域 [Array] [必传:秒杀是 其他否] [{Flag:Delete(修改的时候删除),City_Code:城市Code,City:城市，Building_No:综合体编号,Building:综合体}]
              Item: null,                        // 活动商品 [Array] [必传:是] [{Flag:Delete(修改的时候删除),Item_ID:商品ID,Item_Name:商品名称,Item_Type:类型(0-现金券，1-折扣券),Num:商品数量,Price:价格,IsNewUser:新老用户,Rate:概率}]
              Share_Pic: null,                   // 分享图临时文件名 [String]
            }, data)
            return APIService.RequestPost('/biz/qwom/marketing-manage/invite-manage/add', data)
          }
        }
      },
      TackManage: {
        BaseManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_TackID: null,                       // 策略ID [Number] [必传:否]
              F_TackName: null,                     // 策略名称 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
              F_PositionID: null                    // 触点ID [Number] [必传:否]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/base-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/qwom/tack-manage/base-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 活动ID [Number] [必传:修改必传 其他否]
              Tack_Name: null,                      // 活动名称 [String] [必传:是]
              Date_Begin: null,                     // 开始时间 [Date] [必传:是]
              Date_End: null,                       // 结束时间 [Date] [必传:是]
              Client_Type: null,                    // 客户端类型 [String] [必传:是]  [WechatMini:微信小程序 AndroidAPP IOSAPP]
              Position_ID: null,                    // 策略位置ID [Number] [必传:是]
              Tag_ID: null,                         // 标签ID [Number] [必传:是]
              Priority: null,                       // 优先级 [Number] [必传:否] [默认为1 数字越小优先级越高]
              Type: null,                           // 形式 [String] [必传:是] [Open:弹窗 Image:图片 Text:文字]
              Test_Type: null,                      // 触点资源模式 [Number] [必传:是] [0:A/B测试 1:空]
              City_Code: null,                      // 围栏 [Array] [必传:否]
              Condition: null,                      // 条件 [Array] [必传:否]
              Notification_Message: null,           // 消息 [Array] [必传:否] [{Message_ID:1,Type:(1:按时间间隔),Value:{Type:'Hour',Value:1}},{Message_ID:2,Type:(1:按时间间隔),Value:{Type:'Day',Value:1}},{Message_ID:3,Type:(2:具体时间),Value:'2022-06-29 09:00:00'},{Message_ID:4,Type:(3:即时触达)}]
              Setting: null,                        // 配置 [Array] [必传:否]
              Description: null,                    // 描述 [Number] [必传:是]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
              MaterialGroup: null,                  // 策略资源素材 [Array] [必传:是] [{ Flag: 'Delete'(修改的时候删除), Group_ID:ID}]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/base-manage/add', data)
          }
        },
        MaterialGroupManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_GroupID: null,                      // 资源ID [Number] [必传:否]
              F_GroupName: null,                    // 资源名称 [String] [必传:否]
              F_Type: null,                         // 形式 [String] [必传:否] [Open:弹窗 Image:图片 Text:文字]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/materialgroup-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/qwom/tack-manage/materialgroup-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Group_ID: null,                       // 资源ID [Number] [必传:修改必传 其他否]
              Group_Name: null,                     // 资源名称 [String] [必传:是]
              Type: null,                           // 形式 [String] [必传:是] [Open:弹窗 Image:图片 Text:文字]
              Material: null,                       // 素材 [Array] [必传:是]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            // 套餐  [{"packageId": 套餐ID, "materialId": 素材ID}]
            // 图片  [{"imageUrl": 图片URL, "materialId": 素材ID, "target": 打开链接}]
            // 文字  [{"materialId": 0, "text": "","target": "", "target": 打开链接}]
            // 弹窗  {"priority": 1, "width": 500,"background": {},"header": {},"content": {},"bottom": {},"closeButton": {}}
            return APIService.RequestPost('/biz/qwom/tack-manage/materialgroup-manage/add', data)
          }
        },
        MaterialManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_MaterialID: null,                   // 素材ID [Number] [必传:否]
              F_MaterialName: null,                 // 素材名称 [String] [必传:否]
              F_Type: null,                         // 素材类型 [String] [必传:否] [Image:图片 Video:视频]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/material-manage/list', data)
          },
          Add: (data) => {
            data = Object.assign({
              Material_Name: null,                  // 素材名称 [String] [必传:否]
              Type: null,                           // 素材类型 [String] [必传:是] [Image:图片 Text:文本 Package:套餐]
              Text: null,                           // 文本内容 [String] [必传:否] [Image:图片的扩展名 Text:填文本 Package:填套餐ID]
              Material_Pic: null                    // 素材临时文件名 [String] [必传:否]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/material-manage/add', data)
          }
        },
        GroupManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_GroupID: null,                      // 策略组ID [Number] [必传:否]
              F_GroupName: null,                    // 策略组名称 [String] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/group-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/qwom/tack-manage/group-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Group_ID: null,                       // 策略组ID [Number] [必传:修改必传 其他否]
              Group_Name: null,                     // 策略组名称 [String] [必传:是]
              Remark: null,                         // 备注 [Number] [必传:否]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Tack: null                            // 策略 [Array] [必传:是] [{ Flag: 'Delete'(修改的时候删除), Tack_ID:策略ID}]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/group-manage/add', data)
          }
        },
        DataManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_TackID: null,                       // 策略ID [Number] [必传:否]
              F_TackName: null,                     // 策略名称 [String] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/data-manage/list', data)
          },
          Info: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 策略ID [Number] [必传:是]
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/data-manage/info', data)
          },
          Info_Data: (data) => {
            data = Object.assign({
              Tack_ID: null,   // // 策略ID [Number] [必传:是]
              Date_Begin: null,
              Date_End: null,
              Test_Type: null,
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/data-manage/info-data', data)
          },
          Report: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 策略ID [Number] [必传:是]
              Test_Type: null,                      // 资源类型 [String] [必传:否] [A:资源位A B:资源位B '':资源位空白]
              Date_Begin: null,                     // 开始日期 [String] [必传:否]
              Date_End: null,                       // 结束日期 [String] [必传:否]
              Sort: null                            // 排序 [{ ReportDay: type === 'trend' ? 1 : -1 }] ReportDay: 1正序 -1倒序
            }, data)
            return APIService.RequestPost('/biz/qwom/tack-manage/data-manage/report', data)
          }
        },
        SMSManage: {
          // 推送列表
          Push_List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusPush: null,                   // 推送状态 [Number] [必传:否] [0 完成 1 待发送 2 发送中]
              F_Status: null                        // 状态 [Number] [必传:否] [0 正常 1 锁定]
            }, data)
            return APIService.RequestPost('/biz/qwom/sms-manage/push-list', data)
          },
          // 推送详情
          Push_Info: (id) => APIService.RequestGet(`/biz/qwom/sms-manage/push-info/${id}`),
          // 推送添加
          Push_Add: (data) => {
            data = Object.assign({
              Push_ID: null,                      // ID [Number] [必传:修改必传]
              Push_Name: false,                   // 名称 [String] [必传:是]
              Push_Date: null,                    // 推送时间 [Date] [必传:否] [默认:立即推送][定时推送传递定时推送时间]
              Phone: null,                        // 手机号码 [Array] [必传:是]
              File_Name: null,                    // 文件名 [String] [必传:是]
              Content: null,                      // 短信内容 [String] [必传:是]
              Status: null,                       // 状态 [Number] [必传:否] [默认:0] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/qwom/sms-manage/push-add', data)
          },
        }
      }
    },
    C1024: {
      /**
       * 数据看板
       * @returns {*}
       */
      DataBoard: () => APIService.RequestGet(`/biz/liubike/data-board`),
      CustomerManage: {
        Tag: {
          List: (data) => {
            data = Object.assign({
              P_Start: 0,
              P_Length: 20,
              P_Get: true,
              P_MaxCode: null,
              Sort_Dir: null,
              Sort_Col: null,
              F_Date_Begin: null,
              F_Date_End: null,
              F_SType: null,
              F_Status: null,
              F_FuzzyQuery: null
            }, data)
            return APIService.RequestPost('/biz/liubike/tag-manage/list', data)
          },
          Info: (id) => {
            return APIService.RequestPost(`/biz/liubike/tag-manage/info/${id}`)
          },
          Add: (data) => {
            // 信息添加
            data = Object.assign({
              Tag_ID: null,
              Tag_Name: null,
              ES_Condition: null,
              Description: null,
              Status: null
            }, data)
            return APIService.RequestPost('/biz/liubike/tag-manage/add', data)
          }
        },
        User: {
          List: () => {
            return APIService.RequestGet('/biz/Custom/1024/CustomerManage/User/List')
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/CustomerManage/User/Info/${id}`)
          }
        },
        getMap: (data) => APIService.RequestPost(`/biz/C1024WeChatofficialac/countList`, data)
      },
      Position: {
        List: (data) => {
          return APIService.RequestPost(`/biz/Custom/1024/Position/List`, data)
        },
        TreeList: () => {
          return APIService.RequestGet(`/biz/Custom/1024/Position/TreeList`)
        },
        Info: (id) => {
          return APIService.RequestGet(`/biz/Custom/1024/Position/Info/${id}`)
        },
      },
      WechatManage: {
        MP: {
          TmplMsg: {
            List: (data) => {
              return APIService.RequestPost(`/biz/Custom/1024/WechatManage/MP/TmplMsg/List`, data)
            },
            Info: (id) => {
              return APIService.RequestGet(`/biz/Custom/1024/WechatManage/MP/TmplMsg/Info/${id}`)
            }
          }
        }
      },
      getC1024WeChatofficialaccountList: (flag) => APIService.RequestGet(`/biz/C1024WeChatofficialac/countList?${flag}`),
      getC1024accountofficialaccountList: (flag) => APIService.RequestGet(`/biz/C1024accountofficialac/countList?${flag}`),
      getC1024SingleWeChatofficialac: (flag) => APIService.RequestGet(`/biz/C1024SingleWeChat/countList?${flag}`),
      Region_List: () => APIService.RequestGet(`/biz/liubike/region-list`),
      Building_List: (data) => {
        data = Object.assign({
          City_Code: null   // 围栏编码 [String] [必传:否]
        }, data)
        return APIService.RequestPost(`/biz/liubike/building-list`, data)
      },
      TackManage: {
        BaseManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_TackID: null,                       // 策略ID [Number] [必传:否]
              F_TackName: null,                     // 策略名称 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
              F_PositionID: null                    // 触点ID [Number] [必传:否]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/base-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/liubike/tack-manage/base-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 活动ID [Number] [必传:修改必传 其他否]
              Tack_Name: null,                      // 活动名称 [String] [必传:是]
              Date_Begin: null,                     // 开始时间 [Date] [必传:是]
              Date_End: null,                       // 结束时间 [Date] [必传:是]
              Client_Type: null,                    // 客户端类型 [String] [必传:是]  [WechatMini:微信小程序 AndroidAPP IOSAPP]
              Position_ID: null,                    // 策略位置ID [Number] [必传:是]
              Tag_ID: null,                         // 标签ID [Number] [必传:是]
              Priority: null,                       // 优先级 [Number] [必传:否] [默认为1 数字越小优先级越高]
              Type: null,                           // 形式 [String] [必传:是] [Open:弹窗 Image:图片 Text:文字]
              Test_Type: null,                      // 触点资源模式 [Number] [必传:是] [0:A/B测试 1:空]
              City_Code: null,                      // 围栏 [Array] [必传:否]
              Condition: null,                      // 条件 [Array] [必传:否]
              Notification_Message: null,           // 消息 [Array] [必传:否] [{Message_ID:1,Type:(1:按时间间隔),Value:{Type:'Hour',Value:1}},{Message_ID:2,Type:(1:按时间间隔),Value:{Type:'Day',Value:1}},{Message_ID:3,Type:(2:具体时间),Value:'2022-06-29 09:00:00'},{Message_ID:4,Type:(3:即时触达)}]
              Setting: null,                        // 配置 [Array] [必传:否]
              Description: null,                    // 描述 [Number] [必传:是]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
              MaterialGroup: null,                  // 策略资源素材 [Array] [必传:是] [{ Flag: 'Delete'(修改的时候删除), Group_ID:ID}]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/base-manage/add', data)
          }
        },
        MaterialGroupManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_GroupID: null,                      // 资源ID [Number] [必传:否]
              F_GroupName: null,                    // 资源名称 [String] [必传:否]
              F_Type: null,                         // 形式 [String] [必传:否] [Open:弹窗 Image:图片 Text:文字]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/materialgroup-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/liubike/tack-manage/materialgroup-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Group_ID: null,                       // 资源ID [Number] [必传:修改必传 其他否]
              Group_Name: null,                     // 资源名称 [String] [必传:是]
              Type: null,                           // 形式 [String] [必传:是] [Open:弹窗 Image:图片 Text:文字]
              Material: null,                       // 素材 [Array] [必传:是]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            // 套餐  [{"packageId": 套餐ID, "materialId": 素材ID}]
            // 图片  [{"imageUrl": 图片URL, "materialId": 素材ID, "target": 打开链接}]
            // 文字  [{"materialId": 0, "text": "","target": "", "target": 打开链接}]
            // 弹窗  {"priority": 1, "width": 500,"background": {},"header": {},"content": {},"bottom": {},"closeButton": {}}
            return APIService.RequestPost('/biz/liubike/tack-manage/materialgroup-manage/add', data)
          }
        },
        MaterialManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_MaterialID: null,                   // 素材ID [Number] [必传:否]
              F_MaterialName: null,                 // 素材名称 [String] [必传:否]
              F_Type: null,                         // 素材类型 [String] [必传:否] [Image:图片 Video:视频]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/material-manage/list', data)
          },
          Add: (data) => {
            data = Object.assign({
              Material_Name: null,                  // 素材名称 [String] [必传:否]
              Type: null,                           // 素材类型 [String] [必传:是] [Image:图片 Text:文本 Package:套餐]
              Text: null,                           // 文本内容 [String] [必传:否] [Image:图片的扩展名 Text:填文本 Package:填套餐ID]
              Material_Pic: null                    // 素材临时文件名 [String] [必传:否]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/material-manage/add', data)
          }
        },
        GroupManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_GroupID: null,                      // 策略组ID [Number] [必传:否]
              F_GroupName: null,                    // 策略组名称 [String] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/group-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/liubike/tack-manage/group-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Group_ID: null,                       // 策略组ID [Number] [必传:修改必传 其他否]
              Group_Name: null,                     // 策略组名称 [String] [必传:是]
              Remark: null,                         // 备注 [Number] [必传:否]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Tack: null                            // 策略 [Array] [必传:是] [{ Flag: 'Delete'(修改的时候删除), Tack_ID:策略ID}]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/group-manage/add', data)
          }
        },
        DataManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_TackID: null,                       // 策略ID [Number] [必传:否]
              F_TackName: null,                     // 策略名称 [String] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/data-manage/list', data)
          },
          Info: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 策略ID [Number] [必传:是]
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/data-manage/info', data)
          },
          Info_Data: (data) => {
            data = Object.assign({
              Tack_ID: null,   // // 策略ID [Number] [必传:是]
              Date_Begin: null,
              Date_End: null,
              Test_Type: null,
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/data-manage/info-data', data)
          },
          Report: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 策略ID [Number] [必传:是]
              Test_Type: null,                      // 资源类型 [String] [必传:否] [A:资源位A B:资源位B '':资源位空白]
              Date_Begin: null,                     // 开始日期 [String] [必传:否]
              Date_End: null,                       // 结束日期 [String] [必传:否]
              Sort: null                            // 排序 [{ ReportDay: type === 'trend' ? 1 : -1 }] ReportDay: 1正序 -1倒序
            }, data)
            return APIService.RequestPost('/biz/liubike/tack-manage/data-manage/report', data)
          }
        },
        SMSManage: {
          // 推送列表
          Push_List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusPush: null,                   // 推送状态 [Number] [必传:否] [0 完成 1 待发送 2 发送中]
              F_Status: null                        // 状态 [Number] [必传:否] [0 正常 1 锁定]
            }, data)
            return APIService.RequestPost('/biz/liubike/sms-manage/push-list', data)
          },
          // 推送详情
          Push_Info: (id) => APIService.RequestGet(`/biz/liubike/sms-manage/push-info/${id}`),
          // 推送添加
          Push_Add: (data) => {
            data = Object.assign({
              Push_ID: null,                      // ID [Number] [必传:修改必传]
              Push_Name: false,                   // 名称 [String] [必传:是]
              Push_Date: null,                    // 推送时间 [Date] [必传:否] [默认:立即推送][定时推送传递定时推送时间]
              Phone: null,                        // 手机号码 [Array] [必传:是]
              File_Name: null,                    // 文件名 [String] [必传:是]
              Content: null,                      // 短信内容 [String] [必传:是]
              Status: null,                       // 状态 [Number] [必传:否] [默认:0] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/liubike/sms-manage/push-add', data)
          },
        }
      }
    },
    C1025: {
      CustomerManage: {
        Tag: {
          List: (data) => {
            data = Object.assign({
              P_Start: 0,
              P_Length: 20,
              P_Get: true,
              P_MaxCode: null,
              Sort_Dir: null,
              Sort_Col: null,
              F_Date_Begin: null,
              F_Date_End: null,
              F_SType: null,
              F_Status: null,
              F_FuzzyQuery: null
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/tag-manage/list', data)
          },
          Info: (id) => {
            return APIService.RequestPost(`/biz/argxq/tack-manage/tag-manage/info/${id}`)
          },
          Add: (data) => {
            // 信息添加
            data = Object.assign({
              Tag_ID: null,
              Tag_Name: null,
              ES_Condition: null,
              Description: null,
              Status: null
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/tag-manage/add', data)
          }
        }
      },
      Region_List: () => APIService.RequestGet(`/biz/argxq/region-list`),
      Building_List: (data) => {
        data = Object.assign({
          City_Code: null   // 围栏编码 [String] [必传:否]
        }, data)
        return APIService.RequestPost(`/biz/argxq/building-list`, data)
      },
      TackManage: {
        BaseManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_TackID: null,                       // 策略ID [Number] [必传:否]
              F_TackName: null,                     // 策略名称 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/base-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/argxq/tack-manage/base-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 活动ID [Number] [必传:修改必传 其他否]
              Tack_Name: null,                      // 活动名称 [String] [必传:是]
              Date_Begin: null,                     // 开始时间 [Date] [必传:是]
              Date_End: null,                       // 结束时间 [Date] [必传:是]
              Client_Type: null,                    // 客户端类型 [String] [必传:是]  [WechatMini:微信小程序 AndroidAPP IOSAPP]
              Position_ID: null,                    // 策略位置ID [Number] [必传:是]
              Tag_ID: null,                         // 标签ID [Number] [必传:是]
              Priority: null,                       // 优先级 [Number] [必传:否] [默认为1 数字越小优先级越高]
              Type: null,                           // 形式 [String] [必传:是] [Open:弹窗 Image:图片 Text:文字]
              Test_Type: null,                      // 触点资源模式 [Number] [必传:是] [0:A/B测试 1:空]
              City_Code: null,                      // 围栏 [Array] [必传:否]
              Condition: null,                      // 条件 [Array] [必传:否]
              Setting: null,                        // 配置 [Array] [必传:否]
              Description: null,                    // 描述 [Number] [必传:是]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
              MaterialGroup: null,                  // 策略资源素材 [Array] [必传:是] [{ Flag: 'Delete'(修改的时候删除), Group_ID:ID}]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/base-manage/add', data)
          }
        },
        MaterialGroupManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_GroupID: null,                      // 资源ID [Number] [必传:否]
              F_GroupName: null,                    // 资源名称 [String] [必传:否]
              F_Type: null,                         // 形式 [String] [必传:否] [Open:弹窗 Image:图片 Text:文字]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/materialgroup-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/argxq/tack-manage/materialgroup-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Group_ID: null,                       // 资源ID [Number] [必传:修改必传 其他否]
              Group_Name: null,                     // 资源名称 [String] [必传:是]
              Type: null,                           // 形式 [String] [必传:是] [Open:弹窗 Image:图片 Text:文字]
              Material: null,                       // 素材 [Array] [必传:是]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            // 套餐  [{"packageId": 套餐ID, "materialId": 素材ID}]
            // 图片  [{"imageUrl": 图片URL, "materialId": 素材ID, "target": 打开链接}]
            // 文字  [{"materialId": 0, "text": "","target": "", "target": 打开链接}]
            // 弹窗  {"priority": 1, "width": 500,"background": {},"header": {},"content": {},"bottom": {},"closeButton": {}}
            return APIService.RequestPost('/biz/argxq/tack-manage/materialgroup-manage/add', data)
          }
        },
        MaterialManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_MaterialID: null,                   // 素材ID [Number] [必传:否]
              F_MaterialName: null,                 // 素材名称 [String] [必传:否]
              F_Type: null,                         // 素材类型 [String] [必传:否] [Image:图片 Video:视频]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/material-manage/list', data)
          },
          Add: (data) => {
            data = Object.assign({
              Material_Name: null,                  // 素材名称 [String] [必传:否]
              Type: null,                           // 素材类型 [String] [必传:是] [Image:图片 Text:文本 Package:套餐]
              Text: null,                           // 文本内容 [String] [必传:否] [Image:图片的扩展名 Text:填文本 Package:填套餐ID]
              Material_Pic: null                    // 素材临时文件名 [String] [必传:否]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/material-manage/add', data)
          }
        },
        GroupManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_GroupID: null,                      // 策略组ID [Number] [必传:否]
              F_GroupName: null,                    // 策略组名称 [String] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/group-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/argxq/tack-manage/group-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Group_ID: null,                       // 策略组ID [Number] [必传:修改必传 其他否]
              Group_Name: null,                     // 策略组名称 [String] [必传:是]
              Remark: null,                         // 备注 [Number] [必传:否]
              Status: null,                         // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Tack: null                            // 策略 [Array] [必传:是] [{ Flag: 'Delete'(修改的时候删除), Tack_ID:策略ID}]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/group-manage/add', data)
          }
        },
        DataManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_TackID: null,                       // 策略ID [Number] [必传:否]
              F_TackName: null,                     // 策略名称 [String] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/data-manage/list', data)
          },
          Info: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 策略ID [Number] [必传:是]
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/data-manage/info', data)
          },
          Info_Data: (data) => {
            data = Object.assign({
              Tack_ID: null,   // // 策略ID [Number] [必传:是]
              Date_Begin: null,
              Date_End: null,
              Test_Type: null,
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/data-manage/info-data', data)
          },
          Report: (data) => {
            data = Object.assign({
              Tack_ID: null,                        // 策略ID [Number] [必传:是]
              Test_Type: null,                      // 资源类型 [String] [必传:否] [A:资源位A B:资源位B '':资源位空白]
              Date_Begin: null,                     // 开始日期 [String] [必传:否]
              Date_End: null,                       // 结束日期 [String] [必传:否]
              Sort: null                            // 排序 [{ ReportDay: type === 'trend' ? 1 : -1 }] ReportDay: 1正序 -1倒序
            }, data)
            return APIService.RequestPost('/biz/argxq/tack-manage/data-manage/report', data)
          }
        }
      },
      Position: {
        List: (data) => {
          return APIService.RequestPost(`/biz/Custom/1025/Position/List`, data)
        },
        TreeList: () => {
          return APIService.RequestGet(`/biz/Custom/1025/Position/TreeList`)
        },
        Info: (id) => {
          return APIService.RequestGet(`/biz/Custom/1025/Position/Info/${id}`)
        },
      },
      MarketingTools: {
        ActivityManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_Category: null,                     // 类型 [String] [必传:是] [SecKill:秒杀 Register:新人注册]
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_IsWeApp: null,                      // 展示渠道
              F_StatusDate: null,                   // 活动状态 [Number] [必传:否] [3:已结束 1:未开始 2:进行中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
              F_ShareMethod: null                   // 领券配置
            }, data)
            return APIService.RequestPost('/biz/argxq/marketing-manage/activity-manage/list', data)
          },
          Info: (id) => APIService.RequestGet(`/biz/argxq/marketing-manage/activity-manage/info/${id}`),
          Add: (data) => {
            data = Object.assign({
              Activity_ID: null,                 // 活动ID [Number] [必传:修改必传 其他否]
              Activity_Name: null,               // 活动名称 [String] [必传:是]
              Category: null,                    // 类型 [String] [必传:是] [SecKill:秒杀 Register:新人注册 CouponDaily：日常发券]
              Limit_Num_Order: null,             // 限制总量 0不限制
              Limit_Num_Buy_Max: null,           // 单人单店最大购买量，默认1，0不限制
              Rules: null,                       // 活动规则 [String] [必传:是]
              Intro: null,                       // 活动详情 [String] [必传:否]
              Price: null,                       // 活动价格 [Number] [必传:否]
              Price_Market: null,                // 时长价格 [Number] [必传:否]
              Date_Begin: null,                  // 开始时间 [Date] [必传:是]
              Date_End: null,                    // 结束时间 [Date] [必传:是]
              IsWeApp: null,                     // 展示渠道 [Number] [必传:否] [0:端外售卖 1:端内展示]
              Background_Pic: null,              // H5背景图片 [String] [必传:是]
              Share_Desc: null,                  // 分享描述 [String] [必传:否]
              Share_Text: null,                  // 分享内容 [String] [必传:否]
              Share_WeAPP_Path: null,            // 分享小程序路径 [String] [必传:否]
              List_Order: null,                  // 排序 [Number] [必传:否]
              Status: null,                      // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Share_Method: null,                // 领券配置 [Number] [必传:否] [0:直接领券 1:分享朋友圈领券]
              Area: null,                        // 活动区域 [Array] [必传:否] [{Flag:Delete(修改的时候删除),City_Code:城市Code,City:城市，Building_No:综合体编号,Building:综合体}]
              Item: null,                        // 活动商品 [Array] [必传:秒杀、新人注册是 其他否] [{Flag:Delete(修改的时候删除),Item_ID:商品ID,Item_Name:商品名称,Item_Type:类型(0-现金券，1-折扣券),Num:商品数量,Price:价格}]
              SecKill: null,                     // 秒杀阶段 [Array] [必传:秒杀是 其他否] [{Stage_ID:阶段ID,Num:数量,Price:秒杀价格,Status:状态 0正常 1锁定}]
              Num_Group: null,                   // 团购人数
              Num_Android: null,                 // 团购机器人数
              Num_Order_History: null,           // 配置数量
              Expire: null,                      // 团购过期时间（分钟）
              Main_Pic: null,                    // 主图临时文件名 [String]
              Banner_Pic: null,                  // banner临时文件名 [Array] [临时文件名数组]
              Share_Pic: null,                   // 分享图临时文件名 [String]
            }, data)
            return APIService.RequestPost('/biz/argxq/marketing-manage/activity-manage/add', data)
          }
        },
        OrderManage: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_OrderNo: null,                      // 订单号ID [Number] [必传:否]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_Category: null,                     // 类型 [String] [必传:是] [SecKill:秒杀 Group：拼团，Register:新人注册]
              F_Phone: null,                        // 手机号
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/argxq/marketing-manage/order-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/argxq/marketing-manage/order-manage/info/${id}`)
          },
          RefundCouponMoney: (data) => {
            data = Object.assign({
              Order_No: null,       // 订单号
              Refund_Type: false    // 1:门店撤场退款，2：普通退款
            }, data)
            return APIService.RequestPost(`/biz/argxq/marketing-manage/order-manage/refundcouponmoney`, data)
          },
          Refund: (data) => {
            data = Object.assign({
              Order_No: null,       // 订单号
              Refund_Type: false,   // 1:门店撤场退款，2：普通退款
              Refund_Amt: null      // 退款金额
            }, data)
            return APIService.RequestPost(`/biz/argxq/marketing-manage/order-manage/refund`, data)
          }
        },
        Coupon: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_CouponID: null,                     // 优惠券ID [Number] [必传:否]
              F_CouponType: null,                   // 优惠券类型 [String] [必传:是] [0:现金券 1:折扣券]
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusDate: null,                   // 优惠券状态 [Number] [必传:否] [3:已过期 1:待投放 2:投放中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/argxq/marketing-manage/coupon-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/argxq/marketing-manage/coupon-manage/info/${id}`)
          },
          /**
           * 优惠券添加编辑
           * @param {Object} data
           * @param {number} [data.Coupon_ID] 优惠券ID
           * @param {string} data.Coupon_Name 优惠券名称
           * @param {number} data.Coupon_Type 优惠券类型 [0:现金券|1:折扣券]
           * @param {number} data.Coupon_Cash 现金券金额/折扣券折扣
           * @param {number} data.Confine_Type 门槛限制 [0:无门槛|1:最低限额(满多少可使用)]
           * @param {number} [data.Price_Lower] 最低限额
           * @param {number} [data.Price_Upper] 最高优惠金额
           * @param {number} [data.Expire_Day] 过期天数
           * @param {string} [data.Rules] 优惠券描述
           * @param {number} [data.Status] 状态 [0:正常|1:锁定]
           * @returns {*}
           */
          Add: (data) => {
            data = Object.assign({
              Coupon_ID: null,
              Coupon_Name: null,
              Coupon_Type: null,
              Coupon_Cash: null,
              Confine_Type: null,
              Price_Lower: null,
              Price_Upper: null,
              Expire_Day: null,
              Status: null,
              Rules: null
            }, data)
            return APIService.RequestPost('/biz/argxq/marketing-manage/coupon-manage/add', data)
          }
        },
        CouponDaily: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Coupon/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Coupon/Info/${id}`)
          }
        },
        Register: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Register/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Register/Info/${id}`)
          }
        },
        Group: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Group/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/Group/Info/${id}`)
          }
        },
        GroupOrder: {
          List: () => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/GroupOrder/List`)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/Custom/1024/MarketingTools/GroupOrder/Info/${id}`)
          }
        },
        Fission: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_Category: null,                     // 类型 [String] [必传:否] [dicing:掷骰子 turntable:大转盘 tiger:老虎机 grasp:抓娃娃]
              F_CityCode: null,                     // 围栏编码 [String] [必传:否]
              F_BuildingNo: null,                   // 综合体编码 [String] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusDate: null,                   // 优惠券状态 [Number] [必传:否] [3:已过期 1:待投放 2:投放中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/argxq/marketing-manage/fission-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/argxq/marketing-manage/fission-manage/info/${id}`)
          },
          Add: (data) => {
            data = Object.assign({
              Activity_ID: null,                 // 活动ID [Number] [必传:修改必传 其他否]
              Activity_Name: null,               // 活动名称 [String] [必传:是]
              Category: null,                    // 类型 [String] [必传:是] [dicing:掷骰子 turntable:大转盘 tiger:老虎机 grasp:抓娃娃]
              Limit_Num_Order: null,             // 限制总量 0不限制
              Limit_Num_Fission: null,           // 裂变抽奖人数限制
              Expire_Day: null,                  // 订单分享有效天数
              Date_Begin: null,                  // 开始时间 [Date] [必传:是]
              Date_End: null,                    // 结束时间 [Date] [必传:是]
              Share_Text: null,                  // 分享内容 [String] [必传:否]
              Share_WeAPP_Path: null,            // 分享小程序路径 [String] [必传:否]
              Status: null,                      // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Item: null,                        // 活动商品 [Array] [必传:是] [{Flag:Delete(修改的时候删除),Item_ID:商品ID,Item_Name:商品名称,Item_Type:类型(0-现金券，1-折扣券),Num:商品数量,Price:价格,IsNewUser:新老用户,Rate:概率}]
              Share_Pic: null,                   // 分享图临时文件名 [String]
            }, data)
            return APIService.RequestPost('/biz/argxq/marketing-manage/fission-manage/add', data)
          }
        },
        Invite: {
          List: (data) => {
            data = Object.assign({
              P_MaxCode: null,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
              P_Get: false,                         // 是否获取分页信息 [Bool] [必传:否] [默认:false]
              P_Start: null,                        // 分页起始 [Number] [必传:是] [0 开始不传无数据]
              P_Length: null,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
              Sort_Col: null,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
              Sort_Dir: null,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
              F_SType: null,                        // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
              F_SValue: null,                       // 查询内容 [String] [必传:否] [查询具体内容]
              F_ActivityID: null,                   // 活动ID [Number] [必传:否]
              F_DateBegin: null,                    // 开始时间 [Date] [必传:否]
              F_DateEnd: null,                      // 结束时间 [Date] [必传:否]
              F_StatusDate: null,                   // 优惠券状态 [Number] [必传:否] [3:已过期 1:待投放 2:投放中]
              F_Status: null,                       // 状态 [Number] [必传:否] [0:正常 1:锁定]
            }, data)
            return APIService.RequestPost(`/biz/argxq/marketing-manage/invite-manage/list`, data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/argxq/marketing-manage/invite-manage/info/${id}`)
          },
          Add: (data) => {
            data = Object.assign({
              Activity_ID: null,                 // 活动ID [Number] [必传:修改必传 其他否]
              Activity_Name: null,               // 活动名称 [String] [必传:是]
              Date_Begin: null,                  // 开始时间 [Date] [必传:是]
              Date_End: null,                    // 结束时间 [Date] [必传:是]
              Share_Text: null,                  // 分享内容 [String] [必传:否]
              Share_WeAPP_Path: null,            // 分享小程序路径 [String] [必传:否]
              Status: null,                      // 状态 [Number] [必传:否] [0:正常 1:锁定]
              Area: null,                        // 活动区域 [Array] [必传:秒杀是 其他否] [{Flag:Delete(修改的时候删除),City_Code:城市Code,City:城市，Building_No:综合体编号,Building:综合体}]
              Item: null,                        // 活动商品 [Array] [必传:是] [{Flag:Delete(修改的时候删除),Item_ID:商品ID,Item_Name:商品名称,Item_Type:类型(0-现金券，1-折扣券),Num:商品数量,Price:价格,IsNewUser:新老用户,Rate:概率}]
              Share_Pic: null,                   // 分享图临时文件名 [String]
            }, data)
            return APIService.RequestPost('/biz/argxq/marketing-manage/invite-manage/add', data)
          }
        }
      }
    },
    C1026: {
      ToC: {
        BannerManage: {
          List: (data) => {
            data = Object.assign({
              P_Start: 0,
              P_Length: 20,
              P_Get: true,
              P_MaxCode: null,
              Sort_Dir: null,
              Sort_Col: null,
              F_Status: null,
              F_Date_Begin: null,
              F_Date_End: null,
              F_FuzzyQuery: null,
              F_SType: null,
              F_SValue: null
            }, data)
            return APIService.RequestPost('/biz/danianhuawan/dayouqiu/toc/banner-manage/list', data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/danianhuawan/dayouqiu/toc/banner-manage/info/${id}`)
          },
          Add: (data) => {
            data = Object.assign({
              Banner_ID: null,
              Banner_Name: null,
              Banner_Type: null,
              Setting: null,
              Banner_Pic: null,
              List_Order: null,
              Status: null
            }, data)
            return APIService.RequestPost('/biz/danianhuawan/dayouqiu/toc/banner-manage/add', data)
          }
        },
        ActivityManage: {
          Info: () => {
            return APIService.RequestGet('/biz/danianhuawan/dayouqiu/toc/activity-manage/info')
          },
          Add: (data) => {
            data = Object.assign({
              Activity_Share_URL: null,         // 活动分享链接
              Share_Text: null,                 // 活动分享文案
              Activity_Share_ImageURL: null,    // 活动分享图片URL
              Activity_Share_ImageURL_Pic: null // 上传图片得到的Code
            }, data)
            return APIService.RequestPost('/biz/danianhuawan/dayouqiu/toc/activity-manage/add', data)
          }
        },
        NewsManage: {
          List: (data) => {
            data = Object.assign({
              P_Start: 0,
              P_Length: 20,
              P_Get: true,
              P_MaxCode: null,
              Sort_Dir: null,
              Sort_Col: null,
              F_Status: null,
              F_Date_Begin: null,
              F_Date_End: null,
              F_FuzzyQuery: null,
              F_SType: null,
              F_SValue: null
            }, data)
            return APIService.RequestPost('/biz/danianhuawan/dayouqiu/toc/news-manage/list', data)
          },
          Info: (id) => {
            return APIService.RequestGet(`/biz/danianhuawan/dayouqiu/toc/news-manage/info/${id}`)
          },
          Add: (data) => {
            data = Object.assign({
              News_ID: null,
              News_Name: null,
              News_Content: null,
              Tag_Name: null,
              News_Pic: null,
              News_URL: null,
              Pub_Date: null,
              List_Order: null,
              Status: null
            }, data)
            return APIService.RequestPost('/biz/danianhuawan/dayouqiu/toc/news-manage/add', data)
          }
        }
      }
    }
  }
}
