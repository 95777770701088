'use strict'

export default [
// 营销工具
    {
        path: 'marketing-tools',
        name: 'MarketingTools',
        meta: {Title: '营销工具', Auth: '{Platform}[Marketing]'},
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
            {
                path: 'coupon',
                redirect: 'coupon/list',
                name: 'MarketingTools.Coupon',
                meta: {
                  Title: '优惠券'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
                children: [
                    {
                        path: 'list',
                        name: 'MarketingTools.Coupon.List',
                        meta: {Title: '优惠券列表'},
                        // meta: {Title: '优惠券', Auth: '{Platform}[MarketingTools](Quan)'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/coupon/list.vue')
                    },
                    {
                        path: 'add/:id/:type?',
                        name: 'MarketingTools.Coupon.Add',
                        meta: {Title: '优惠券添加'},
                        // meta: {Title: '优惠券', Auth: '{Platform}[MarketingTools](Quan)'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/coupon/add.vue')
                    },
                ]
              },
            {
                path: 'task-manage',
                redirect: 'task-manage/list',
                name: 'MarketingTools.TaskManage',
                meta: {
                    Title: '任务管理'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
                children: [
                    // 内容标签
                    {
                        path: 'list',
                        name: 'MarketingTools.TaskManage.List',
                        meta: {Title: '任务管理列表'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/task-manage/list.vue')
                    },
                    {
                        path: 'add/:id',
                        name: 'MarketingTools.TaskManage.Add',
                        meta: {Title: '任务管理编辑'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/task-manage/add.vue')
                    },
                ]
            },


            {
                path: 'activities/list',
                name: 'MarketingTools.Activities.List',
                meta: {Title: '营销活动', Auth: '{Platform}[MarketingTools](Manage)'},
                component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/activities/list.vue')
            },

            // {
            //     path: 'marketing-examine',
            //     name: 'MarketingTools.Examine',
            //     meta: {Title: '砍价', Auth: '{Platform}[MarketingTools](Examine)'},
            //     component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/examine/list.vue')
            // },
            {
                path: 'marketing-distribute',
                redirect: 'marketing-distribute/list',
                name: 'MarketingTools.Distribute',
                meta: {
                    Title: '分阶秒杀'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
                children: [
                    // 内容标签
                    {
                        path: 'list',
                        name: 'MarketingTools.DistributeList',
                        meta: {Title: '秒杀活动列表'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/distribute/list.vue')
                    },
                    {
                        path: 'add/:id',
                        name: 'MarketingTools.DistributeAdd',
                        meta: {Title: '秒杀活动信息'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/distribute/add.vue')
                    },
                ]
            },
            {
                path: 'marketing-group',
                redirect: 'marketing-group/list',
                name: 'MarketingTools.Group',
                meta: {
                    Title: '限时拼团'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
                children: [
                    // 内容标签
                    {
                        path: 'list',
                        name: 'MarketingTools.GroupList',
                        meta: {Title: '拼团活动列表'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/group/list.vue')
                    },
                    {
                        path: 'add/:id',
                        name: 'MarketingTools.Groupadd',
                        meta: {Title: '拼团活动信息'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/group/add.vue')
                    },
                ]
            },
            {
                path: 'fission-lottery',
                redirect: 'fission-lottery/list',
                name: 'MarketingTools.FissionLottery',
                meta: {
                    Title: '裂变抽奖'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
                children: [
                    // 内容标签
                    {
                        path: 'list',
                        name: 'MarketingTools.FissionLottery.List',
                        meta: {Title: '裂变抽奖列表'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/fission-lottery/list.vue')
                    },
                    // {
                    //     path: 'add/:id',
                    //     name: 'MarketingTools.Groupadd',
                    //     meta: {Title: '裂变抽奖信息'},
                    //     component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/group/add.vue')
                    // },
                ]
            },
            {
                path: 'truck-activity',
                redirect: 'truck-activity/list',
                name: 'MarketingTools.TruckActivity',
                meta: {
                    Title: '集卡活动'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
                children: [
                    // 内容标签
                    {
                        path: 'list',
                        name: 'MarketingTools.TruckActivity.List',
                        meta: {Title: '集卡活动列表'},
                        component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/truck-activity/list.vue')
                    },
                    // {
                    //     path: 'add/:id',
                    //     name: 'MarketingTools.Groupadd',
                    //     meta: {Title: '拼团活动信息'},
                    //     component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/group/add.vue')
                    // },
                ]
            },
            // {
            //     path: 'analysis/list',
            //     name: 'MarketingTools.Analysis',
            //     meta: {Title: '营销分析', Auth: '{Platform}[MarketingTools](Manage)'},
            //     component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/analysis/index.vue')
            // },
            {
                path: 'analysis',
                // redirect: 'analysis/list',
                name: 'MarketingTools.Analysis',
                meta: {
                    Title: '营销分析'
                },
                component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/marketing-tools/analysis/list.vue'),
                // children: [
                    // 内容标签
                    // {
                    //     path: 'list',
                    //     name: 'Analysis.List',
                    //     meta: {Title: '营销分析'},
                    //     component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/analysis/list.vue')
                    // },
                    // {
                    //     path: 'add/:id',
                    //     name: 'MarketingTools.Groupadd',
                    //     meta: {Title: '拼团活动信息'},
                    //     component: () => import(/* webpackChunkName: "index.lazy.marketing-tools" */ '@/page/marketing-tools/group/add.vue')
                    // },
                // ]
            },
        ]
    }
]
