'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import Common from './module/common'
import CStateData from './module/c-statedata'
// import CPreLoad from './module/c-preload'
// import User from './module/user'
// import UConfig from './module/u-config'
import Biz from './module/biz'
import BMenu from './module/b-menu'
import BNavTag from './module/b-navtag'
// import BBreadcrumb from './module/b-breadcrumb'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Common,
    CStateData,
    // CPreLoad,
    // User,
    // UConfig,
    Biz,
    BMenu,
    BNavTag,
    // BBreadcrumb
  },
  // strict: process.env.NODE_ENV !== 'production'
  strict: false
})
