'use strict'

export default [
  // 视频数据
  {
    path: 'tiktok-data',
    name: 'tiktokData',
    meta: { Title: '抖音体系管理',Auth: '{DouyinData}'},
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'account',
        name: 'tiktokData.Account',
        redirect: 'account/list',
        meta: { Title: '抖音号管理',Auth: '{DouyinData}'},
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'tiktokData.Account.List',
            meta: { Title: '抖音账号授权管理',Auth: '{DouyinData}[FilmVideo](MovieList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/follow-tiktok.vue')
          },
          // {
          //   path: 'tiktok',
          //   name: 'videoData.tiktok',
          //   redirect:'tiktok/search',
          //   meta: { Title: '抖音号查找',Auth: '{DouyinData}[FilmVideo](MovieList)'},
          //   component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/common/parent-view'),
          //   children:[

              // {
              //   path: 'search',
              //   name: 'videoData.tiktokSearch',
              //   meta: { Title: '抖音号查找',Auth: '{DouyinData}[FilmVideo](MovieList)'},
              //   component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/tiktok-search.vue')
              // },
              // {
              //   path: 'details/:id',
              //   name: 'videoData.TiktokDetails',
              //   meta: { Title: '抖音号详情',Auth: '{DouyinData}[FilmVideo](MovieList)'},
              //   component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/tiktok-details.vue')
              // },
          //   ]
          // },
        ]
      },
      {
        path: 'video-data',
        name: 'videoData',
        redirect: 'video-data/starlist-data',
        meta: { Title: '抖音数据',Auth: '{DouyinData}'},
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'top-list',
            name: 'videoData.TopList',
            meta: { Title: '热门榜单',Auth: '{DouyinData}[FilmVideo](MovieList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/top-list.vue')
          },
          {
            path: 'movie-list',
            name: 'videoData.MovieList',
            meta: { Title: '电影榜',Auth: '{DouyinData}[FilmVideo](MovieList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/movie-list.vue')
          },
          {
            path: 'drama-list',
            name: 'videoData.DramaList',
            meta: { Title: '剧集榜',Auth: '{DouyinData}[FilmVideo](TVList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/drama-list.vue')
          },
          {
            path: 'hot-topic',
            name: 'videoData.hotTopic',
            meta: { Title: '热门话题',Auth: '{DouyinData}[Billboard](TopicList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/hot-topic.vue')
          },
          {
            path: 'hot-movie',
            name: 'videoData.hotMovie',
            meta: { Title: '热门视频',Auth: '{DouyinData}[Billboard](HotList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/hot-movie.vue')
          },
          {
            path: 'hot-music',
            name: 'videoData.hotMusic',
            meta: { Title: '热门音乐',Auth: '{DouyinData}[Billboard](MusicList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/hot-music.vue')
          },
          {
            path: 'variety-show',
            name: 'videoData.VarietyShow',
            meta: { Title: '综艺榜',Auth: '{DouyinData}[FilmVideo](ArtList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/variety-show.vue')
          },


          {
            path: 'account-ranking',
            name: 'videoData.AccountRanking',
            meta: { Title: '涨粉排行',Auth: '{DouyinData}[FilmVideo](MovieList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/account-ranking.vue')
          },
          {
            path: 'starlist-data',
            name: 'videoData.StarlistData',
            meta: { Title: '星图达人热榜',Auth: '{DouyinData}[Billboard](StarHotList)'},
            component: () => import(/* webpackChunkName: "index.lazy.tiktok-data" */ '@/page/video-data/starlist-data.vue')
          },
        ]
      }
    ]
  },

]
