<template>
  <div>
      <a-pagination
            size="small"
            :hide-on-single-page="false"
            :page-size-options="pageSizes"
            :current="currentPage"
            :page-size.sync="pageSize"
            :total="total"
            :show-total="(total) => `共 ${total} 条`"
            :show-size-changer="true"
            show-quick-jumper
            @change="handleSizeChange"
            @showSizeChange="handleCurrentChange"
          >
          </a-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    pageNum: {
      type: Number,
    },
    limit: {
      type: Number,
      default: 20
    },
    autoScroll: {
      type: Boolean,
      default: false
    },
    pageSizes: {
      type: Array,
      default() {
        return ['5', '10', '20', '50','100']
      }
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.pageNum
      },
      // set(val) {
      //   console.log(val);
      // }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
     handleCurrentChange(val) {
       this.$emit('update:pageNum', 1)
      this.$emit('pagination', { pageNum: 1, limit: val })
      if (this.autoScroll) {
        this.$UtilsBiz.scrollTo(0, 800)
      }
    },

    handleSizeChange(val) {
      this.$emit('update:pageNum', val)
      this.$emit('pagination', { pageNum: val, limit: this.pageSize })
      if (this.autoScroll) {
        this.$UtilsBiz.scrollTo(0, 800)
      }
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
}
.pagination-container.hidden {
  display: none;
}
</style>
