<template>
  <a-config-provider :locale="locale">
    <keep-alive >
      <router-view />
      <loading></loading>
    </keep-alive>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import loading from "./components/loading.vue";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    loading,
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: {
    ...mapState({
      LOADING: state => state.Common.LoadingAPI
    }),
  },
  created() {
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
