'use strict'

import KOCSleep from 'koc-common-sleep'
import StoreConst from '../const'

const state = {
  LoadingRouter: false,
  LoadingAPI: false,
  Shortcut: {
    Show: false,
    Type: null
  }
}

const getters = {
  [StoreConst.Common.Getter.LoadingRouter]: state => {
    return state.LoadingRouter
  },
  [StoreConst.Common.Getter.LoadingAPI]: state => {
    return state.LoadingAPI
  },
  [StoreConst.Common.Getter.Shortcut]: state => {
    return state.Shortcut
  }
}

const actions = {
  async [StoreConst.Common.Action.LoadingRouter_End] ({commit}) {
    commit(StoreConst.Common.Mutation.LoadingRouter, 'f')
    await KOCSleep(60)
    if (state.LoadingRouter === 'f' && !state.LoadingAPI) commit(StoreConst.Common.Mutation.LoadingRouter, false)
  },
  async [StoreConst.Common.Action.LoadingAPI_End] ({commit}) {
    commit(StoreConst.Common.Mutation.LoadingAPI, 'f')
    await KOCSleep(20)
    if (state.LoadingAPI !== 'f') return
    commit(StoreConst.Common.Mutation.LoadingAPI, false)
    if (state.LoadingRouter === 'f') commit(StoreConst.Common.Mutation.LoadingRouter, false)
  }
}

const mutations = {
  [StoreConst.Common.Mutation.LoadingRouter] (state, loading) {
    state.LoadingRouter = loading === undefined ? true : loading
  },
  [StoreConst.Common.Mutation.LoadingAPI] (state, loading) {
    state.LoadingAPI = loading === undefined ? true : loading
  },
  [StoreConst.Common.Mutation.Shortcut] (state, show) {
    if (show) {
      state.Shortcut.Show = true
      state.Shortcut.Type = show
    } else {
      state.Shortcut.Show = false
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
