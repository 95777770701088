export default {
  mainName: 'Root',
  pagination: {
    pageSize: 10,
    sizeOpts: ['5', '10', '20', '50', '100'],
    showSizeChanger: true,
    showQuickJumper: true
  },
  Setting: {
    Logo: process.env.VUE_APP_OSS + 'static/qswnet/biz/logo.png', // 首页左上角logo图， 大小：442*68
    BgImg: process.env.VUE_APP_OSS + 'static/qswnet/biz/left.png', // 背景图 大小：1266*796
    Title: '氢数 智能营销平台', // 登录框显示标题
    CompanyID: 0, // 默认企业ID值
    BtnLoginStyle: { // 登录按钮样式设置
      backgroundColor: '#EB5827', // 背景色 // 黄色：#f6c820， // 橙色：#EB5827, //大有秋蓝色： #1ea7a0
      color: '#FFFFFF' // 字体颜色 灰色：333333 // 白色：#FFFFFF
    }
  }
}
