'use strict'

export default {
  Common: {
    Mutation: {
      LoadingRouter: 'COMMON/MUTATION/LOADINGROUTER',
      LoadingAPI: 'COMMON/MUTATION/LOADINGAPI',
      Shortcut: 'COMMON/MUTATION/SHORTCUT'
    },
    Action: {
      LoadingRouter_End: 'COMMON/ACTION/LOADINGROUTER-END',
      LoadingAPI_End: 'COMMON/ACTION/LOADINGAPI-END'
    },
    Getter: {
      LoadingRouter: 'COMMON/GETTER/LOADINGROUTER',
      LoadingAPI: 'COMMON/GETTER/LOADINGAPI',
      Shortcut: 'COMMON/GETTER/SHORTCUT'
    }
  },
  CStateData: {
    Mutation: {
      Set: 'CSTATEDATA/MUTATION/SET',
      Set_User: 'CSTATEDATA/MUTATION/SET-USER'
    },
    Action: {
      Set: 'CSTATEDATA/ACTION/SET',
      Clear_User: 'CSTATEDATA/ACTION/CLEAR-USER'
    },
    Getter: {
      Admin: 'CSTATEDATA/GETTER/ADMIN',
      Company: 'CSTATEDATA/GETTER/COMPANY',
      User: 'CSTATEDATA/GETTER/USER'
    }
  },
  CPreLoad: {
    Mutation: {
      SetPage: 'CPRELOAD/MUTATION/SETPAGE',
      RemovePage: 'CPRELOAD/MUTATION/REMOVEPAGE'
    },
    Getter: {
      LoadedPage: 'CPRELOAD/GETTER/LOADEDPAGE'
    }
  },
  User: {
    Mutation: {},
    Getter: {}
  },
  UConfig: {
    Mutation: {
      Common: 'UCONFIG/MUTATION/COMMON',
      Mall: 'UCONFIG/MUTATION/MALL'
    },
    Action: {
      Common: 'UCONFIG/ACTION/COMMON',
      Mall: 'UCONFIG/ACTION/MALL'
    },
    Getter: {
      Common: 'UCONFIG/GETTER/COMMON',
      Mall: 'UCONFIG/GETTER/MALL'
    }
  },
  Biz: {
    Mutation: {},
    Action: {},
    Getter: {}
  },
  BMenu: {
    Mutation: {
      Data: 'BMENU/MUTATION/DATA',
      Current: 'BMENU/MUTATION/CURRENT'
    },
    Action: {
      Data: 'BMENU/ACTION/DATA'
    },
    Getter: {
      Data: 'BMENU/GETTER/DATA'
    }
  },
  BNavTag: {
    Mutation: {
      Add: 'BNAVTAG/MUTATION/ADD',
      Delete: 'BNAVTAG/MUTATION/DELETE'
    },
    Action: {},
    Getter: {}
  },
  BBreadCrumb: {
    Mutation: {
      BreadCrumb: 'BBREADCRUMB/MUTATION/BREADCRUMB',
      BreadExplain: 'BBREADCRUMB/MUTATION/BREADEXPLAIN'
    },
    Action: {},
    Getter: {}
  }
}
