import Vue from 'vue'
import VueRouter from 'vue-router'
import KOCString from 'koc-common-string'
import UtilsBiz from '../utils/biz'
import Store from '../store/biz'
import StoreConst from '../store/const'

import RouterMain from './main'
// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const RouterIndex = new VueRouter({
  mode: 'history',
  routes: RouterMain
})

// beforeEach
RouterIndex.beforeEach(async (to, from, next) => {
  if (to.matched.length === 0) {
    // 匹配不到路由跳转到上级,如果没有上级跳转到首页
    if (to.name.split('.').length > 1) {
      next({ name: to.name.split('.')[0] })
    } else {
      next({ name: 'Index' })
      return false
    }
  }

  // 计算菜单
  if(to.path.indexOf('/list') !==-1){
    to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
  }
  // Loading效果处理
  Store.commit(StoreConst.Common.Mutation.LoadingRouter)
  // meta 状态验证参数
  //  State: true/false // 是否验证状态信息(默认true)
  //  Auth: // 状态验证参数
  //    string // 同@RightCode
  //    or
  //    {
  //      RightCode: 'Code'
  //    }
  //  CompanyID: [] // 允许商户ID（正式环境验证）

  const value = Object.assign({
    RightCode: false
  }, to.meta.Auth || to.meta.Auth === '' ? ((typeof to.meta.Auth !== 'object') ? { RightCode: to.meta.Auth } : to.meta.Auth) : false)
  // CompanyID限制(正式环境中验证)
  if (process.env.NODE_ENV === 'production' && to.meta.CompanyID) value.AuthCompanyID = Array.isArray(to.meta.CompanyID) ? to.meta.CompanyID : [to.meta.CompanyID]
  // 无需验证状态，渲染页面(如:错误页面)
  if (to.meta.state === false) {
    next()
    return
  }
  // if(to.path.indexOf('/login') !==-1){
  //   next()
  //   return
  // }
  // 更新用户状态
  let retValue = await Store.dispatch(StoreConst.CStateData.Action.Set)
  if (retValue.hasError) {
    console.log('CStateData.Action.Set', retValue)
    // 转到错误页面
    return
  }
  // 获取菜单信息(登录状态下读取)
  if (await UtilsBiz.AuthCheck('', false) === true) {
    retValue = await Store.dispatch(StoreConst.BMenu.Action.Data)
    if (retValue.hasError) {
      console.log('BMenu.Action.Data', retValue)
      // 转到错误页面
      return
    }
  }
  // 验证权限
  const authCheck = await UtilsBiz.AuthCheck(value.RightCode, to.fullPath, false)
  if (authCheck !== true) {
    if (KOCString.Regular.Verify(authCheck, 'Http')) {
      window.location.href = authCheck
    } else {
      next(authCheck)
    }
    return
  }
  Store.commit(StoreConst.BMenu.Mutation.Current, to.name)
  // 菜单标题说明
  // Store.commit(StoreConst.BBreadCrumb.Mutation.BreadExplain, to)
  // 写入导航标签
  Store.commit(StoreConst.BNavTag.Mutation.Add, {
    Route: {
      Name: to.name,
      Query: to.query,
      Params: to.params,
      Meta: to.meta
    },
    Index: null
  })
  // 路由确定
  next()
})

// afterEach
RouterIndex.afterEach(async route => {
  // Loading效果处理
  await Store.dispatch(StoreConst.Common.Action.LoadingRouter_End)
  // 回顶部
  window.scrollTo(0, 0)
  // Shortcut设置
  Store.commit(StoreConst.Common.Mutation.Shortcut, route.meta.Shortcut)
})

export default RouterIndex
