'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import Common from './module/common'
import CStateData from './module/c-statedata'
import CPreLoad from './module/c-preload'
import User from './module/user'
import UConfig from './module/u-config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Common,
    CStateData,
    CPreLoad,
    User,
    UConfig,
  },
  // strict: process.env.NODE_ENV !== 'production'
  strict: false
})
