'use strict'

export default [
  // 客户运营
  {
    path: 'customer-manage',
    name: 'CustomerManage',
    meta: {
      Title: '用户运营',
      Auth: '{Customer}'
    },
    component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'user',
        redirect: 'user/list',
        meta: { Title: '用户管理'},
        component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'CustomerManage.List',
            meta: {
              Title: '用户列表',
              Auth: '{Customer}[List]'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/user/list.vue')
          },
          {
            path: 'info/:id',
            name: 'CustomerManage.Info',
            meta: {
              Title: '用户详情',
              Auth: '{Customer}[Info]'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/user/info.vue')
          },
        ]
      },
      {
        path: 'user-tag',
        redirect: 'user-tag/list',
        name: 'CustomerManage.UserTag',
        meta: {
          Title: '用户标签',
          Auth: '{Member}[Tag]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'CustomerManage.UserTag.List',
            meta: {
              Title: '用户标签列表',
              Auth: '{Member}[Tag](ListTag)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/tag/user-tag-list.vue')
          },
          {
            path: 'add/:id?',
            name: 'CustomerManage.UserTag.Add',
            meta: {
              Title: '用户标签编辑',
              Auth: '{Member}[Tag](AddTag)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/tag/user-tag-add.vue')
          },
          {
            path: 'info/:id?',
            name: 'CustomerManage.UserTag.Info',
            meta: {
              Title: '用户标签详情',
              Auth: '{Member}[Tag](InfoTag)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/tag/user-tag-info.vue')
          },
        ]
      },
      {
        path: 'tag',
        redirect: 'tag/list',
        name: 'CustomerManage.Tag',
        meta: {
          Title: '标签管理',
          Auth: '{Member}[Tag]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [{
            path: 'list',
            name: 'CustomerManage.Tag.List',
            meta: {
              Title: '标签列表',
              Auth: '{Member}[Tag](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/tag/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'CustomerManage.Tag.Add',
            meta: {
              Title: '标签编辑',
              Auth: '{Member}[Tag](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/tag/add.vue')
          },
          {
            path: 'info/:id',
            name: 'CustomerManage.Tag.Info',
            meta: {
              Title: '标签详情',
              Auth: '{Member}[Tag](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/tag/info.vue')
          }
        ]
      },
      {
        path: 'customer-analysis',
        name: 'DataAnalysis.CustomerAnalysis',
        meta: { Title: '用户分析' },
        component: () => import(/* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/customer-analysis.vue')
      },
      {
        path: 'user/tag-add/:id?',
        name: 'CustomerManage.User.TagAdd',
        meta: {
          Title: '用户标签添加'
          // Auth: '{Member}[Tag](AddTag)'
        },
        component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/user/tag-add.vue')
      },
      {
        path: 'marketing-task-manage',
        redirect: 'marketing-task-manage/list',
        name: 'CustomerManage.MarketingTaskManage',
        meta: {
          Title: '消息群发',
          Auth: '{Member}[User](WechatList)'
        },
        component: () => import( /* webpackChunkName: "index.lazy.company-manage" */ '@/page/common/parent-view'),
        children: [{
            path: 'list',
            name: 'CustomerManage.MarketingTaskManage.List',
            meta: {
              Title: '消息群发列表'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/marketing-task-manage/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'CustomerManage.MarketingTaskManage.Add',
            meta: {
              Title: '消息群发编辑'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/customer-manage/marketing-task-manage/add.vue')
          },
        ]
      },

    ]
  }
]
