'use strict'

import StoreConst from '../const'

const state = {
  LoadedPage: {}
}

const getters = {
  [StoreConst.CPreLoad.Getter.LoadedPage]: state => page => {
    return state.LoadedPage[page]
  }
}

const actions = {}

const mutations = {
  [StoreConst.CPreLoad.Mutation.SetPage] (state, page) {
    state.LoadedPage[page] = true
  },
  [StoreConst.CPreLoad.Mutation.RemovePage] (state, page) {
    state.LoadedPage[page] = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
