'use strict'

import KOCReturn from 'koc-common-return'
import StoreConst from '../const'
import APIUser from '../../api/biz'
import UtilsUser from '../../utils/user'

const state = {
  Common: {
    Init: false,
    Shortcut: null
  },
  Mall: {
    Init: false,
    URL: {
      Index: null, // 商城首页
      Point: null, // 积分首页
      List: null // 商品列表
    },
    ServicePhone: null, // 客服电话
    Shortcut: null
  }
}

const getters = {
  [StoreConst.UConfig.Getter.Mall]: state => {
    return state.Mall
  },
  [StoreConst.UConfig.Getter.Common]: state => {
    return state.Common
  }
}

const actions = {
  async [StoreConst.UConfig.Action.Common] ({commit}, reload) {
    if (state.Common.Init === UtilsUser.Cookie.CompanyID.Get() && !reload) return KOCReturn.Value()
    const retValue = await APIUser.Config({
      Company_ID: UtilsUser.Cookie.CompanyID.Get(),
      List: 'Common'
    })
    if (retValue.hasError) return retValue
    commit(StoreConst.UConfig.Mutation.Common, retValue.returnObject)
    return retValue
  },
  async [StoreConst.UConfig.Action.Mall] ({commit}, reload) {
    if (state.Mall.Init === UtilsUser.Cookie.CompanyID.Get() && !reload) return KOCReturn.Value()
    const retValue = await APIUser.Config({
      Company_ID: UtilsUser.Cookie.CompanyID.Get(),
      List: 'Mall'
    })
    if (retValue.hasError) return retValue
    commit(StoreConst.UConfig.Mutation.Mall, retValue.returnObject)
    return retValue
  }
}

const mutations = {
  [StoreConst.UConfig.Mutation.Common] (state, data) {
    if (!data) return
    state.Common.Init = data.Company_ID
    state.Common.Shortcut = data.Common.Shortcut
  },
  [StoreConst.UConfig.Mutation.Mall] (state, data) {
    if (!data) return
    state.Mall.Init = data.Company_ID
    state.Mall.URL.Index = data.Mall.URL.Index
    state.Mall.URL.Point = data.Mall.URL.Point || state.Mall.URL.Index
    state.Mall.URL.List = data.Mall.URL.List
    state.Mall.ServicePhone = data.Mall.ServicePhone
    state.Mall.Shortcut = data.Mall.Shortcut
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
