import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VueClipboard2 from 'vue-clipboard2'
import router from './router'
import Moment from 'moment'
import KOCString from 'koc-common-string'
import KOCSleep from 'koc-common-sleep'
import store from '@/store/biz'
import StoreConst from '@/store/const'
import UtilsCommon from '@/utils/common'
import UtilsBiz from '@/utils/biz'
import APIBiz from '@/api/biz'
import Config from '../config/site.config'
import Antd from 'ant-design-vue'
import VueQuillEditor from 'vue-quill-editor'
import Imger from "@/components/imger";
import Pagination from "@/components/Pagination";

import 'ant-design-vue/dist/antd.css'
import './style/common.scss'
import './style/iconfont/iconfont.css'
import './style/iconfont/iconfont-common.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'default-passive-events'

import VueColorpicker from 'vcolorpicker'

VueClipboard2.config.autoSetContainer = true // add this line

Vue.prototype.$KOCString = KOCString
Vue.prototype.$KOCSleep = KOCSleep
Vue.prototype.$APIBiz = APIBiz
Vue.prototype.$UtilsCommon = UtilsCommon
Vue.prototype.$UtilsBiz = UtilsBiz
Vue.prototype.$StoreConst = StoreConst
Vue.prototype.$EventBus = new Vue()
Vue.prototype.$Moment = Moment
Vue.prototype.$Config = Config

Vue.config.productionTip = false

Vue.use(VueQuillEditor);
Vue.use(Antd)
Vue.use(VueMeta)
Vue.use(VueClipboard2)
Vue.use(VueColorpicker)

Vue.component('Pagination', Pagination)
Vue.component('Imger', Imger)

const vueInstance =new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

UtilsCommon.Instance(vueInstance)
UtilsBiz.Instance(vueInstance)
