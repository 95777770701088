'use strict'

// service 接口服务

import Axios from 'axios'
import KOCReturn from 'koc-common-return'
import UtilsCommon from '../utils/common'
import Store from '../store/user'
import StoreConst from '../store/const'

const APIService = {
  Instance: null,
  Request: (method, url, data, config) => {
    return KOCReturn.Promise(() => {
      switch (method) {
        case 'get':
          return APIService.Instance.get(url, config || data)
        case 'delete':
          return APIService.Instance.delete(url, config || data)
        case 'head':
          return APIService.Instance.head(url, config || data)
        case 'post':
          return APIService.Instance.post(url, data, config)
        case 'put':
          return APIService.Instance.put(url, data, config)
        case 'patch':
          return APIService.Instance.patch(url, data, config)
        default:
          return KOCReturn.Value({
            hasError: true,
            message: 'method error'
          })
      }
    })
  },
  RequestGet: (url, config) => {
    return APIService.Request('get', url, null, config)
  },
  RequestDelete: (url, config) => {
    return APIService.Request('delete', url, null, config)
  },
  RequestHead: (url, config) => {
    return APIService.Request('head', url, null, config)
  },
  RequestPost: (url, data, config) => {
    return APIService.Request('post', url, data, config)
  },
  RequestPut: (url, data, config) => {
    return APIService.Request('put', url, data, config)
  },
  RequestPatch: (url, data, config) => {
    return APIService.Request('patch', url, data, config)
  }
}

APIService.Instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
})

// http request 拦截器
APIService.Instance.interceptors.request.use(
  config => {
    // Loading效果处理
    Store.commit(StoreConst.Common.Mutation.LoadingAPI)
    // jwt处理
    const jsonWebToken = UtilsCommon.Cookie.JsonWebToken.Get()
    if (jsonWebToken) {
      config.headers.Authorization = `jwt ${jsonWebToken}`
    }
    return config
  },
  err => {
    // Loading效果处理
    Store.dispatch(StoreConst.Common.Action.LoadingAPI_End).then(() => {
      return Promise.reject(err)
    })
  }
)

// http respones 拦截器
APIService.Instance.interceptors.response.use(
  async response => {
      // Loading效果处理
      await Store.dispatch(StoreConst.Common.Action.LoadingAPI_End)
      // 返回值处理
      if (response.status !== 200) return Promise.reject(new Error('status:' + response.status + ', statusText:' + response.statusText))
      switch (response.data.flag) {
        case -9999: // token过期
          UtilsCommon.Cookie.JsonWebToken.Del()
          UtilsCommon.LocationReload()
          return Promise.reject(new Error(`${response.data.msg} - ${response.config.url}`))
        case -1: // 未登录(隐性授权(微信))
          console.log('转向登录(隐性)')
          return Promise.reject(new Error(`${response.data.msg} - ${response.config.url}`))
        case -2: // 未登录(显性授权(微信))
          console.log('转向登录(显性)')
          return Promise.reject(new Error(`${response.data.msg} - ${response.config.url}`))
        case -3: // 填写手机
          console.log('转向验证手机')
          return Promise.reject(new Error(`${response.data.msg} - ${response.config.url}`))
        case -4: // 关注
          console.log('转向关注')
          return Promise.reject(new Error(`${response.data.msg} - ${response.config.url}`))
        case -5: // APP权限
          console.log('APP权限错误')
          return Promise.reject(new Error(`${response.data.msg} - ${response.config.url}`))
        case 0: // 正常
          // UtilsCommon.Cookie.JsonWebToken.Del()
          return response.data.data
        default: // 其它错误
          return Promise.reject(new Error(`${response.data.msg}`))
      }
    },
    error => {
      // Loading效果处理
      Store.dispatch(StoreConst.Common.Action.LoadingAPI_End)
      // 抛出错误
      return Promise.reject(new Error(error))
    }
)

export default APIService
