'use strict'

import RouterCustomerManage from '/src/router/customer-manage'
import RouterContentFactory from '/src/router/content-factory'
import RouterWechatManage from '/src/router/wechat-manage'
import RouterSystemSetting from '/src/router/system-setting'
import RouterMarketingManage from '/src/router/marketing-tools'
import RouterVideoData from '/src/router/video-data'
import RouterLiuBike from '/src/router/liubike'
import RouterArgXQ from '/src/router/argxq'
import RouterDaNianHuaWan from '/src/router/danianhuawan'
import RouterChannelDelivery from '/src/router/channel-delivery'
import RouterQwom from '/src/router/qwom'
export default [
  {
    path: '/login',
    name: 'Login',
    meta: { Title: '登录页'},
    component: () => import(/* webpackChunkName: "index.lazy.login" */ '@/page/login.vue')
  },

  {
    path: '/',
    name: 'Root',
    component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/master/main'),
    redirect:'/',
    meta: { Title: '主页'},
    children: [
      {
        path: '',
        name: 'Index',
        // redirect:'/1024/data-board',
        meta: { Title: '首页' },
        // meta: { Title: '数据看板', Auth: '{Routine}' },
        component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/index')
        // children: [{
        //   path: 'data-board',
        //   name: 'DataBoard',
        //   meta: { Title: '数据看板',Auth:'{DataBoard}' },
        //   // meta: { Title: '数据看板', Auth: '{Routine}' },
        //   component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/custom/1024/data-board'),
        // }]
      }, {
        path: 'data-board',
        name: 'DataBoard',
        meta: { Title: '数据看板',Auth:'{DataBoard}' },
        // meta: { Title: '数据看板', Auth: '{Routine}' },
        component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/data-board'),
      },
      // {
      //   path: '/no-permission',
      //   name: 'NoPermission',
      //   meta: { Title: '无权限'},
      //   component: () => import(/* webpackChunkName: "index.lazy.login" */ '@/page/no-permission')
      // },
      ...RouterCustomerManage,
      ...RouterContentFactory,
      ...RouterWechatManage,
      ...RouterSystemSetting,
      ...RouterMarketingManage,
      ...RouterVideoData,
      ...RouterLiuBike,
      ...RouterArgXQ,
      ...RouterDaNianHuaWan,
      ...RouterChannelDelivery,
      ...RouterQwom
    ]
  }
]
