'use strict'

module.exports = {
  // 'd-admin': {
  //   subdomain: 'd.admin',
  //   port: 4001
  // },
  'd-biz': {
    subdomain: 'd.biz',
    port: 4002
  },
  // 'm-user': {
  //   subdomain: 'm.user',
  //   port: 4003
  // },
  // 'm-h5': {
  //   subdomain: 'm.h5',
  //   port: 4004
  // },
  // 'd-admin': {
  //   subdomain: 'd.admin',
  //   port: 4005
  // },
  // 'd-classroom': {
  //   subdomain: 'd.classroom',
  //   port: 4006
  // }
}
