'use strict'

export default [
  // 系统设置
  {
    path: 'system-setting',
    name: 'SystemSetting',
    meta: { Title: '系统设置' },
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'base-setting',
        name: 'SystemManage.BaseManage',
        meta: { Title: '基础设置' , Auth: '{System}[Base]'},
        component: () => import(/* webpackChunkName: "index.lazy.system-setting" */ '@/page/system-setting/base-setting.vue')
      },
      {
        path: 'personnel-manage',
        redirect: 'personnel-manage/list',
        name: 'SystemSetting.PersonnelManage',
        meta: {
            Title: '人员设置'
        },
        component: () => import( /* webpackChunkName: "index.lazy.system-setting" */ '@/page/common/parent-view'),
        children: [
            // 内容标签
            {
              path: 'list',
              name: 'SystemManage.EmployeeManage.List',
              meta: { Title: '人员管理', Auth: '{System}[Employee](List)'},
              component: () => import(/* webpackChunkName: "index.lazy.system-setting" */ '@/page/system-setting/personnel-manage/list.vue')
            },
            {
              path: 'add/:id',
              name: 'SystemManage.EmployeeManage.Add',
              meta: { Title: '人员详情', Auth: '{System}[Employee](Add)' },
              component: () => import(/* webpackChunkName: "index.lazy.system-setting" */ '@/page/system-setting/personnel-manage/add.vue')
            },
        ]
    },

      {
        path: 'password',
        name: 'SystemManage.PersonalManage.ChangePassword',
        meta: { Title: '修改密码' ,Auth: '{System}[Personal](ChangePassword)'},
        component: () => import(/* webpackChunkName: "index.lazy.system-setting" */ '@/page/system-setting/password.vue')
      },
      {
        path: 'log-manage',
        name: 'SystemManage.LogManage.List',
        meta: { Title: '日志管理' , Auth: '{System}[Log](List)'},
        component: () => import(/* webpackChunkName: "index.lazy.system-setting" */ '@/page/system-setting/log-manage.vue')
      },
    ]
  }
]
