'use strict'

export default [
  // 数据看板
  {
    path: '1025/data-board',
    name: 'C1025DataBoard',
    meta: { Title: '数据看板',Auth:'{C1025DataBoard}' },
    // meta: { Title: '数据看板', Auth: '{Routine}' },
    component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/data-board'),
  },
  // 用户运营
  {
    path: '1025/customer-manage',
    name: 'C1025CustomerManage',
    meta: { Title: '用户运营', Auth: '{C1025Customer}'},
    component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'user',
        redirect: 'user/list',
        meta: { Title: '用户管理'},
        component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025CustomerManage.List',
            meta: {
              Title: '用户列表',
              Auth: '{C1025Customer}[List]'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/custom/1025/customer-manage/user/list.vue')
          },
          {
            path: 'info/:id',
            name: 'C1025CustomerManage.Info',
            meta: {
              Title: '用户详情',
              Auth: '{C1025Customer}[Info]'
            },
            component: () => import( /* webpackChunkName: "index.lazy.customer-manage" */ '@/page/custom/1025/customer-manage/user/info.vue')
          },
        ]
      }, {
        path: 'tag',
        redirect: 'tag/list',
        name: 'C1025CustomerManage.TagManage',
        meta: {
          Title: '标签管理',
          Auth: '{C1025Customer}[Tag](List)'
        },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025CustomerManage.TagManage.List',
            meta: {
              Title: '标签列表',
              Auth: '{C1025Customer}[Tag](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-customer-manage" */ '@/page/custom/1025/customer-manage/tag/list.vue')
          }, {
            path: 'add/:id?',
            name: 'C1025CustomerManage.TagManage.Add',
            meta: {
              Title: '标签编辑',
              Auth: '{C1025Customer}[Tag](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-customer-manage" */ '@/page/custom/1025/customer-manage/tag/add.vue')
          }, {
            path: 'info/:id',
            name: 'C1025CustomerManage.TagManage.Info',
            meta: {
              Title: '标签详情',
              Auth: '{C1025Customer}[Tag](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-customer-manage" */ '@/page/custom/1025/customer-manage/tag/info.vue')
          }
        ]
      }, {
        path: 'user-analysis',
        name: 'C1025CustomerManage.Analysis',
        meta: {
          Title: '用户分析',
          Auth: ''
        },
        component: () => import( /* webpackChunkName: "index.lazy.c1025-customer-manage" */ '@/page/custom/1025/customer-manage/user-analysis.vue')
      }]
  },
  // 策略中心
  {
    path: '1025/tack-manage',
    name: 'C1025TackManage',
    meta: {Title: '策略中心', Auth: '{Tack}'},
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'tack',
        meta: { Title: '策略管理' },
        redirect: 'tack/list',
        component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.BaseManage.List',
            meta: { Title: '策略列表', Auth: '{C1025Tack}[Base](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/tack/list.vue'),
          }, {
            path: 'info/:id/:type?/:category?/:groupId?',
            name: 'C1025TackManage.BaseManage.Info',
            meta: { Title: '策略详情', Auth: '{C1025Tack}[Base](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/tack/info.vue'),
          },
          {
            path: 'add/:id?/:type?/:category?/:groupId?',
            name: 'C1025TackManage.BaseManage.Add',
            meta: { Title: '策略编辑', Auth: '{C1025Tack}[Base](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/tack/add.vue'),
          }
        ]
      },
      {
        path: 'policy-group',
        meta: { Title: '策略组管理', Auth: '{C1025Tack}[Group]' },
        redirect: 'policy-group/list',
        component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.GroupManage.List',
            meta: { Title: '策略组列表', Auth: '{C1025Tack}[Group](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/policy-group/list.vue'),
          },
          {
            path: 'info/:id',
            name: 'C1025TackManage.GroupManage.Info',
            meta: { Title: '策略组详情', Auth: '{C1025Tack}[Group](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/policy-group/info.vue'),
          },
          {
            path: 'add/:id?',
            name: 'C1025TackManage.GroupManage.Add',
            meta: { Title: '策略组编辑', Auth: '{C1025Tack}[Group](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/policy-group/add.vue'),
          }
        ]
      },
      {
        path: 'position',
        meta: { Title: '触点管理' },
        redirect: 'position/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.PositionManage.List',
            meta: { Title: '触点列表', Auth: '{C1025Tack}[Position](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/position/list.vue'),
          },
          {
            path: 'info/:id',
            name: 'C1025TackManage.PositionManage.Info',
            meta: { Title: '触点详情', Auth: '{C1025Tack}[Position](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/position/info.vue'),
          }
        ]
      },
      {
        path: 'contact-resources',
        name: 'C1025TackManage.MaterialGroupManage',
        meta: { Title: '触点资源', Auth: '{C1025Tack}[MaterialGroup](List)' },
        redirect: 'contact-resources/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.MaterialGroupManage.List',
            meta: { Title: '触点资源列表', Auth: '{C1025Tack}[MaterialGroup](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/contact-resources/list.vue'),
          },
          {
            path: 'info/:id?/:type',
            name: 'C1025TackManage.MaterialGroupManage.Info',
            meta: { Title: '触点资源详情', Auth: '{C1025Tack}[MaterialGroup](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/contact-resources/info.vue'),
          },
          {
            path: 'add/:id?/:type',
            name: 'C1025TackManage.MaterialGroupManage.Add',
            meta: { Title: '触点资源添加', Auth: '{C1025Tack}[MaterialGroup](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/contact-resources/add.vue'),
          },
        ]
      },
      {
        path: 'strategy-material',
        name: 'C1025TackManage.StrategyMaterial',
        meta: { Title: '策略素材', Auth: '{C1025Tack}[Material](List)' },
        redirect: 'strategy-material/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.MaterialManage.List',
            meta: { Title: '策略素材列表', Auth: '{C1025Tack}[Material](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/strategy-material/list.vue'),
          },
          {
            path: 'add',
            name: 'C1025TackManage.MaterialManage.Add',
            meta: { Title: '素材编辑',Auth:'{C1025Tack}[Material](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/strategy-material/add.vue'),
          },
        ]
      },
      {
        path: 'data-statistics',
        meta: { Title: '策略分析',Auth:'{C1025Tack}[Data](List)'},
        redirect: 'data-statistics/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.DataManage.List',
            meta: { Title: '',Auth:'{C1025Tack}[Data](List)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/data-statistics/list.vue'),
          },
          {
            path: 'info/:id',
            name: 'C1025TackManage.DataManage.Info',
            meta: { Title: '策略数据详情',Auth:'{C1025Tack}[Data](Info)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/data-statistics/info.vue'),
          },
          {
            path: 'index',
            name: 'C1025TackManage.DataManage.IndexInfo',
            meta: { Title: '关键指标详情',Auth:'{C1025Tack}[Data](IndexInfo)' },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/data-statistics/index-info.vue'),
          },
        ]
      },
      {
        path: 'sms-push',
        name: 'C1025TackManage.SMSPushManage',
        meta: { Title: '短信群发',
          Auth:'{C1025Tack}[SMSPush](List)'
        },
        redirect: 'sms-push/list',
        component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children:[
          {
            path: 'list',
            name: 'C1025TackManage.SMSPushManage.List',
            meta: { Title: '群发列表',
              Auth:'{C1025Tack}[SMSPush](List)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/sms-push/list.vue'),
          },
          {
            path: 'add/:id?',
            name: 'C1025TackManage.SMSPushManage.Add',
            meta: { Title: '短信群发新建/编辑',
              Auth:'{C1025Tack}[SMSPush](Add)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/sms-push/add.vue'),
          },
          {
            path: 'info/:id',
            name: 'C1025TackManage.SMSPushManage.Info',
            meta: { Title: '短信群发详情',Auth:'{C1025Tack}[SMSPush](Info)'},
            component: () => import(/* webpackChunkName: "index.lazy.tack-center" */ '@/page/custom/1025/tack-manage/sms-push/info.vue'),
          }
        ]
      }
    ]
  },
  // 营销工具
  {
    path: '1025/marketing-manage',
    name: 'C1025MarketingManage',
    meta: {
      Title: '营销工具',
      Auth: '{Platform}[Marketing]'
    },
    component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'coupon-manage',
        redirect: 'coupon-manage/list',
        meta: { Title: '优惠券管理' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025MarketingManage.CouponManage.List',
            meta: {
              Title: '优惠券列表',
              Auth: '{C1025Marketing}[Coupon](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-manage/list.vue')
          }, {
            path: 'add/:id?',
            name: 'C1025MarketingManage.CouponManage.Add',
            meta: {
              Title: '优惠券信息编辑',
              Auth: '{C1025Marketing}[Coupon](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-manage/add.vue')
          }, {
            path: 'info/:id',
            name: 'C1025MarketingManage.CouponManage.Info',
            meta: {
              Title: '优惠券详情',
              Auth: '{C1025Marketing}[Coupon](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-manage/info.vue')
          }
        ]
      },
      {
        path: 'coupon-daily',
        redirect: 'coupon-daily/list',
        meta: { Title: '日常发券' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025MarketingManage.CouponDailyManage.List',
            meta: {
              Title: '',
              Auth: '{C1025Marketing}[CouponDaily](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-daily/list.vue')
          }, {
            path: 'add/:id?',
            name: 'C1025MarketingManage.CouponDailyManage.Add',
            meta: {
              Title: '编辑活动',
              Auth: '{C1025Marketing}[CouponDaily](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-daily/add.vue')
          }, {
            path: 'info/:id',
            name: 'C1025MarketingManage.CouponDailyManage.Info',
            meta: {
              Title: '活动详情',
              Auth: '{C1025Marketing}[CouponDaily](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-daily/info.vue')
          },
          {
            path: 'index-info/:id',
            name: 'C1025MarketingManage.CouponDailyManage.IndexInfo',
            meta: {
              Title: '指标详情',
              Auth: '{C1025Marketing}[CouponDaily](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/coupon-daily/index-info.vue')
          }
        ]
      },
      {
        path: 'register',
        redirect: 'register/list',
        meta: { Title: '注册有礼', },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025MarketingManage.RegisterManage.List',
            meta: {
              Title: '活动列表',
              Auth: '{C1025Marketing}[Register](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/register/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'C1025MarketingManage.RegisterManage.Add',
            meta: {
              Title: '编辑活动',
              Auth: '{C1025Marketing}[Register](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/register/add.vue')
          },
          {
            path: 'info/:id',
            name: 'C1025MarketingManage.RegisterManage.Info',
            meta: {
              Title: '活动详情',
              Auth: '{C1025Marketing}[Register](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/register/info.vue')
          },
          {
            path: 'index-info/:id',
            name: 'C1025MarketingManage.RegisterManage.IndexInfo',
            meta: {
              Title: '指标详情',
              Auth: '{C1025Marketing}[Register](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/register/index-info.vue')
          }
        ]
      },
      {
        path: 'fission',
        redirect: 'fission/list',
        meta: { Title: '裂变抽奖' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          // 活动列表
          {
            path: 'list',
            name: 'C1025MarketingManage.FissionManage.List',
            meta: {
              Title: '',
              Auth: '{C1025Marketing}[Fission](List)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.marketing-manage" */ '@/page/custom/1025/marketing-manage/fission/list.vue')
          },
          {
            path: 'add/:id',
            name: 'C1025MarketingManage.FissionManage.Add',
            meta: {
              Title: '新建活动',
              Auth: '{C1025Marketing}[Fission](Add)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.marketing-manage" */ '@/page/custom/1025/marketing-manage/fission/add.vue')
          },
          {
            path: 'info/:id',
            name: 'C1025MarketingManage.FissionManage.Info',
            meta: {
              Title: '活动详情',
              Auth: '{C1025Marketing}[Fission](Info)'
            },
            component: () => import(/* webpackChunkName: "index.lazy.marketing-manage" */ '@/page/custom/1025/marketing-manage/fission/info.vue')
          },
          {
            path: 'index-info/:id',
            name: 'C1025MarketingManage.FissionManage.IndexInfo',
            meta: {
              Title: '指标详情',
              Auth: '{C1025Marketing}[Fission](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/fission/index-info.vue')
          }
        ]
      },
      {
        path: 'analysis',
        name: 'C1025MarketingManage.Analysis',
        meta: {
          Title: '营销分析',
          // Auth: '{C1025Marketing}[Analysis]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.c1025-customer-manage" */ '@/page/custom/1025/marketing-manage/analysis.vue')
      },
      {
        path: 'invite',
        redirect: 'invite/list',
        meta: { Title: '邀请好友' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025MarketingManage.InviteManage.List',
            meta: {
              Title: '',
              Auth: '{C1025Marketing}[Invite](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/invite/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'C1025MarketingManage.InviteManage.Add',
            meta: {
              Title: '编辑活动',
              Auth: '{C1025Marketing}[Invite](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/invite/add.vue')
          },
          {
            path: 'info/:id',
            name: 'C1025MarketingManage.InviteManage.Info',
            meta: {
              Title: '活动详情',
              Auth: '{C1025Marketing}[Invite](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/invite/info.vue')
          },
          {
            path: 'index-info/:id',
            name: 'C1025MarketingManage.InviteManage.IndexInfo',
            meta: {
              Title: '指标详情',
              Auth: '{C1025Marketing}[Invite](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/invite/index-info.vue')
          }
        ]
      },
      {
        path: 'seckill',
        redirect: 'seckill/list',
        meta: { Title: '分阶秒杀' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025MarketingManage.SeckillManage.List',
            meta: {
              Title: '秒杀活动列表',
              Auth: '{C1025Marketing}[Seckill](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/seckill/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'C1025MarketingManage.SeckillManage.Add',
            meta: {
              Title: '编辑活动',
              Auth: '{C1025Marketing}[Seckill](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/seckill/add.vue')
          },
          {
            path: 'info/:id',
            name: 'C1025MarketingManage.SeckillManage.Info',
            meta: {
              Title: '活动详情',
              Auth: '{C1025Marketing}[Seckill](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/seckill/info.vue')
          },
          {
            path: 'order-list/:id?',
            name: 'C1025MarketingManage.SeckillManage.OrderList',
            meta: {
              Title: '秒杀订单列表',
              Auth: '{C1025Marketing}[Seckill](OrderList)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/seckill/order/list.vue')
          },
          {
            path: 'order-info/:id',
            name: 'C1025MarketingManage.SeckillManage.OrderInfo',
            meta: {
              Title: '秒杀订单详情',
              Auth: '{C1025Marketing}[Seckill](OrderInfo)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/seckill/order/info.vue')
          },
          {
            path: 'index-info/:id',
            name: 'C1025MarketingManage.SeckillManage.IndexInfo',
            meta: {
              Title: '指标详情',
              // Auth: '{C1025Marketing}[Seckill](IndexInfo)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/seckill/index-info.vue')
          }
        ]
      },
      {
        path: 'group',
        redirect: 'group/list',
        meta: { Title: '限时拼团' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1025MarketingManage.GroupManage.List',
            meta: {
              Title: '拼团活动列表',
              Auth: '{C1025Marketing}[Group](List)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/group/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'C1025MarketingManage.GroupManage.Add',
            meta: {
              Title: '编辑活动',
              Auth: '{C1025Marketing}[Group](Add)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/group/add.vue')
          },
          {
            path: 'info/:id',
            name: 'C1025MarketingManage.GroupManage.Info',
            meta: {
              Title: '活动详情',
              Auth: '{C1025Marketing}[Group](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/group/info.vue')
          },
          {
            path: 'order-list/:id?',
            name: 'C1025MarketingManage.GroupManage.OrderList',
            meta: {
              Title: '拼团订单列表',
              Auth: '{C1025Marketing}[Group](OrderList)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/group/order/list.vue')
          },
          {
            path: 'order-info/:id',
            name: 'C1025MarketingManage.GroupManage.OrderInfo',
            meta: {
              Title: '拼团订单详情',
              Auth: '{C1025Marketing}[Group](OrderInfo)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/group/order/info.vue')
          },
          {
            path: 'index-info/:id',
            name: 'C1025MarketingManage.GroupManage.IndexInfo',
            meta: {
              Title: '指标详情',
              Auth: '{C1025Marketing}[Group](Info)'
            },
            component: () => import( /* webpackChunkName: "index.lazy.c1025-marketing-manage" */ '@/page/custom/1025/marketing-manage/group/index-info.vue')
          }
        ]
      }
    ]
  }
]
