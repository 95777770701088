const PositionList = {
  TreeList: [
    { Department_ID: 1, Department_Name: "全部模块", Parent_ID: 0 },
    { Department_ID: 2, Department_Name: "打开程序", Parent_ID: 1 },
    { Department_ID: 3, Department_Name: "骑行体验", Parent_ID: 1 },
    { Department_ID: 4, Department_Name: "钱包充值", Parent_ID: 1 },
    { Department_ID: 5, Department_Name: "个人中心", Parent_ID: 1 },
    { Department_ID: 6, Department_Name: "首页", Parent_ID: 2 },
    { Department_ID: 7, Department_Name: "扫码用车页", Parent_ID: 3 },
    { Department_ID: 8, Department_Name: "费用支付页", Parent_ID: 3 },
    { Department_ID: 9, Department_Name: "选择支付方式页", Parent_ID: 3 },
    { Department_ID: 10, Department_Name: "支付完成页", Parent_ID: 3 },
    { Department_ID: 11, Department_Name: "充值页", Parent_ID: 4 },
    { Department_ID: 12, Department_Name: "选择支付方式页", Parent_ID: 4 },
    { Department_ID: 13, Department_Name: "个人中心页", Parent_ID: 5 },
    { Department_ID: 14, Department_Name: "我的行程页", Parent_ID: 5 },
    { Department_ID: 15, Department_Name: "骑行详情页", Parent_ID: 5 },
    { Department_ID: 16, Department_Name: "我的钱包页", Parent_ID: 5 },
    { Department_ID: 17, Department_Name: "我的优惠页", Parent_ID: 5 },
    { Department_ID: 18, Department_Name: "我的消息页", Parent_ID: 5 }
  ],
  List: [
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:100001,Business_Module:'打开程序',page:'首页',Position_Name:'弹窗',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/100001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:100002,Business_Module:'打开程序',page:'首页',Position_Name:'Banner位-顶部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/100002.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:101001,Business_Module:'骑行体验',page:'扫码用车页',Position_Name:'Banner位-顶部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/101001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:102001,Business_Module:'骑行体验',page:'费用支付页',Position_Name:'卡券优惠位',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/102001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:102002,Business_Module:'骑行体验',page:'费用支付页',Position_Name:'Banner位-底部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:102003,Business_Module:'骑行体验',page:'费用支付页',Position_Name:'优惠充值推荐',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:103001,Business_Module:'骑行体验',page:'选择支付方式页',Position_Name:'钱包支付/充值',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/103001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:104001,Business_Module:'骑行体验',page:'支付完成页',Position_Name:'弹窗',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/104001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:104002,Business_Module:'骑行体验',page:'支付完成页',Position_Name:'Banner位1-底部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/104002.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:104003,Business_Module:'骑行体验',page:'支付完成页',Position_Name:'Banner位2-底部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/104002.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:105001,Business_Module:'钱包充值',page:'充值页',Position_Name:'主推产品推荐',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:105002,Business_Module:'钱包充值',page:'充值页',Position_Name:'充值套餐推荐',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:106001,Business_Module:'钱包充值',page:'选择支付方式页',Position_Name:'优惠领取位',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:106002,Business_Module:'钱包充值',page:'选择支付方式页',Position_Name:'弹窗提醒',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:107001,Business_Module:'个人中心',page:'个人中心页',Position_Name:'浮动按钮-右下角',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/107001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:107002,Business_Module:'个人中心',page:'个人中心页',Position_Name:'Banner位-底部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/107002.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:108001,Business_Module:'个人中心',page:'我的行程页',Position_Name:'Banner位-底部浮动',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:109001,Business_Module:'个人中心',page:'骑行详情页',Position_Name:'浮动按钮-右上角',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:109002,Business_Module:'个人中心',page:'骑行详情页',Position_Name:'Banner位-底部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:110001,Business_Module:'个人中心',page:'我的钱包页',Position_Name:'Banner位-底部',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/110001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:111001,Business_Module:'个人中心',page:'我的优惠页',Position_Name:'弹窗',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/111001.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:111002,Business_Module:'个人中心',page:'我的优惠页',Position_Name:'Banner位-底部浮动',priority:'',Status:0,Create_time:'2022-04-22 16:15:33',src:process.env.VUE_APP_OSS + 'static/argxq/biz/position_sign/111002.png'},
    {Create_men:'@cname',Mod_men:'@cname',Update_Date:'2022-04-22 16:15:33',Position_ID:112001,Business_Module:'个人中心',page:'我的消息页',Position_Name:'Banner位-底部浮动',priority:'',Status:0,Create_time:'2022-04-22 16:15:33'}
  ]
}

export default PositionList
