'use strict'
import KOCReturn from 'koc-common-return'
import StoreConst from '../const'
import APIBiz from '../../api/biz'
import UtilsBiz from '../../utils/biz'
import Vue from 'vue';
const state = {
  Data: null,
  DataT: null,
  ClassFirst: {
    Name: null,
    List: []
  },
  ClassSecond: {
    Name: null,
    List: []
  },
  ClassThird: {
    Name: null,
    List: []
  }
}
const getters = {
  [StoreConst.BMenu.Getter.Data]: state => {
    return state.Data
  }
}
const mutations = {
  [StoreConst.BMenu.Mutation.Data] (state, data) {
    // const List = data.filter(item=>item.Text!=='抖音数据'&&item.Text!=='其它功能'&&item.Text!=='问卷调查管理'&&item.Text!=='企业微信管理'&&item.Text!=='平台管理'&&item.Text!=='内容工厂管理')
    // List.forEach(item=>{
    //   item.Child =  item.Child.filter(itemText=>itemText.Text!=='企业管理')
    // })
    // state.Data = data
    Vue.set(state,'Data', data)
  },
  [StoreConst.BMenu.Mutation.Current] (state, data) {
    const currentData = {
      ClassFirst: {
        Name: null,
        List: []
      },
      ClassSecond: {
        Name: null,
        List: []
      },
      ClassThird: {
        Name: null,
        List: []
      }
    }
    if (!state.Data || !data) {
      Object.assign(state, currentData)
      return
    }
    const currentName = data.split('.')
    // 一级菜单
    currentData.ClassFirst.List = state.Data.filter(item => UtilsBiz.AuthCodeCheck(item.RightCode) === true)
    let currentMenu = currentData.ClassFirst.List.find(item => item.Name === currentName[0])
    if (currentMenu) currentData.ClassFirst.Name = currentMenu.Name
    // 二级菜单
    if (currentMenu && currentMenu.Child) {
      currentData.ClassSecond.List = currentMenu.Child.filter(item => UtilsBiz.AuthCodeCheck(item.RightCode) === true)
      currentMenu = currentData.ClassSecond.List.length && currentName.length >= 2 ? currentData.ClassSecond.List.find(item => item.Name === `${currentName[0]}.${currentName[1]}`) : null
      if (currentMenu) currentData.ClassSecond.Name = currentMenu.Name
    }
    // 三级菜单
    if (currentMenu && currentMenu.Child) {
      currentData.ClassThird.List = currentMenu.Child
      currentMenu = currentData.ClassThird.List.length && currentName.length >= 3 ? currentData.ClassThird.List.find(item => item.Name === `${currentName[0]}.${currentName[1]}.${currentName[2]}`) : null
      if (currentMenu) currentData.ClassThird.Name = currentMenu.Name
    }
    Object.assign(state, currentData.ClassFirst)
  }
}
const actions = {
  async [StoreConst.BMenu.Action.Data] ({commit}, reload) {
    // 非强制刷新5分钟以内只取一次配置数据
    if (reload !== true && state.Data && new Date().getTime() - state.DataT <= 5 * 60) return KOCReturn.Value()
    const retValue = await APIBiz.Config()
    if (retValue.hasError) return retValue
    commit(StoreConst.BMenu.Mutation.Data, retValue.returnObject.Menu)
    return retValue
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
