import KOCString from 'koc-common-string'
import Store from '../store/user'
import StoreBusiness from '../store/biz'
import StoreConst from '../store/const'
import UtilsCommon from './common'
import Moment from 'moment'
import APIBiz from '@/api/biz'
import C1001PositionList from '../page/custom/1001/tack-manage/position/tree-list'
import C1024PositionList from '../page/custom/1024/tack-manage/position/tree-list'
import C1025PositionList from '../page/custom/1025/tack-manage/position/tree-list'

let vueInstance = null
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) {
    return c / 2 * t * t + b
  }
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}

function move(amount) {
  document.getElementsByClassName('tmain-container')[0].scrollTop = amount
}
var requestAnimFrame = (function () {
  return document.getElementsByClassNam || function (callback) {
    window.setTimeout(callback, 1000 / 60)
  }
})()

function position() {
  return document.getElementsByClassName('tmain-container')[0].scrollTop;
}

const UtilsBiz = {
  Instance: (vue) => {
    vueInstance = vue
  },
  // AuthCheck 状态验证
  // code: 权限代码
  // opts: 参数 默认值:null
  // opts.URL: 返回URL 默认值:当前url
  // to: 是否直接跳转 true/false 默认值:true
  AuthCheck: async (code, opts, to) => {
    if (code === false) return true
    if (!vueInstance) return false
    if (typeof opts === 'string') {
      opts = {URL: opts}
    }
    if (typeof opts !== 'object') {
      to = opts
      opts = {}
    }
    if (!opts.URL) opts.URL = vueInstance.$route.fullPath
    to = to !== false
    const stateCompany = Store.getters[StoreConst.CStateData.Getter.Company]
    let toValue
    if (stateCompany) {
      if (stateCompany.IsMaster || !code) return true
      for (const thisValue of code.split('|')) {
        if (thisValue && stateCompany.RightCode.indexOf(thisValue) >= 0) return true
      }
      // const Info = StoreBusiness.getters[StoreConst.CStateData.Getter.Company]
      // // if (UtilsBiz.AuthCodeCheck('{DataBoard}')) {
      // //   console.log(1)
      // //   toValue = {name: 'DataBoard'} // 无权限返回首页
      // // } else
      // if (Info.RightCode!=='') {
      //   const RightCode = Info.RightCode.split(',')[0]
      //   console.log(1, RightCode)
      //   toValue = {name: UtilsBiz.CalculationMenu(RightCode,StoreBusiness.getters[StoreConst.BMenu.Getter.Data])}
      // } else {
      //   toValue = {name: 'Index'}
      // }
      toValue = {name: 'Index'}
    } else toValue = {name: 'Login', query: {url: encodeURIComponent(opts.URL)}} // 未登录转向登录
    if (!to) return toValue
    if (KOCString.Regular.Verify(toValue, 'Http')) window.location.href = toValue
    else vueInstance.$router.push(toValue)
    return false
  },
  // CalculationMenu: (code, menu) => {
  //   for (let index = 0; index < menu.length; index++) {
  //     if(menu[index].RightCode===code) {
  //       console.log(menu[index].Name);
  //       return menu[index].Name
  //     } else if(menu[index].Child) {
  //       return UtilsBiz.CalculationMenu(code, menu[index].Child)
  //     }
  //   }
  // },
  AuthCodeCheck: (data, code) => {
    // console.log(data, code,'经过~~~~~');
    if(code==='')return false
    if (data && code) {
      if (code.indexOf(data) > -1) return true
      else return false
    } else {
      const Info = StoreBusiness.getters[StoreConst.CStateData.Getter.Company]
      // console.log(Info);
      if (!Info) return false
      code = code || Info.RightCode
      if (Info.IsMaster) {
        return true
      } else if (!code) {
        return false
      } else {
        if (code.indexOf(data) > -1) return true
        else return false
      }
    }
  },
  //  点击菜单转向页面
  TurnToPage: async (route) => {
    let { name, params, query } = {}
    if (typeof route === 'string') name = route
    else {
      name = route.name || route.Name
      params = route.params || route.Params
      query = route.query || route.Query
    }
    vueInstance.$router.push({
      name,
      params,
      query
    })
  },
  ToNextRoute: (route) => {
    const list = UtilsCommon.LocalStorage.GetJson('TagNavList') || []
    let nextRoute = {}
    if (list.length <= 1) {
      nextRoute = {name: 'Index'}
    } else {
      const index = list.findIndex(item => UtilsCommon.IsEqualRoute(item, route))
      if (index === list.length - 1) nextRoute = list[list.length - 2]
      else nextRoute = list[index + 1]
    }
    return nextRoute
  },
  TurnToBack: () => {
    vueInstance.$router.go(-1)
  },
  BreadCrumbList: (route) => {
    let routeMetched = route.matched
    let res = routeMetched.filter(item => {
      return item.meta && Object.keys(item.meta).length !== 0
    }).map(item => {
      let meta = { ...item.meta }
      if (meta.title && typeof meta.title === 'function') {
        meta.__titleIsFunction__ = true
        meta.title = meta.title(route)
      }
      let obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: meta
      }
      return obj
    })
    return res
  },
  // BreadExplain: (data) => {
  //   const menuData = StoreBusiness.getters[StoreConst.BMenu.Getter.Data]
  //   if (!menuData || !Object.keys(data.meta).length) return
  //   let retVal = {Text: data.meta.Title, Explain: null}
  //   let findItem = (arr, name) => {
  //     arr.forEach((item) => {
  //       if (item.Name === name) {
  //         retVal = item
  //         return item
  //       } else if (item.Child && item.Child.length > 0) {
  //         findItem(item.Child, name)
  //       } else {
  //         return retVal
  //       }
  //     })
  //   }
  //   findItem(menuData, data.name)
  //   return retVal
  // },
  // region 微信平台管理
  WechatManage: {
    // region 基础授权管理
    APPManage: {
      // 授权列表
      List: async(type) => {
        let retValue = await APIBiz.WechatManage.APPManage.List({
          APP_Type: type ? type : null // 1 公众号 2 小程序 3 QQ 101 安卓APP 102 IOS 999 其他
        })
        if (retValue.hasError) {
          this.$message.error(`读取APP列表出错，请重试! ${retValue.message}`)
          return
        }
        for (const item of retValue.returnObject) {
          if (item.Create_Date) item.Create_Date = Moment(item.Create_Date).format('YYYY/MM/DD HH:mm:ss')
        }
        return retValue.returnObject
      }
    }
  },
  Activity: {
    List: async (data) => {
      let dataParams = Object.assign(
        {},
        {
          P_MaxCode: data.sMaxCode,                      // MaxCode [Number] 第一次传空，之后通过接口返回原样传递
          P_Get: data.P_Get,                             // 是否获取分页信息 [Bool] [必传:否] [默认:false]
          P_Start: (data.P_Start - 1) * data.P_Length,   // 分页起始 [Number] [必传:是] [0 开始不传无数据]
          P_Length: data.P_Length,                       // 获取数据条数 [Number] [必传:是] [0或不传无数据]
          Sort_Col: data.Sort_Col,                       // 排序字段 [String] [必传:否] [Price:价格;List_Order:排序;Num_Order:订单数量]
          Sort_Dir: data.Sort_Dir,                       // 排序顺序 [String] [必传:否] [默认:'DESC'] ['ASC':正序 'DESC' 倒序]
          F_SType: data.F_SType !== -1 ? data.F_SType : null, // 查询条件 [String] [必传:否] [例如：空字符串或者不传为模糊查询 其他为具体查询字段或者特殊标识]
          F_SValue: data.F_SValue,                       // 查询内容 [String] [必传:否] [查询具体内容]
          F_ActivityID: data.F_ActivityID,               // 活动ID [Number] [必传:否]
          F_Category: data.F_Category,                   // 类型 [String] [必传:是] [SecKill:秒杀 Register:新人注册]
          F_CityCode: data.F_CityCode !== -1 ? data.F_CityCode : null,       // 围栏编码 [String] [必传:否]
          F_BuildingNo: data.F_BuildingNo !== -1 ? data.F_BuildingNo : null, // 综合体编码 [String] [必传:否]
          F_DateBegin: data.F_DateBegin,                                     // 开始时间 [Date] [必传:否]
          F_DateEnd: data.F_DateEnd,                                         // 结束时间 [Date] [必传:否]
          F_IsWeApp: data.F_IsWeApp !== -1 ? data.F_IsWeApp : null,          // 展示渠道
          F_StatusDate: data.F_StatusDate !== -1 ? data.F_StatusDate : null, // 活动状态 [Number] [必传:否] [3:已结束 1:未开始 2:进行中]
          F_Status: data.F_Status !== -1 ? data.F_Status : null,             // 状态 [Number] [必传:否] [0:正常 1:锁定]
          F_ShareMethod: data.F_ShareMethod !== -1 ? data.F_ShareMethod : null
        }
      );
      let retValue = await APIBiz.Custom.C1025.MarketingTools.ActivityManage.List(dataParams);
      return retValue
      // if (retValue.hasError) {
      //   this.$message.error(`读取列表信息出错，请重试! ${retValue.message}`);
      //   return;
      // }
      // return retValue.returnObject
    }
  },
  Company: {
    Info: async () => {
      const retValue = await APIBiz.BaseManage.CompanyManage.Company.Info();
      if (retValue.hasError) {
        this.$message.error(`读取企业信息出错，请重试! ${retValue.message}`);
        return null;
      }
      return retValue.returnObject.Corp_ID;
    }
  },
  Custom: {
    C1001: {
      Position: {
        TreeList: () => {
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null
            }
          }
          retValue.returnObject.data = C1001PositionList.TreeList
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        },
        List: (options) => {
          const index = options.Department_ID[0]
          let sucData = options
          let data
          if(C1001PositionList.TreeList[index-1].Parent_ID === 1){
            data = C1001PositionList.List.filter(item => item.Business_Module === C1001PositionList.TreeList[index-1].Department_Name)
          } else if(C1001PositionList.TreeList[index-1].Parent_ID>1){
            const ParentList = C1001PositionList.TreeList.filter(item=>item.Department_ID === C1001PositionList.TreeList[index-1].Parent_ID)
            const filterList = C1001PositionList.List.filter(item=>item.Business_Module === ParentList[0].Department_Name)
            data = filterList.filter(item => item.page === C1001PositionList.TreeList[index-1].Department_Name)
          } else {
            data = C1001PositionList.List
          }
          if (sucData.F_Status || sucData.F_Status === 0) {
            data = data.filter(item => item.Status === sucData.F_Status)
          }
          if (sucData.F_DateBegin && sucData.F_DateEnd) {
            data = data.filter(item => Number(sucData.F_DateBegin.split('-').join('')) <= Number(item.Create_time.substr(0,10).split('-').join(''))<=Number(sucData.F_DateEnd.split('-').join('')))
          }
          if (sucData.F_DateBegin) data = data.filter(item => Number(sucData.F_DateBegin.split('-').join('')) <=Number(item.Create_time.substr(0,10).split('-').join('')))
          if (sucData.F_DateEnd) data = data.filter(item => Number(item.Create_time.substr(0,10).split('-').join('')) <= Number(sucData.F_DateEnd.split('-').join('')))
          if (sucData.F_SValue&&sucData.F_SValue.length !== 0) {
            if (sucData.F_FuzzyQuery === -1) {
              data= data.filter(item=>String(item.Position_ID).includes(sucData.F_SValue) || item.Position_Name.includes(sucData.F_SValue))
            }
            if (sucData.F_FuzzyQuery === 0) {
              data = data.filter(item => item.Position_ID === Number(sucData.F_SValue))
            }
            if (sucData.F_FuzzyQuery === 1) {
              data = data.filter(item => item.Position_Name === sucData.F_SValue)
            }
          }
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null,
              iTotal: 0
            }
          }
          retValue.returnObject.data = data.slice(sucData.P_Start,sucData.P_Start+sucData.P_Length)
          retValue.returnObject.iTotal = data.length
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        },
        Info: (id) => {
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null
            }
          }
          retValue.returnObject.data = C1001PositionList.List.filter(item => item.Position_ID === id)[0]
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        }
      }
    },
    C1024: {
      Position: {
        TreeList: () => {
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null
            }
          }
          retValue.returnObject.data = C1024PositionList.TreeList
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        },
        List: (options) => {
          const index = options.Department_ID[0]
          let sucData = options
          let data
          if(C1024PositionList.TreeList[index-1].Parent_ID === 1){
            data = C1024PositionList.List.filter(item => item.Business_Module === C1024PositionList.TreeList[index-1].Department_Name)
          } else if(C1024PositionList.TreeList[index-1].Parent_ID>1){
            const ParentList = C1024PositionList.TreeList.filter(item=>item.Department_ID === C1024PositionList.TreeList[index-1].Parent_ID)
            const filterList = C1024PositionList.List.filter(item=>item.Business_Module === ParentList[0].Department_Name)
            data = filterList.filter(item => item.page === C1024PositionList.TreeList[index-1].Department_Name)
          } else {
            data = C1024PositionList.List
          }
          if (sucData.F_Status || sucData.F_Status === 0) {
            data = data.filter(item => item.Status === sucData.F_Status)
          }
          if (sucData.F_DateBegin && sucData.F_DateEnd) {
            data = data.filter(item => Number(sucData.F_DateBegin.split('-').join('')) <= Number(item.Create_time.substr(0,10).split('-').join(''))<=Number(sucData.F_DateEnd.split('-').join('')))
          }
          if (sucData.F_DateBegin) data = data.filter(item => Number(sucData.F_DateBegin.split('-').join('')) <=Number(item.Create_time.substr(0,10).split('-').join('')))
          if (sucData.F_DateEnd) data = data.filter(item => Number(item.Create_time.substr(0,10).split('-').join('')) <= Number(sucData.F_DateEnd.split('-').join('')))
          if (sucData.F_SValue&&sucData.F_SValue.length !== 0) {
            if (sucData.F_FuzzyQuery === -1) {
              data= data.filter(item=>String(item.Position_ID).includes(sucData.F_SValue) || item.Position_Name.includes(sucData.F_SValue))
            }
            if (sucData.F_FuzzyQuery === 0) {
              data = data.filter(item => item.Position_ID === Number(sucData.F_SValue))
            }
            if (sucData.F_FuzzyQuery === 1) {
              data = data.filter(item => item.Position_Name === sucData.F_SValue)
            }
          }
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null,
              iTotal: 0
            }
          }
          retValue.returnObject.data = data.slice(sucData.P_Start,sucData.P_Start+sucData.P_Length)
          retValue.returnObject.iTotal = data.length
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        },
        Info: (id) => {
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null
            }
          }
          retValue.returnObject.data = C1024PositionList.List.filter(item => item.Position_ID === id)[0]
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        }
      }
    },
    C1025: {
      Position: {
        TreeList: () => {
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null
            }
          }
          retValue.returnObject.data = C1025PositionList.TreeList
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        },
        List: (options) => {
          const index = options.Department_ID[0]
          let sucData = options
          let data
          if(C1025PositionList.TreeList[index-1].Parent_ID === 1){
            data = C1025PositionList.List.filter(item => item.Business_Module === C1025PositionList.TreeList[index-1].Department_Name)
          } else if(C1025PositionList.TreeList[index-1].Parent_ID>1){
            const ParentList = C1025PositionList.TreeList.filter(item=>item.Department_ID === C1025PositionList.TreeList[index-1].Parent_ID)
            const filterList = C1025PositionList.List.filter(item=>item.Business_Module === ParentList[0].Department_Name)
            data = filterList.filter(item => item.page === C1025PositionList.TreeList[index-1].Department_Name)
          } else {
            data = C1025PositionList.List
          }
          if (sucData.F_Status || sucData.F_Status === 0) {
            data = data.filter(item => item.Status === sucData.F_Status)
          }
          if (sucData.F_DateBegin && sucData.F_DateEnd) {
            data = data.filter(item => Number(sucData.F_DateBegin.split('-').join('')) <= Number(item.Create_time.substr(0,10).split('-').join(''))<=Number(sucData.F_DateEnd.split('-').join('')))
          }
          if (sucData.F_DateBegin) data = data.filter(item => Number(sucData.F_DateBegin.split('-').join('')) <=Number(item.Create_time.substr(0,10).split('-').join('')))
          if (sucData.F_DateEnd) data = data.filter(item => Number(item.Create_time.substr(0,10).split('-').join('')) <= Number(sucData.F_DateEnd.split('-').join('')))
          if (sucData.F_SValue&&sucData.F_SValue.length !== 0) {
            if (sucData.F_FuzzyQuery === -1) {
              data= data.filter(item=>String(item.Position_ID).includes(sucData.F_SValue) || item.Position_Name.includes(sucData.F_SValue))
            }
            if (sucData.F_FuzzyQuery === 0) {
              data = data.filter(item => item.Position_ID === Number(sucData.F_SValue))
            }
            if (sucData.F_FuzzyQuery === 1) {
              data = data.filter(item => item.Position_Name === sucData.F_SValue)
            }
          }
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null,
              iTotal: 0
            }
          }
          retValue.returnObject.data = data.slice(sucData.P_Start,sucData.P_Start+sucData.P_Length)
          retValue.returnObject.iTotal = data.length
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        },
        Info: (id) => {
          let retValue = {
            hasError: false,
            message: '',
            returnObject: {
              data: null
            }
          }
          retValue.returnObject.data = C1025PositionList.List.filter(item => item.Position_ID === id)[0]
          if (!retValue.returnObject.data) retValue.hasError = true
          return retValue
        }
      }
    }
  },
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  },
  getMonday(type) {
    let day = ''
    if (type === "s") {
      day = Moment().startOf('isoWeek').format('YYYY-MM-DD')
    } else if(type === "e") {
      day = Moment().endOf('isoWeek').format('YYYY-MM-DD')
    }
    return day
  },
  getMonth(type) {
    let day = ''
    if (type === "s") {
      day = Moment().startOf('month').format('YYYY-MM-DD')
    } else if(type === "e") {
      day = Moment().endOf('month').format('YYYY-MM-DD')
    }
    return day
  },
  filterNumber(value,numLength=5){
    if (value >= 100000000) {
      value = (value / 100000000).toFixed(1) + '亿'
    } else if (value > 10000) {
      value = (value / 10000).toFixed(1) + 'w'
    } else if (value > 1000 && numLength===4) {
      value = (value / 1000).toFixed(1) + 'k'
    }
    return value
  },
  scrollTo(to, duration, callback) {
    const start = position()
    const change = to - start
    const increment = 20
    let currentTime = 0
    duration = (typeof (duration) === 'undefined') ? 500 : duration
    var animateScroll = function () {
      currentTime += increment
      var val = Math.easeInOutQuad(currentTime, start, change, duration)
      move(val)
      if (currentTime < duration) {
        console.log(111);
        requestAnimFrame(animateScroll)
      } else {
        if (callback && typeof (callback) === 'function') {
          callback()
        }
      }
    }
    animateScroll()
  }
}
export default UtilsBiz
