'use strict'

// user 个人用户相关接口

import APIService from './service'

export default {
  Config: (data) => {
    data = Object.assign({
      Company_ID: null,
      List: null
    }, data)
    return APIService.RequestPost('user/config', data)
  },
  OAuthURL: (data) => {
    data = Object.assign({
      Company_ID: null,
      APP_ID: null,
      Source: null,
      Inviter: null,
      Auth_Base: null,
      URL: null
    }, data)
    return APIService.RequestPost('user/oauthurl', data)
  },
  Login: (data) => {
    data = Object.assign({
      Bind: null,
      Company_ID: null,
      Phone: null,
      Code: null,
      Source: null,
      Inviter: null
    }, data)
    return APIService.RequestPost('user/login', data)
  },
  Logout: () => {
    return APIService.RequestPost('user/logout')
  }
}
