<template>
    <div>
        <div :class="imgclass" class="currency" v-if="!flag">
           <a-icon type="loading" />
        </div>
        <img :style="imgstyle" :src="url" v-else />
    </div>
</template>
<script>
export default {
    props: ['src','imgstyle','imgclass'],
    data() {
        return {
            url: false,
            flag: false
        }
    },
    watch:{
        src(){
            this.flag = false
            this.loadingImg()
        }
    },
    methods:{
        loadingImg(){
            var newImg = new Image()
            newImg.src = this.src
            newImg.onload = () => {
            this.url = newImg.src
            this.flag = true
        }
        }
    },
    mounted() {
        this.loadingImg()
    }
}
</script>
<style scoped lang="less">
.loadEffect {
  width: 60px;
  height: 80px;
  line-height: 80px;
}
.musicEffect {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.loadmaterial{
  width: 30px;
  height: 50px;
  line-height: 50px;
}
.viewposter {
  width: 152px;
  height: 188px;
  line-height: 188px;
}
.currency{
  position: relative;
  margin: 0 auto;
  font-size: 20px;
}
.position_info{
  width: 260px;
  height: 560px;
  margin: 0;
  text-align: center;
  line-height: 560px;
}
.position_List{
  width: 220px;
  height: 470px;
  margin: 0;
  text-align: center;
  line-height: 470px;
}
</style>