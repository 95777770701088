'use strict'

export default [
  // 数据看板
  // {
  //   path: '1026/data-board',
  //   name: 'C1026DataBoard',
  //   meta: { Title: '数据看板',Auth:'{C1026DataBoard}' },
  //   // meta: { Title: '数据看板', Auth: '{Routine}' },
  //   component: () => import(/* webpackChunkName: "index.lazy.main" */ '@/page/custom/1026/data-board'),
  // },
  // ToC小程序管理
  {
    path: '1026/toc-manage',
    name: 'C1026ToCManage',
    meta: { Title: 'ToC小程序管理', Auth: '{ToC}'},
    component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      {
        path: 'banner',
        redirect: 'banner/list',
        name: 'C1026ToCManage.BannerManage',
        meta: { Title: 'Banner管理', Auth: '{C1026ToC}[Banner](List)'},
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1026ToCManage.BannerManage.List',
            meta: { Title: 'Banner列表', Auth: '{C1026ToC}[Banner](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/banner/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'C1026ToCManage.BannerManage.Add',
            meta: { Title: 'Banner编辑', Auth: '{C1026ToC}[Banner](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/banner/add.vue')
          },
          {
            path: 'info/:id?',
            name: 'C1026ToCManage.BannerManage.Info',
            meta: { Title: 'Banner详情', Auth: '{C1026ToC}[Banner](Info)', keepAlive: true },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/banner/info.vue')
          },
        ]
      }, {
        path: 'news',
        redirect: 'news/list',
        name: 'C1026ToCManage.NewsManage',
        meta: { Title: '动态管理', Auth: '{C1026ToC}[News](List)'},
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'C1026ToCManage.NewsManage.List',
            meta: { Title: '动态列表', Auth: '{C1026ToC}[News](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/news/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'C1026ToCManage.NewsManage.Add',
            meta: { Title: '动态编辑', Auth: '{C1026ToC}[News](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/news/add.vue')
          },
          {
            path: 'info/:id?',
            name: 'C1026ToCManage.NewsManage.Info',
            meta: { Title: '动态详情', Auth: '{C1026ToC}[News](Info)', keepAlive: true },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/news/info.vue')
          },
        ]
      }, {
        path: 'activity',
        redirect: 'activity/add',
        name: 'C1026ToCManage.ActivityManage',
        meta: { Title: '活动专区分享管理', Auth: '{C1026ToC}[Activity](Add)'},
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          // {
          //   path: 'list',
          //   name: 'C1026ToCManage.ActivityManage.List',
          //   meta: { Title: '动态列表', Auth: '{C1026ToC}[Activity](List)' },
          //   component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/activity/list.vue')
          // },
          {
            path: 'add',
            name: 'C1026ToCManage.ActivityManage.Add',
            meta: { Title: '活动专区分享项', Auth: '{C1026ToC}[Activity](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/activity/add.vue')
          },
          // {
          //   path: 'info/:id?',
          //   name: 'C1026ToCManage.ActivityManage.Info',
          //   meta: { Title: '动态详情', Auth: '{C1026ToC}[Activity](Info)', keepAlive: true },
          //   component: () => import(/* webpackChunkName: "index.lazy.toc-manage" */ '@/page/custom/1026/toc-manage/activity/info.vue')
          // }
        ]
      }
    ]
  }
]
