'use strict'

export default [
  // 内容工厂
  {
    path: 'content-factory',
    name: 'ContentFactory',
    meta: { Title: '内容分发', Auth: '{Content}' },
    component: () => import(/* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
    children: [
      // 内容管理
      {
        path: 'content-manage',
        redirect: 'content-manage/list',
        name: 'ContentManage.ProjectManage',
        meta: {
          Title: '内容管理',
          Auth: '{Content}[Project]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'ContentManage.ProjectManage.List',
            meta: { Title: '内容列表', Auth: '{Content}[Project](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/list.vue')
          },
          {
            path: 'info/:id',
            name: 'ContentManage.ProjectManage.Info',
            meta: { Title: '查看内容', Auth: '{Content}[Project](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/info.vue')
          },
          {
            path: 'add/:type?/:id?',
            name: 'ContentManage.ProjectManage.Add',
            meta: { Title: '编辑内容', Auth: '{Content}[Project](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/add.vue')
          },
          {
            path: 'approval-list',
            name: 'ContentManage.ProjectManage.ApprovalList',
            meta: { Title: '审核列表', Auth: '{Content}[Project](ApprovalList)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/approval.vue')
          },
          {
            path: 'approval-info/:id',
            name: 'ContentManage.ProjectManage.ApprovalInfo',
            meta: { Title: '内容审核详情', Auth: '{Content}[Project](Approval)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/info.vue')
          },
        ]
      },
      {
        path: 'content-analysis',
        redirect: 'content-analysis/list',
        name: 'ContentManage.AnalysisManage',
        meta: { Title: '内容分析', Auth: '{Content}[Analysis]' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          // 内容标签
          {
            path: 'list',
            name: 'ContentManage.AnalysisManage.List',
            meta: { Title: '分析列表', Auth: '{Content}[Analysis](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-analysis/list')
          },
          {
            path: 'info/:id',
            name: 'ContentManage.AnalysisManage.Info',
            meta: { Title: '分析详情', Auth: '{Content}[Analysis](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-analysis/info')
          },
        ]
      },
      // {
      //   path: 'content-approval',
      //   redirect: 'content-approval/list',
      //   name: 'ContentManage.ApprovalManage',
      //   meta: { Title: '内容审核', Auth: '{Content}[Project](ApprovalList)' },
      //   component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'ContentFactory.ContentApproval.List',
      //       meta: { Title: '审核列表', Auth: '{Content}[Project](ApprovalList)' },
      //       component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/approval.vue')
      //     },
      //     {
      //       path: 'info/:id',
      //       name: 'ContentFactory.ContentApproval.Info',
      //       meta: { Title: '内容审核详情', Auth: '{Content}[Project](Approval)' },
      //       component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-manage/info.vue')
      //     },
      //   ]
      // },
      {
        path: 'content-material',
        redirect: 'content-material/list',
        name: 'ContentManage.MaterialManage',
        meta: { Title: '素材库', Auth: '{Content}[Material]' },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          // 素材管理
          {
            path: 'list',
            name: 'ContentManage.MaterialManage.List',
            meta: { Title: '素材列表', Auth: '{Content}[Material](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/material-manage/list.vue')
          },
          {
            path: 'info/:id',
            name: 'ContentManage.MaterialManage.Info',
            meta: { Title: '素材详情', Auth: '{Content}[Material](Info)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/material-manage/info.vue')
          },
          {
            path: 'add/:id',
            name: 'ContentManage.MaterialManage.Add',
            meta: { Title: '素材编辑', Auth: '{Content}[Material](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/material-manage/add.vue')
          }
        ]
      },
      {
        path: 'content-tag',
        redirect: 'content-tag/list',
        name: 'ContentManage.TagManage',
        meta: {
          Title: '内容标签',
          Auth: '{Content}[Tag]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          // 内容标签
          {
            path: 'list',
            name: 'ContentManage.TagManage.List',
            meta: { Title: '标签列表', Auth: '{Content}[Tag](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-tag/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'ContentManage.TagManage.Add',
            meta: { Title: '标签编辑', Auth: '{Content}[Tag](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-tag/add.vue')
          },
          {
            path: 'info/:id?',
            name: 'ContentManage.TagManage.Info',
            meta: { Title: '标签详情', Auth: '{Content}[Tag](Info)', keepAlive: true },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-tag/info.vue')
          },
        ]
      },
      {
        path: 'content-banner',
        redirect: 'content-banner/list',
        name: 'ContentManage.BannerManage',
        meta: {
          Title: 'Banner管理',
          Auth: '{Content}[Banner]'
        },
        component: () => import( /* webpackChunkName: "index.lazy.parent-view" */ '@/page/common/parent-view'),
        children: [
          {
            path: 'list',
            name: 'ContentManage.BannerManage.List',
            meta: { Title: 'Banner列表', Auth: '{Content}[Banner](List)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-banner/list.vue')
          },
          {
            path: 'add/:id?',
            name: 'ContentManage.BannerManage.Add',
            meta: { Title: 'Banner编辑', Auth: '{Content}[Banner](Add)' },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-banner/add.vue')
          },
          {
            path: 'info/:id?',
            name: 'ContentManage.BannerManage.Info',
            meta: { Title: 'Banner详情', Auth: '{Content}[Banner](Info)', keepAlive: true },
            component: () => import(/* webpackChunkName: "index.lazy.content-factory" */ '@/page/content-factory/content-banner/info.vue')
          },
        ]
      },
    ]
  }
]
