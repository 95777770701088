'use strict'

import KOCString from 'koc-common-string'
import UtilsCommon from '@/utils/common'
import StoreConst from '../const'

const state = {
  List: []
}

const getters = {
}

const mutations = {
  [StoreConst.BNavTag.Mutation.Add] (state, data) {
    if (!Array.isArray(data)) data = [data]
    // 从LocalStorage 取出
    const list = UtilsCommon.LocalStorage.GetJson('TagNavList') || []
    for (const item of data) {
      // 拦截没有Title的路由
      if (!item.Route.Meta.Title) continue
      // 是否为Main路由
      if (item.Route.Name.split('.').length === 1 && item.Route.Name !== 'Main') continue
      // 新路由是否在 TagNavList 已存在
      if (list.filter(listItem => UtilsCommon.IsEqualRoute(listItem, item.Route)).length) continue
      // 判断 Index
      item.Index = KOCString.ToInt(item.Index, -1)
      // 判断index
      if (item.Index >= 0) list.splice(item.Index, 0, item.Route)
      else list.push(item.Route)
    }
    state.List = list
    UtilsCommon.LocalStorage.SetJson('TagNavList', list)
  },
  [StoreConst.BNavTag.Mutation.Delete] (state, data) {
    if (!Array.isArray(data)) data = [data]
    for (const item of data) {
      // 判断 Mode
      if (item.Mode === 'All') state.List = []
      if (item.Mode === 'Other') state.List = state.List.filter(listItem => { return UtilsCommon.IsEqualRoute(listItem, item.Route) })
      else state.List = state.List.filter(listItem => { return !UtilsCommon.IsEqualRoute(listItem, item.Route) })
    }
    UtilsCommon.LocalStorage.SetJson('TagNavList', state.List)
  }
}
const actions = {
}
export default {
  state,
  getters,
  mutations,
  actions
}
