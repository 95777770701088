import KOCString from 'koc-common-string'
// import KOCSleep from 'koc-common-sleep'
import Store from '../store/user'
import StoreConst from '../store/const'
import APIUser from '../api/user'
import UtilsCommon from './common'

let vueInstance = null

const UtilsUser = {
  Instance: (vue) => {
    vueInstance = vue
  },
  Cookie: {
    CompanyID: {
      Set: (val) => {
        UtilsCommon.Cookie.Set('UESR:CompanyID', KOCString.ToIntPositive(val))
      },
      Get: () => {
        return KOCString.ToIntPositive(UtilsCommon.Cookie.Get('UESR:CompanyID'))
      }
    },
    APPID: {
      Set: (val) => {
        UtilsCommon.Cookie.Set('UESR:APPID', KOCString.ToIntPositive(val))
      },
      Get: () => {
        return KOCString.ToIntPositive(UtilsCommon.Cookie.Get('UESR:APPID'))
      }
    },
    Source: {
      Set: (val) => {
        if (val) UtilsCommon.Cookie.Set('UESR:Source', KOCString.ToString(val))
      },
      Get: () => {
        return KOCString.ToString(UtilsCommon.Cookie.Get('UESR:Source'))
      }
    },
    Inviter: {
      Set: (val) => {
        val = KOCString.ToIntPositive(val)
        if (val) UtilsCommon.Cookie.Set('UESR:Inviter', KOCString.ToString(val))
      },
      Get: () => {
        return KOCString.ToIntPositive(UtilsCommon.Cookie.Get('UESR:Inviter'))
      }
    }
  },
  // AuthCheck 状态验证
  // auth: false:无需授权,Base:基础授权,true:授权,Phone:验证手机,TPhone:授权验证手机 默认值:true
  // opts: 参数 默认值:null
  // opts.URL: 返回URL 默认值:当前url
  // opts.Phone: 是否只绑定手机(验证手机时使用) [true:只绑定手机(不显示三方登录)/'Re':重新验证手机(不显示三方登录)/false:显示第三方登录] 默认值:false
  // to: 是否直接跳转 true/false 默认值:true
  AuthCheck: async (auth, opts, to) => {
    if (auth === false) return true
    if (!vueInstance) return false
    if (!['Base', true, 'Phone', 'TPhone'].includes(auth)) {
      to = opts
      opts = auth
      auth = true
    }
    if (typeof opts === 'string') opts = {URL: opts}
    if (typeof opts !== 'object') {
      to = opts
      opts = {}
    }
    if (!opts.URL) opts.URL = vueInstance.$route.fullPath
    to = to !== false
    const stateUser = Store.getters[StoreConst.CStateData.Getter.User]
    const browserGroup = UtilsCommon.BrowserGroup()
    if (!['QQ', 'Wechat', 'Weibo'].includes(browserGroup) && [true, 'Base', 'TPhone'].includes(auth)) auth = 'Phone'
    if (stateUser && opts.Phone !== 'Re' && (
      auth === 'Base' ||
      (auth === true && !stateUser.Info_Reload) ||
      (auth === 'Phone' && stateUser.User_ID) ||
      (auth === 'TPhone' && !stateUser.Info_Reload && stateUser.User_ID)
    )) {
      return true
    }
    if (['Phone', 'TPhone'].includes(auth) && ['QQ', 'Wechat', 'Weibo'].includes(browserGroup) && (!stateUser || (auth === 'TPhone' && stateUser.Info_Reload))) auth = auth === 'Phone' ? 'Base' : true
    if ([true, 'Base'].includes(auth) && !['QQ', 'Wechat', 'Weibo'].includes(browserGroup)) auth = 'Phone'
    const toValue = {
      Company_ID: UtilsUser.Cookie.CompanyID.Get(),
      APP_ID: UtilsUser.Cookie.APPID.Get(),
      URL: null
    }
    if ([true, 'Base'].includes(auth)) {
      const retValue = await APIUser.OAuthURL({
        Company_ID: toValue.Company_ID,
        APP_ID: toValue.APP_ID,
        Source: UtilsUser.Cookie.Source.Get(),
        Inviter: UtilsUser.Cookie.Inviter.Get(),
        Auth_Base: auth === 'Base',
        URL: KOCString.Regular.Verify(opts.URL, 'Http') ? opts.URL : (UtilsCommon.SiteURL.Get(vueInstance.$options.site) + (opts.URL.startsWith('/') ? opts.URL.substr(1) : opts.URL))
      })
      if (retValue.hasError) {
        auth = 'Phone'
      } else {
        toValue.URL = retValue.returnObject
      }
    }
    if (['Phone', 'TPhone'].includes(auth)) toValue.URL = (vueInstance.$options.site !== 'm-user' ? UtilsCommon.SiteURL.MUser() : '/') + toValue.Company_ID + (toValue.APP_ID ? '/' + toValue.APP_ID : '') + '/account/login' + (opts.Phone ? '/true' : '') + '?url=' + encodeURIComponent((KOCString.Regular.Verify(opts.URL, 'Http') || vueInstance.$options.site === 'm-user') ? opts.URL : (UtilsCommon.SiteURL.Get(vueInstance.$options.site) + (opts.URL.startsWith('/') ? opts.URL.substr(1) : opts.URL)))
    if (!to) return toValue.URL
    if (KOCString.Regular.Verify(toValue.URL, 'Http')) {
      window.location.href = toValue.URL
    } else {
      vueInstance.$router.push(toValue.URL)
    }
    return false
  }
}

export default UtilsUser
